import React from 'react';

import { constants, IconButton } from '@armis/armis-ui-library';
import { Typography } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { cloneDeep } from 'lodash';
import { Pinned } from 'src/assets/icons/Pinned';
import { UnPinned } from 'src/assets/icons/UnPinned';
import { LOGIN, SSO } from 'src/constants/APIConstants';
import { StyledAnchorTag } from 'src/helpers/Common.style';
import {
    ChartsBox,
    StyledTile,
    TenantResource,
    TenantResources,
    TileTitle,
    TileTitleDiv
} from 'src/pages/containers/TileView/Tile/Tile.style';
import { TileProps } from 'src/pages/containers/TileView/Tile/Tile.types';
import { getChartOptions } from 'src/pages/containers/TileView/Tile/TileUtility';

export const Tile = ({
    criticality,
    pinned,
    tileTitle,
    tileURL,
    tenantResources,
    chartResources,
    tenant,
    pinLatest,
    isArmisUser,
    pinActionTile
}: TileProps) => {
    const pinActionClick = () => {
        const tenantArray = [
            {
                id: tenant.id,
                pinOrder: pinned ? null : pinLatest + 1
            }
        ];
        pinActionTile(tenantArray);
    };
    const options = getChartOptions(
        `chart-integrations-${tenant.id}-container`,
        chartResources[0].totalCount,
        chartResources[0].labelName,
        chartResources[0].chartData
    );
    const options1 = getChartOptions(
        `chart-collectors-${tenant.id}-container`,
        chartResources[1].totalCount,
        chartResources[1].labelName,
        chartResources[1].chartData
    );

    return (
        <StyledTile>
            <TileTitle criticality={criticality}>
                <TileTitleDiv>
                    <StyledAnchorTag
                        href={isArmisUser ? tileURL + LOGIN + SSO : tileURL}
                        rel="noreferrer"
                        sx={{ fontSize: '1.6rem' }}
                        target="_blank"
                    >
                        {tileTitle}
                    </StyledAnchorTag>
                </TileTitleDiv>
                <IconButton
                    aria-label={pinned ? 'pinned' : 'unpinned'}
                    onClick={pinActionClick}
                    sx={{
                        padding: '0px',
                        position: 'absolute',
                        right: '7px'
                    }}
                >
                    {pinned ? <Pinned /> : <UnPinned />}
                </IconButton>
            </TileTitle>
            <TenantResources>
                {tenantResources.map(({ labelName, count }, index) => (
                    <TenantResource key={index}>
                        <Typography
                            sx={{
                                fontFamily: constants.PROXIMANOVA_W08_SEMIBOLD,
                                textAlign: 'center'
                            }}
                            variant="h4"
                        >
                            {count}
                        </Typography>
                        <Typography
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            variant="h5"
                        >
                            {labelName}
                        </Typography>
                    </TenantResource>
                ))}
            </TenantResources>
            <ChartsBox>
                <HighchartsReact
                    containerProps={{
                        id: `chart-integrations-${tenant.id}-container`
                    }}
                    highcharts={Highcharts}
                    options={cloneDeep(options)}
                />
                <HighchartsReact
                    containerProps={{
                        id: `chart-collectors-${tenant.id}-container`
                    }}
                    highcharts={Highcharts}
                    options={cloneDeep(options1)}
                />
            </ChartsBox>
        </StyledTile>
    );
};

import { styled } from '@mui/material';

export const StyledContainer = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
export const StyledText = styled('div')`
    font-size: 1.4rem;
    white-space: pre-line;
    color: ${props => (props.theme.palette.mode === 'dark' ? 'white' : '')};
`;

export const Content = styled('div')`
    display: flex;
    margin-left: 30px;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
`;

import {
    IconButton,
    MenuIcon,
    ColumnsFilter,
    ShowSkeleton
} from '@armis/armis-ui-library';
import { SELECTED } from 'src/constants/LabelText';

import { StyledHeaderContainer, StyledTitle } from './TableHeader.style';
import { TableHeaderProps } from './TableHeader.types';

export const TableHeader = ({
    loading,
    title,
    selectedCount = 0,
    childrenLeft,
    childrenRight,
    menuActions,
    onColumnMenuClick,
    hideColumnsFilter = false
}: TableHeaderProps) => (
    <StyledHeaderContainer className="table-header">
        <ShowSkeleton
            content={
                <>
                    <StyledTitle data-testid="table-title">
                        <span>{title}</span>
                    </StyledTitle>
                    {selectedCount > 0 && (
                        <span className="selected-counter">
                            ({selectedCount} {SELECTED})
                        </span>
                    )}
                </>
            }
            loading={loading}
        />
        <span className="spacer" />
        <div className="control actions">
            {childrenLeft}
            {menuActions && (
                <IconButton disabled={!(selectedCount > 0)}>
                    <MenuIcon />
                </IconButton>
            )}
            {childrenRight}
        </div>
        {!hideColumnsFilter && (
            <IconButton disabled={loading} onClick={onColumnMenuClick}>
                <ColumnsFilter />
            </IconButton>
        )}
    </StyledHeaderContainer>
);

import React from 'react';

import { WarningTri } from '@armis/armis-ui-library';
import { Box } from '@mui/material';

import {
    Content,
    StyledContainer,
    StyledText
} from './WarningModalContainer.style';

interface WarningModalContainerProps {
    text: string;
    children?: JSX.Element;
}

export const WarningModalContainer = ({
    text,
    children
}: WarningModalContainerProps) => (
    <StyledContainer>
        <Box className="control svg-symbol-button" sx={{ height: '80px' }}>
            <WarningTri height="60" width="60" />
        </Box>
        <Content>
            <StyledText>{text}</StyledText>
            {children}
        </Content>
    </StyledContainer>
);

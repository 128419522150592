import { constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const CustomPropertiesContainer = styled('div')`
    display: flex;
    flex-direction: column;
    box-sizing: content-box;
    color: ${props => (props.theme.palette.mode === 'dark' ? 'white' : '')};
`;

export const StyledSectionTitle = styled('div')`
    font-family: ${constants.PROXIMANOVA_W08_REGULAR};
    font-size: 20px;
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 10px;
`;

export const StyledSectionParagraph = styled('div')`
    font-family: ${constants.PROXIMANOVA_W08_REGULAR};
    font-size: 16px;
    padding: 0px 64px 10px 64px;

    @media (max-width: 1360px) {
        padding: 0px 32px 16px 32px;
    }
    margin-left: 14px;
`;

export const Control = styled('div')`
    padding: 24px 64px 10px 64px;

    @media (max-width: 1360px) {
        padding: 24px 32px 16px 32px;
    }
    font-size: 16px;
    display: flex;
`;

export const Area = styled('div')`
    flex: 1;
`;

export const ButtonGroup = styled('div')`
    padding: 15px 0px;
    width: 60.6%;
    @media (max-width: 1024px) {
        width: 100%;
    }
    button {
        width: 140px;
        height: 43px;
    }
`;

export const FieldsContainer = styled('div')`
    padding: 0 14px;
    display: inline-table;
`;

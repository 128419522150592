import React, { useCallback, useEffect, useState } from 'react';

import { Loader } from '@armis/armis-ui-library';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ArmisLogo from 'src/assets/icons/ArmisLogo';
import CentrixLogo from 'src/assets/icons/CentrixLogo';
import LoginPageLogo from 'src/assets/icons/LoginPageLogo';
import { TOAST_ID } from 'src/constants/APIConstants';
import { ARMIS, LOGIN_TITLE } from 'src/constants/LabelText';
import { HOME } from 'src/constants/RouteConstants';
import { showToast, TOAST_TYPE } from 'src/helpers/utility';
import { useAuth } from 'src/hooks/useAuth';
import { BasicAuth } from 'src/pages/containers/Login/BasicAuth/BasicAuth';

import { StyledDiv, StyledContainer } from './Login.style';
import LoginImage from '../../../assets/images/loginImage.png';
import LoginVideo from '../../../assets/video/loginMovie.mp4';

export const Login = () => {
    const navigate = useNavigate();
    const isAuth = useAuth();
    const [searchParams] = useSearchParams();

    const [innerWidth, setInnerWidth] = useState<number>(1536);

    const [isLoginSuccess, setIsLoginSuccess] = useState(false);

    useEffect(() => {
        const error = searchParams.get('error');
        if (error?.trim().length) {
            showToast(error, TOAST_TYPE.ERROR, TOAST_ID);
        }
        setInnerWidth(window.innerWidth);
    }, []);

    const redirectAfterSuccess = useCallback(() => {
        const redirectURL = searchParams.get('r') || HOME;
        navigate(redirectURL, {
            replace: true
        });
    }, [navigate, searchParams]);

    useEffect(() => {
        if (isAuth) {
            redirectAfterSuccess();
        }
    }, [isAuth, redirectAfterSuccess]);

    if (isAuth === false)
        return (
            <StyledContainer isLoginSuccess={isLoginSuccess}>
                <Helmet>
                    <title>{`${ARMIS} | ${LOGIN_TITLE}`}</title>
                </Helmet>
                <div
                    style={{
                        display: 'flex',
                        width: '100%'
                    }}
                >
                    <div style={{ marginTop: '30px' }}>
                        <ArmisLogo />
                    </div>
                    <StyledDiv>
                        <img
                            alt="Login"
                            className="login-video"
                            src={LoginImage}
                        />
                        <video
                            autoPlay
                            className="login-video"
                            id="background-video"
                            loop
                            muted
                            poster={LoginImage}
                        >
                            <source src={LoginVideo} type="video/mp4" />
                        </video>
                        <div
                            style={{
                                marginTop: innerWidth <= 1530 ? '-30px' : '0px'
                            }}
                        >
                            <CentrixLogo />
                        </div>
                        <div className="login-header">
                            See, protect and manage your entire attack surface
                        </div>
                        <div className="login-sub-header">
                            Powered by the Armis AI-driven Asset Intelligence
                            Engine
                        </div>
                        <div className="content">
                            <header className="header">
                                Log in to your Armis account
                            </header>
                            <BasicAuth
                                onClickLogin={() => {
                                    setIsLoginSuccess(true);
                                    setTimeout(() => {
                                        redirectAfterSuccess();
                                    }, 2000);
                                }}
                            />
                        </div>
                        <div
                            className="logo-resize"
                            style={{
                                position: 'absolute',
                                bottom: '0px',
                                right: '0px',
                                display: 'block',
                                marginRight: '24px',
                                marginBottom: '3px'
                            }}
                        >
                            <LoginPageLogo />
                        </div>
                    </StyledDiv>
                </div>
            </StyledContainer>
        );

    return (
        <Loader fade={false} loaderType={{ name: 'dots' }} show>
            <div className="dots" />
        </Loader>
    );
};

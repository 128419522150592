import React, { useEffect, useRef, useState } from 'react';

import { Grid } from '@mui/material';
import { HOURS_CAPS, MINUTES_CAPS } from 'src/constants/LabelText';
import {
    CRON_SCALES,
    cronGenerator,
    cronDecoder,
    SCALE_HELPER_TEXT
} from 'src/helpers/utility';

import {
    LabelContainer,
    StyledInput,
    StyledDropDownInput,
    StyledMenuItem
} from './CronEditField.style';
import { CronEditFieldProps } from './CronEditField.types';

/* Removed Seconds option from Scale as Currently will support only Minutes and Hours. */
const SchedulerScales = [
    {
        value: CRON_SCALES.MINUTES,
        label: MINUTES_CAPS
    },
    {
        value: CRON_SCALES.HOURS,
        label: HOURS_CAPS
    }
];

export const CronEditField = ({
    id,
    disabled = false,
    type = 'text',
    label,
    value,
    onChange,
    error = false
}: CronEditFieldProps) => {
    const inpRef = useRef<null | HTMLInputElement>(null);
    const [selectedScale, setSelectedScale] = useState<
        string | undefined | unknown
    >('');
    const [inputState, setInputState] = useState<string>('');
    const [cronValue, setCronValue] = useState<string>('');
    const [localHelperText, setLocalHelperText] = useState<string>('');

    useEffect(() => {
        if (value !== cronValue) {
            const cronObject = cronDecoder(value);
            if (cronObject.scale) setSelectedScale(cronObject.scale);
            else setSelectedScale(CRON_SCALES.MINUTES);
            setInputState(cronObject?.value || '');
        }
    }, [value]);

    useEffect(() => {
        if (inputState && selectedScale) {
            setCronValue(cronGenerator(inputState, selectedScale));
            onChange(cronGenerator(inputState, selectedScale));
        }
    }, [inputState, selectedScale]);

    useEffect(() => {
        if (error)
            setLocalHelperText(SCALE_HELPER_TEXT[selectedScale as string]);
        else setLocalHelperText('');
    }, [error]);

    return (
        <>
            <Grid item xs={2.5}>
                <LabelContainer>{label}</LabelContainer>
            </Grid>
            <Grid item xs={9.5}>
                <StyledInput
                    ref={inpRef}
                    autoComplete="off"
                    disabled={disabled}
                    error={error}
                    helperText={localHelperText}
                    id={id}
                    InputProps={{
                        endAdornment: (
                            <StyledDropDownInput
                                autoWidth
                                defaultValue={CRON_SCALES.MINUTES}
                                onChange={e =>
                                    setSelectedScale(e.target.value!)
                                }
                                value={selectedScale}
                                variant="outlined"
                            >
                                {SchedulerScales.map((scale: any) => (
                                    <StyledMenuItem
                                        key={scale.value}
                                        value={scale.value}
                                    >
                                        {scale.label}
                                    </StyledMenuItem>
                                ))}
                            </StyledDropDownInput>
                        )
                    }}
                    onBlur={() =>
                        onChange(cronGenerator(inputState, selectedScale))
                    }
                    onChange={e => setInputState(e.target.value)}
                    onKeyPress={event => {
                        if (!event.key.match(/[0-9]/)) {
                            event.preventDefault();
                        }
                    }}
                    placeholder={label}
                    type={type}
                    value={inputState}
                    variant="outlined"
                />
            </Grid>
        </>
    );
};

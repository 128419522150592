import { TextBox, Select, constants } from '@armis/armis-ui-library';
import { MenuItem, styled } from '@mui/material';

export const LabelContainer = styled('div')`
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? 'white'
            : `${constants.COLOR_GENERAL_3}`};
    height: 36px;
    vertical-align: middle;
    display: table-cell;
`;

export const StyledInput = styled(TextBox)`
    width: 30%;
    @media (max-width: 1024px) {
        width: 80%;
    }
    & .MuiInputAdornment-root {
        cursor: pointer;
        height: 100%;
    }
`;

export const StyledDropDownInput = styled(Select)`
    border: none;
    color: ${props => props.theme.palette.primary.main};
    width: 50%;
    min-width: 110px;
    svg {
        color: ${props => props.theme.palette.primary.main};
        font-size: 2.5rem !important;
    }
`;

export const StyledMenuItem = styled(MenuItem)`
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_26
            : constants.COLOR_GENERAL_24};
`;

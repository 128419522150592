import { constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

const linkStyle = `
    font-family: ${constants.PROXIMANOVA_W08_SEMIBOLD};
    font-size: 1.4rem;
    letter-spacing: 0.4px;
    text-decoration: none;
    cursor: pointer;
    
    &:hover {
        text-decoration: underline;
    }`;

export const StyledAnchorTag = styled('a')`
    ${linkStyle}
    color: ${props =>
        props.theme.palette.mode === 'dark' ? '#9DD2FF' : '#3569EB'};
`;

export const StyledLink = styled(Link)`
    ${linkStyle}
    color: ${props =>
        props.theme.palette.mode === 'dark' ? '#9DD2FF' : '#3569EB'};
`;

export const StyledLoaderContainer = styled('div')<{
    isHidden: boolean;
}>(({ isHidden }) => ({
    visibility: isHidden ? 'hidden' : 'visible',
    height: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
}));

export const StyledInfoContainer = styled('div')`
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-width: 420px;
    margin: 15px;

    .row:not(:last-child) {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: solid 1px ${constants.COLOR_GENERAL_7};
    }
`;

export const StyledRow = styled('div')`
    display: flex;
    flex-direction: row;

    .left {
        min-width: 160px;
        padding: 0 10px;
        line-height: normal;
        white-space: pre-wrap;
        word-break: break-word;
        font-size: 1.3rem;
        color: ${constants.COLOR_GENERAL_4};
    }

    .right {
        overflow-x: auto;
        padding: 0 10px;
        white-space: pre-wrap;
        line-height: normal;
        font-size: 1.2rem;
        color: ${constants.COLOR_GENERAL_4};
    }
`;

export const FlexRow = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
`;

import { styled } from '@mui/material';
import { Modal } from 'src/pages/components/Modal';

export const StyledOption = styled('div')`
    line-height: 28px;
    padding: 4px 10px;
`;

export const StyledActionContainer = styled('div')`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: space-between;
`;

export const StyledActionFooter = styled('div')`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    .left {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }

    .right {
        display: flex;
    }
`;

export const StyledSyncResource = styled('span')`
    font-weight: bold;
`;

export const PolicyActionModal = styled(Modal)`
    .MuiDialogContent-root {
        padding: 20px !important;
    }
`;

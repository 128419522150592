import React, { useEffect, useState } from 'react';

import {
    CHECKBOX_STATUS,
    MenuItemProps,
    MultiSelect,
    Select
} from '@armis/armis-ui-library';
import { FormLabel } from '@mui/material';
import { AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';
import { TOAST_ID } from 'src/constants/APIConstants';
import { FIELD_TYPE_MAP } from 'src/constants/CommonConstants';
import {
    ALL_BOUNDARIES,
    GENERATING_REPORT_INFO,
    SELECT_BOUNDARIES,
    TOTAL_SELECTED_BOUNDARIES
} from 'src/constants/LabelText';
import {
    TOAST_TYPE,
    displayErrorMessage,
    showToast
} from 'src/helpers/utility';
import { Tenant } from 'src/pages/containers/TenantManagement/TenantManagement.types';
import {
    getBoundaries,
    getTenants,
    previewReport,
    runReport
} from 'src/services/api.service';
import { GenericResponseData } from 'src/types/APIResponseTypes';
import { DropDownWithKeyValue, FilterItems } from 'src/types/CommonTypes';

import { StyledModal } from './GenerateReport.style';
import {
    Boundary,
    GenerateReportActionProps,
    GenerateReportResponse
} from './GenerateReport.types';
import { ModalField } from '../ModalField/ModalField';
import { StyledField } from '../ModalField/ModalField.style';

export const GenerateReport = ({
    reportId,
    reportTemplateName,
    onClickCancel,
    onSubmitSuccess,
    setIsLoading,
    title,
    submitBtnLabel,
    type,
    reportFieldsData,
    reportTitleAndName,
    icon,
    logoPath
}: GenerateReportActionProps) => {
    const timeFrameEnum = [
        'LAST_DAY',
        'LAST_7_DAY',
        'LAST_14_DAY',
        'LAST_30_DAY'
    ];

    const outputFormatEnum = ['PDF', 'DOC', 'PPT', 'JSON'];

    const [outputFormat, setoutputFormat] = useState(0);
    const [timeFrame, settimeFrame] = useState(0);
    const [tenants, setTenants] = useState<DropDownWithKeyValue[]>([]);
    const [boundaries, setBoundaries] = useState<FilterItems[]>([]);
    const [boundariescount, setBoundariescount] = useState(ALL_BOUNDARIES);
    const [selectedBoundaries, setselectedBoundaries] = useState<string[]>([]);
    const [selectedTenant, setselectedTenant] = useState(-1);

    const onChangeOutputFormat = (e: any) => {
        setoutputFormat(e);
    };
    const onChangeTimeFrame = (e: any) => {
        settimeFrame(e);
    };
    const onChangeTenants = (e: any) => {
        setselectedTenant(e);
    };

    const onClickSubmitHandler = () => {
        setIsLoading(true);
        // ::TODO we should fix this argument according to the type it is opened.
        if (type === 'generate') {
            runReport({
                reportId,
                tenantId: tenants[selectedTenant].id,
                outputFormat: outputFormatEnum[outputFormat],
                timeFrame: timeFrameEnum[timeFrame],
                boundaries: selectedBoundaries
            })
                .then((res: AxiosResponse<GenerateReportResponse>) => {
                    showToast(
                        GENERATING_REPORT_INFO.replace(
                            '%s',
                            reportTemplateName
                        ),
                        TOAST_TYPE.INFO,
                        TOAST_ID
                    );
                    onSubmitSuccess({
                        reportId: res.data.reportConfig.id,
                        reportHistoryId: res.data.id,
                        reportTemplateName
                    });
                })
                .catch(err => {
                    displayErrorMessage(err);
                    setIsLoading(false);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        if (type === 'preview') {
            const tempReportData = cloneDeep(reportFieldsData);

            let order = 1;

            tempReportData.reportlet_data.forEach((report: any) => {
                delete report.dragAndDropId;
                delete report.previewDragAndDropId;
                report.order = order;
                order++;
            });

            const postData = {
                reportDto: {
                    title: reportTitleAndName.title,
                    name: reportTitleAndName.name,
                    tags: reportTitleAndName.tags,
                    logoPath: icon?.length ? null : logoPath,
                    elements: tempReportData.reportlet_data
                },
                tenantId: tenants[selectedTenant].id,
                outputFormat: outputFormatEnum[outputFormat],
                timeFrame: timeFrameEnum[timeFrame],
                boundaries: selectedBoundaries
            };

            const formData = new FormData();
            if (icon?.length) {
                formData.append('file', icon[0]);
            }

            formData.append(
                'runReport',
                new Blob([JSON.stringify(postData)], {
                    type: 'application/json'
                })
            );

            previewReport(formData).then(res =>
                onSubmitSuccess({
                    previewId: res.data.id,
                    previewName: res.data.name || res.data.title,
                    fileType: outputFormatEnum[outputFormat]
                })
            );
        }
    };

    useEffect(() => {
        const makeAnApiCall = async () => {
            try {
                const res: AxiosResponse<GenericResponseData<Tenant>> =
                    await getTenants('', false);

                const getTotalTenants = res.data.content.map((item: any) => ({
                    label: item.name,
                    id: item.id
                }));
                setTenants(getTotalTenants);
                // getBoundariesbyTenants(getTotalTenants[0].id);
                setselectedTenant(0);
            } catch (err: any) {
                displayErrorMessage(err);
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        };

        setIsLoading(true);
        makeAnApiCall();
    }, []);

    useEffect(() => {
        const getBoundariesbyTenants = async (tenantId: string) => {
            try {
                const res: AxiosResponse<Boundary[]> = await getBoundaries(
                    tenantId
                );

                const getAllBoundaries = res.data.map((item: any) => ({
                    label: item.name,
                    id: item.id,
                    checkStatus: CHECKBOX_STATUS.CHECKED,
                    labelRender: <span>{item.name}</span>
                }));
                setBoundaries(getAllBoundaries);
                setselectedBoundaries(
                    getAllBoundaries.map(element => element.id)
                );
            } catch (err: any) {
                displayErrorMessage(err);
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        };
        if (tenants[selectedTenant]?.id) {
            getBoundariesbyTenants(tenants[selectedTenant]?.id);
        }
    }, [selectedTenant]);

    useEffect(() => {
        const totalBoundariesSelected = selectedBoundaries.length;
        if (totalBoundariesSelected === 0) {
            setBoundariescount(SELECT_BOUNDARIES);
        } else if (totalBoundariesSelected === boundaries.length) {
            setBoundariescount(ALL_BOUNDARIES);
        } else if (totalBoundariesSelected === 1) {
            const boundaryName = boundaries.find(
                element => element.id === selectedBoundaries[0]
            );
            setBoundariescount(boundaryName?.label!);
        } else {
            setBoundariescount(
                TOTAL_SELECTED_BOUNDARIES.replace(
                    '%s',
                    totalBoundariesSelected.toString()
                )
            );
        }
        const updatedBoundaries = boundaries.map(boundary =>
            selectedBoundaries.find(boundary1 => boundary1 === boundary.id)
                ? { ...boundary, checkStatus: CHECKBOX_STATUS.CHECKED }
                : { ...boundary, checkStatus: CHECKBOX_STATUS.UNCHECKED }
        );
        setBoundaries(updatedBoundaries);
    }, [selectedBoundaries]);

    return (
        <StyledModal
            displayBtn="all"
            isModalOpen
            onCancel={onClickCancel}
            onSubmit={onClickSubmitHandler}
            submitBtnLabel={submitBtnLabel}
            title={title}
        >
            <ModalField
                key={1}
                error={false}
                helperText=""
                index={1}
                items={tenants.map(singleTenant => singleTenant.label)}
                labelName="Tenant"
                onChange={onChangeTenants}
                type={FIELD_TYPE_MAP.DROPDOWN}
                value={selectedTenant}
            />
            <StyledField key={2}>
                <FormLabel htmlFor="select-boundaries">Boundaries</FormLabel>

                <Select
                    defaultValue={ALL_BOUNDARIES}
                    id="select-boundaries"
                    MenuProps={{
                        sx: { zIndex: 10002 }
                    }}
                    renderValue={() => boundariescount}
                    sx={{ width: '100%' }}
                    value={boundariescount}
                    variant="outlined"
                >
                    <MultiSelect
                        isAllSelected
                        items={boundaries}
                        onSelectionChanged={(
                            selectedItems: MenuItemProps[]
                        ) => {
                            setselectedBoundaries(
                                selectedItems.map(element => element.id)
                            );
                        }}
                        showSelectAllOption
                    />
                </Select>
            </StyledField>
            <ModalField
                key={3}
                error={false}
                helperText=""
                index={3}
                items={[
                    'Last Day',
                    'Last 7 Days',
                    'Last 14 Days',
                    'Last 30 Days'
                ]}
                labelName="Time Frame"
                onChange={onChangeTimeFrame}
                type={FIELD_TYPE_MAP.DROPDOWN}
                value={timeFrame}
            />
            <ModalField
                key={4}
                error={false}
                helperText=""
                index={4}
                items={['PDF', 'DOC', 'PPT', 'JSON']}
                labelName="Output Format"
                onChange={onChangeOutputFormat}
                type={FIELD_TYPE_MAP.DROPDOWN}
                value={outputFormat}
            />
        </StyledModal>
    );
};

import React, { useState, useMemo, useEffect } from 'react';

import { Tab, TabGroup } from '@armis/armis-ui-library';
import { useDispatch, useSelector } from 'react-redux';
import {
    NEXT,
    PUSH,
    PUSH_WIZARD_STEP_1,
    PUSH_WIZARD_STEP_2
} from 'src/constants/LabelText';
import { allowedPushWizardTab } from 'src/pages/containers/PolicyTemplate/constants';
import { PushWizardContainer } from 'src/pages/containers/PolicyTemplate/PolicyTemplate.style';
import { PolicyTemplatePushWizardProps } from 'src/pages/containers/PolicyTemplate/PolicyTemplate.types';
import {
    selectSelectedTab,
    selectSelectedTenants,
    setSelectedTab
} from 'src/store/slices/pushWizardStepSlice';

import { SelectTenantStep } from './SelectTenantStep';
import { ValidatePushStep } from './ValidatePushStep';

const PolicyTemplatePushWizard = ({
    setSubmitBtnLabel,
    validatePolicyTemplatePush
}: PolicyTemplatePushWizardProps) => {
    const [tabs, setTabs] = useState<Tab[]>([
        {
            label: PUSH_WIZARD_STEP_1,
            tabValue: allowedPushWizardTab.SELECT_TENANT,
            disabled: false
        },
        {
            label: PUSH_WIZARD_STEP_2,
            tabValue: allowedPushWizardTab.VALIDATE_PUSH,
            disabled: true
        }
    ]);

    const selectedTenants = useSelector(selectSelectedTenants);
    const selectedTab = useSelector(selectSelectedTab);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedTab === allowedPushWizardTab.SELECT_TENANT) {
            setSubmitBtnLabel(NEXT);
        } else if (selectedTab === allowedPushWizardTab.VALIDATE_PUSH) {
            setSubmitBtnLabel(PUSH);
        }
    }, [selectedTab]);

    useEffect(() => {
        if (selectedTenants.length === 0) {
            setTabs(prevValue =>
                prevValue.map(tab =>
                    tab.tabValue !== allowedPushWizardTab.SELECT_TENANT
                        ? { ...tab, disabled: true }
                        : tab
                )
            );
        } else {
            setTabs(prevValue =>
                prevValue.map(tab => ({ ...tab, disabled: false }))
            );
        }
    }, [selectedTenants]);

    const renderContent = useMemo(() => {
        let content: JSX.Element | string = '';

        switch (selectedTab) {
            case allowedPushWizardTab.SELECT_TENANT:
                content = (
                    <>
                        <SelectTenantStep />
                        <div />
                    </>
                );
                break;
            case allowedPushWizardTab.VALIDATE_PUSH:
                content = (
                    <>
                        <SelectTenantStep hideStep />
                        <ValidatePushStep />
                    </>
                );
                break;
            default:
                break;
        }
        return content;
    }, [selectedTab]);

    return (
        <PushWizardContainer>
            <TabGroup
                onChange={(_, value) => {
                    if (value === allowedPushWizardTab.VALIDATE_PUSH)
                        validatePolicyTemplatePush();
                    else dispatch(setSelectedTab(value));
                }}
                sx={{
                    '.scroll-wrapper': {
                        flex: 1
                    },
                    '.tab': {
                        maxWidth: 'unset',
                        flex: 1
                    }
                }}
                tabsItems={tabs}
                value={selectedTab}
            />
            {renderContent}
        </PushWizardContainer>
    );
};

export default PolicyTemplatePushWizard;

import React, { useMemo, useRef } from 'react';

import { CustomHeader, Table, TableNoData } from '@armis/armis-ui-library';
import { useSelector } from 'react-redux';
import { NO_DATA_TO_SHOW } from 'src/constants/TableConstants';
import { commonDashboardColumnDef } from 'src/helpers/ColumnsConfig';
import { selectUser } from 'src/store/slices/userSlice';

import { CommonTableProps } from './CommonTable.types';

export const CommonTable = ({ rowData }: CommonTableProps) => {
    const tableRef = useRef();
    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            resizable: true
        }),
        []
    );

    const currentUser = useSelector(selectUser);

    const isArmisUser = currentUser.username.includes('@armis.com');

    return (
        <Table
            ref={tableRef}
            columnDefs={commonDashboardColumnDef}
            context={{ isArmisUser }}
            defaultColDef={defaultColDefs}
            noRowsOverlayComponent={TableNoData}
            noRowsOverlayComponentParams={{
                content: NO_DATA_TO_SHOW
            }}
            rowData={rowData}
        />
    );
};

import React, { useTransition } from 'react';

import { onFieldChangeHandler } from 'src/helpers/utility';
import { ModalField } from 'src/pages/components/ModalField/ModalField';
import { ModalFieldType } from 'src/types/CommonTypes';

import { actionFormMetaData } from './constants';

export interface EditTenantProps {
    modalFields: ModalFieldType;
    setModalFields: React.Dispatch<React.SetStateAction<ModalFieldType>>;
}

export const EditTenant = ({
    modalFields,
    setModalFields
}: EditTenantProps) => {
    const [, startTransition] = useTransition();

    return (
        <div>
            {actionFormMetaData.map(
                ({ labelName, type, inputType, placeHolder }, index) => (
                    <ModalField
                        key={index}
                        index={index}
                        inputType={inputType}
                        {...modalFields[labelName]}
                        labelName={labelName}
                        onChange={fieldValue =>
                            onFieldChangeHandler(
                                fieldValue,
                                labelName,
                                type,
                                actionFormMetaData,
                                setModalFields,
                                modalFields,
                                startTransition
                            )
                        }
                        placeHolder={placeHolder}
                        type={type}
                    />
                )
            )}
        </div>
    );
};

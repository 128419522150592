import React, { useState, useEffect } from 'react';

import { Breadcrumbs } from '@armis/armis-ui-library';
import { Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { REPORT_TEMPLATES } from 'src/constants/LabelText';
import {
    REPORTS,
    TEMPLATE_ROUTE,
    TENANT_MANAGEMENT
} from 'src/constants/RouteConstants';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { Header } from 'src/pages/components/Header';
import { getSingleReportTemplate, getTenant } from 'src/services/api.service';

import { ReportHistory } from './ReportHistory';
import { Report } from '../ReportTemplate/ReportTemplate.types';
import { Tenant } from '../TenantManagement/TenantManagement.types';

interface HistoryViewProps {
    setIsLoading: (
        isComponentLoading: boolean,
        needFullPageLoading?: boolean
    ) => undefined;
}

const History = ({ setIsLoading }: HistoryViewProps) => {
    const { reportTemplateId, tenantId } = useParams();
    const navigate = useNavigate();
    const [currentTenant, setCurrentTenant] = useState<Tenant>();
    const [currentReport, setCurrentReport] = useState<Report>();

    const doApiCall = () => {
        getTenant(tenantId!)
            .then((res: AxiosResponse<Tenant>) => {
                setCurrentTenant(res.data);
            })
            .catch(() => {
                navigate(`${TENANT_MANAGEMENT}`, { replace: true });
            });

        getSingleReportTemplate(reportTemplateId!)
            .then(res => {
                setCurrentReport(res.data);
            })
            .catch(() => {
                navigate(`${TEMPLATE_ROUTE}/${REPORTS}`, { replace: true });
            });
    };

    useEffect(() => {
        // setIsLoading(true, true);
        doApiCall();
    }, [reportTemplateId, tenantId, navigate]);

    return (
        <>
            <Header>
                <Breadcrumbs>
                    <Link
                        className="parent-link"
                        to={`${TEMPLATE_ROUTE}/${REPORTS}`}
                    >
                        {REPORT_TEMPLATES}
                    </Link>
                    <Link
                        className="parent-link"
                        to={`${TEMPLATE_ROUTE}/${REPORTS}/${reportTemplateId}${TENANT_MANAGEMENT}`}
                    >
                        {currentReport?.name}
                    </Link>
                    <Typography
                        style={{ fontFamily: 'Proxima Nova Bld' }}
                        variant="h4"
                    >
                        {currentTenant?.name}
                    </Typography>
                </Breadcrumbs>
            </Header>
            <div className="control table">
                <ReportHistory
                    reportHistoryByTenant={false}
                    reportHistoryByTenantByReport
                    reportTemplateId={reportTemplateId as string}
                    setIsLoading={setIsLoading}
                    tenantId={tenantId as string}
                />
            </div>
        </>
    );
};

export const ReportHistoryByTenantByReport = IsLoadingHOC(History);

import { styled } from '@mui/material';

import { Modal } from '../Modal';

export const StyledModal = styled(Modal)`
    .MuiInputBase-root {
        min-width: 75px;
        max-width: 75px;
    }
    .react-pdf__message--error {
        color: #121212;
    }
    #header-bar {
        display: none;
    }
    #proxy-renderer {
        // overflow-y: unset;
    }
    .MuiDialogContent-root {
        padding: 0;
        max-height: 70vh !important;
        min-height: 70vh !important;
    }
    .MuiDialogActions-root {
        padding: 10px 30px 10px;
    }
    .header {
        padding: 0 30px !important;
    }
    .generate-as-button {
        min-width: 80px !important;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    #pdf-controls {
        // display: none;
        background-color: white;
        z-index: 3;

        #pdf-download {
            display: none;
        }
    }
`;

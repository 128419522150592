import React from 'react';

import { WarningTri, ErrorTri } from '@armis/armis-ui-library';
import { StyledAnchorTag } from 'src/helpers/Common.style';

import { StyledDiv } from './ThresoldStatus.style';

interface ThresoldStatusProps {
    thresoldValue: number;
    actualValue: number;
    redirectLink: string;
}
export const ThresoldStatus = ({
    thresoldValue,
    actualValue,
    redirectLink
}: ThresoldStatusProps) => {
    let statusSVG = null;
    if (actualValue >= thresoldValue) {
        statusSVG = <ErrorTri />;
    } else if (actualValue < thresoldValue && actualValue !== 0) {
        statusSVG = <WarningTri />;
    }
    return (
        <StyledDiv>
            {statusSVG}
            <StyledAnchorTag
                className="primary-color"
                href={redirectLink}
                rel="noreferrer"
                target="_blank"
            >
                {actualValue}
            </StyledAnchorTag>
        </StyledDiv>
    );
};

import { constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const StyledDiv = styled('div')`
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    .MuiInputBase-root {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_29
                : constants.COLOR_WHITE};
    }
`;

export const StyledSpan = styled('span')`
    font-family: ${constants.PROXIMANOVA_W08_SEMIBOLD};
    font-size: 1.4rem;
`;

export const OptionalSpanStyle = styled('span')`
    font-size: 1rem;
    font-family: ${constants.PROXIMANOVA_W08_THIN_REGULAR};
`;

import React, { useState, useEffect, useRef } from 'react';

import {
    Add,
    ArrowDown,
    Button,
    IconButton,
    IconPencil
} from '@armis/armis-ui-library';
import { FormLabel, Alert } from '@mui/material';
import { cloneDeep, isArray } from 'lodash';
import uuid from 'react-uuid';
import {
    ADD,
    CONDITIONS,
    TYPE,
    POLICY_DRIVEN_ACTIONS,
    SEVERITY,
    POLICY_SEVERITY_HEADER,
    ALERT,
    INVALID_POLICY_FORMAT,
    POLICY_TEMPLATE_CONDITIONS_QUERY
} from 'src/constants/LabelText';
import {
    allowedRulesOperator,
    allowedSeverities,
    policyTemplateConditionsType,
    requiredKeysinPolicyJson
} from 'src/pages/containers/PolicyTemplate/constants';
import {
    ActivityRow,
    EditContainer,
    InfoContainer
} from 'src/pages/containers/PolicyTemplate/PolicyTemplate.style';
import {
    PolicyRuleInputType,
    PolicyRulesStateType,
    PolicyTemplateEditProps
} from 'src/pages/containers/PolicyTemplate/PolicyTemplate.types';
import {
    PolicyJsonType,
    PolicyRules
} from 'src/pages/containers/TenantView/Policies/Policies.types';
import { PromotePolicy } from 'src/pages/containers/TenantView/Policies/PolicyActions/PromotePolicy';

import LogicTree from './LogicTree';

export const PolicyTemplateEdit = ({
    selectedPolicyTemplate,
    onPolicyTemplateChanged
}: PolicyTemplateEditProps) => {
    const [policyTemplate, setpolicyTemplate] = useState(
        selectedPolicyTemplate
    );
    const [policyTemplateName, setpolicyTemplateName] = useState(
        selectedPolicyTemplate.templateName
    );
    const [selectedTags, setselectedTags] = useState<string[]>(
        selectedPolicyTemplate.tags
    );
    const [isValidPolicy, setIsValidPolicy] = useState(true);

    const generateRules = (rule: string | PolicyRules) => {
        if (typeof rule === 'string') {
            return {
                key: uuid(),
                value: rule
            };
        }

        const ruleKeys = Object.keys(rule ?? {});
        const operator = ruleKeys[0];
        const obj: PolicyRulesStateType = {};

        if (
            ruleKeys.length !== 1 ||
            !Object.keys(allowedRulesOperator).includes(operator) ||
            !isArray(rule[operator]) ||
            rule[operator].length === 0
        ) {
            setIsValidPolicy(false);
            return {};
        }

        obj[operator] = rule[operator]
            .map(lRule => generateRules(lRule))
            .filter(uRule => uRule.key || Object.keys(uRule).length);
        return obj;
    };

    const [conditionsInputBoxList, setConditionsInputBoxList] =
        useState<PolicyRulesStateType>(
            () =>
                generateRules(
                    selectedPolicyTemplate.policyJson.rules ?? {}
                ) as PolicyRulesStateType
        );

    const getValidSeverities = (policyJson: PolicyJsonType) =>
        policyJson.actions?.filter(
            action =>
                action?.type === 'alert' &&
                Object.keys(allowedSeverities).includes(
                    action?.params?.severity || ''
                )
        );

    const [actionSeverityValue, setactionSeverityValue] = useState<boolean[][]>(
        () =>
            getValidSeverities(selectedPolicyTemplate.policyJson)?.map(action =>
                Object.keys(allowedSeverities).map(
                    severity => severity === action?.params?.severity
                )
            )
    );
    const [severityRowExpandedState, setseverityRowExpandedState] = useState<
        boolean[]
    >(() =>
        getValidSeverities(selectedPolicyTemplate.policyJson).map(() => false)
    );

    const firstRender = useRef(true);

    useEffect(() => {
        const instanceOfPolicyJson = (json: any): json is PolicyJsonType =>
            requiredKeysinPolicyJson.every(key => Object.hasOwn(json, key)) &&
            Object.keys(policyTemplateConditionsType).includes(json?.ruleType);

        setIsValidPolicy(
            prevValue =>
                prevValue &&
                instanceOfPolicyJson(selectedPolicyTemplate.policyJson)
        );
    }, [selectedPolicyTemplate.policyJson]);

    useEffect(() => {
        onPolicyTemplateChanged(policyTemplate);
    }, [policyTemplate]);

    useEffect(() => {
        if (!isValidPolicy) return;

        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        setpolicyTemplate(prevValue => {
            let isValidRules = false;

            const getRuleValues = (
                rule: PolicyRuleInputType | PolicyRulesStateType
            ): string | PolicyRules => {
                if (rule.key) {
                    const ruleValue = rule.value as string;
                    if (ruleValue.trim().length !== 0) isValidRules = true;
                    return ruleValue;
                }
                const operator = Object.keys(rule)[0];
                const obj: PolicyRules = {};

                if (!operator) return {};

                obj[operator] = (rule as PolicyRulesStateType)[operator].map(
                    lRule => getRuleValues(lRule)
                );
                return obj;
            };

            const updatedPolicyTemplate = cloneDeep(prevValue);
            updatedPolicyTemplate.templateName = policyTemplateName;
            updatedPolicyTemplate.policyJson.name = policyTemplateName!;
            updatedPolicyTemplate.tags = selectedTags;
            updatedPolicyTemplate.policyJson.rules = getRuleValues(
                conditionsInputBoxList
            ) as PolicyRules;
            updatedPolicyTemplate.isValidRules = isValidRules; // check to disable the submit button.

            updatedPolicyTemplate.policyJson?.actions
                .filter(
                    action =>
                        action?.type === 'alert' &&
                        Object.keys(allowedSeverities).includes(
                            action?.params?.severity || ''
                        )
                )
                .forEach((action, index) => {
                    [action.params.severity] = [
                        ...Object.keys(allowedSeverities).filter(
                            (severity, sIndex) =>
                                actionSeverityValue[index][sIndex] && severity
                        )
                    ];
                });
            return updatedPolicyTemplate;
        });
    }, [
        policyTemplateName,
        selectedTags,
        conditionsInputBoxList,
        actionSeverityValue,
        severityRowExpandedState,
        isValidPolicy
    ]);

    const getFieldHeader = (headerTitle: string) => (
        <div className="header">
            <FormLabel className="title">
                <div className="icon-title-separator">
                    <IconPencil height={14} width={14} />
                    <span className="title-name"> {headerTitle}</span>
                </div>
            </FormLabel>
        </div>
    );

    return (
        <EditContainer>
            {isValidPolicy ? (
                <>
                    <PromotePolicy
                        defaultTags={policyTemplate.tags}
                        icon={<IconPencil height={14} width={14} />}
                        onNameChange={newValue =>
                            setpolicyTemplateName(newValue)
                        }
                        policyName={policyTemplateName!}
                        selectedTags={selectedTags}
                        setSelectedTags={setselectedTags}
                        textInputDisabled={false}
                    />
                    {Object.keys(conditionsInputBoxList).length && (
                        <div className="policy-template-segment">
                            {getFieldHeader(CONDITIONS)}
                            <div className="content">
                                <div className="row trigger">
                                    <div className="label">
                                        <div className="text">
                                            {TYPE}:{' '}
                                            {
                                                policyTemplateConditionsType[
                                                    policyTemplate.policyJson
                                                        ?.ruleType
                                                ]
                                            }
                                        </div>
                                    </div>
                                </div>
                                <ActivityRow>
                                    <div className="tree-container">
                                        <div className="logic-tree">
                                            <LogicTree
                                                conditionsInputRules={
                                                    conditionsInputBoxList
                                                }
                                                inputPlaceholder={POLICY_TEMPLATE_CONDITIONS_QUERY.replace(
                                                    '%s',
                                                    policyTemplateConditionsType[
                                                        policyTemplate
                                                            .policyJson
                                                            ?.ruleType
                                                    ]
                                                )}
                                                level={0}
                                                onChangeRuleGroup={childRuleGroup => {
                                                    setConditionsInputBoxList(
                                                        childRuleGroup
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="logic-action">
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                setConditionsInputBoxList(
                                                    prevValue => {
                                                        const operator =
                                                            Object.keys(
                                                                prevValue
                                                            )[0];
                                                        const oldRules =
                                                            cloneDeep(
                                                                prevValue[
                                                                    operator
                                                                ]
                                                            );
                                                        return {
                                                            ...prevValue,
                                                            [operator]: [
                                                                ...oldRules,
                                                                {
                                                                    key: uuid(),
                                                                    value: ''
                                                                }
                                                            ]
                                                        };
                                                    }
                                                );
                                            }}
                                            startIcon={<Add />}
                                            variant="text"
                                        >
                                            {ADD}
                                        </Button>
                                    </div>
                                </ActivityRow>
                            </div>
                        </div>
                    )}
                    {!!actionSeverityValue && !!actionSeverityValue.length && (
                        <div className="policy-template-segment">
                            {getFieldHeader(POLICY_DRIVEN_ACTIONS)}
                            <div className="content">
                                {actionSeverityValue.map(
                                    (actionValue, index: number) => (
                                        <div
                                            key={index}
                                            className="alert-action-container"
                                        >
                                            <div className="action-border">
                                                <div className="action-content">
                                                    <div
                                                        className="header"
                                                        onClick={() => {
                                                            setseverityRowExpandedState(
                                                                prevValue =>
                                                                    prevValue.map(
                                                                        (
                                                                            curr: boolean,
                                                                            exIndex: number
                                                                        ) =>
                                                                            exIndex ===
                                                                            index
                                                                                ? !curr
                                                                                : curr
                                                                    )
                                                            );
                                                        }}
                                                    >
                                                        <div className="compound-title">
                                                            {
                                                                POLICY_SEVERITY_HEADER
                                                            }
                                                            <span className="highlight">
                                                                {Object.keys(
                                                                    allowedSeverities
                                                                )
                                                                    .filter(
                                                                        (
                                                                            value,
                                                                            sIndex
                                                                        ) =>
                                                                            actionValue[
                                                                                sIndex
                                                                            ] &&
                                                                            value
                                                                    )
                                                                    .map(
                                                                        key =>
                                                                            allowedSeverities[
                                                                                key as keyof typeof allowedSeverities
                                                                            ]
                                                                    )}
                                                            </span>
                                                            {`${SEVERITY} `}
                                                            {
                                                                policyTemplate
                                                                    ?.policyJson
                                                                    ?.actions[
                                                                    index
                                                                ].params.type
                                                            }{' '}
                                                            {ALERT}
                                                        </div>
                                                        <IconButton>
                                                            <ArrowDown
                                                                className={
                                                                    severityRowExpandedState[
                                                                        index
                                                                    ]
                                                                        ? 'open'
                                                                        : ''
                                                                }
                                                            />
                                                        </IconButton>
                                                    </div>
                                                    <div
                                                        className="body"
                                                        style={{
                                                            maxHeight:
                                                                severityRowExpandedState[
                                                                    index
                                                                ]
                                                                    ? '399px'
                                                                    : '0px',
                                                            opacity:
                                                                severityRowExpandedState[
                                                                    index
                                                                ]
                                                                    ? 1
                                                                    : 0
                                                        }}
                                                    >
                                                        {severityRowExpandedState[
                                                            index
                                                        ] && (
                                                            <div className="alert-severity ">
                                                                <div className="label">
                                                                    {SEVERITY}
                                                                </div>
                                                                <div className="radio-btn-group">
                                                                    {Object.keys(
                                                                        allowedSeverities
                                                                    ).map(
                                                                        (
                                                                            value,
                                                                            sIndex
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    index +
                                                                                    sIndex
                                                                                }
                                                                                className="radio-btn"
                                                                            >
                                                                                <input
                                                                                    checked={
                                                                                        actionValue[
                                                                                            sIndex
                                                                                        ]
                                                                                    }
                                                                                    id={
                                                                                        value +
                                                                                        index
                                                                                    }
                                                                                    name={`severity-radio-${`${index}`} `}
                                                                                    onChange={e => {
                                                                                        const updatedValues =
                                                                                            cloneDeep(
                                                                                                actionSeverityValue
                                                                                            );
                                                                                        updatedValues.forEach(
                                                                                            (
                                                                                                action,
                                                                                                uIndex
                                                                                            ) => {
                                                                                                if (
                                                                                                    uIndex ===
                                                                                                    index
                                                                                                )
                                                                                                    action =
                                                                                                        action.fill(
                                                                                                            false
                                                                                                        );
                                                                                            }
                                                                                        );
                                                                                        updatedValues[
                                                                                            index
                                                                                        ][
                                                                                            sIndex
                                                                                        ] =
                                                                                            e.target.checked;
                                                                                        setactionSeverityValue(
                                                                                            updatedValues
                                                                                        );
                                                                                    }}
                                                                                    type="radio"
                                                                                    value={
                                                                                        value
                                                                                    }
                                                                                />
                                                                                <FormLabel
                                                                                    className={`btn-label ${
                                                                                        actionValue[
                                                                                            sIndex
                                                                                        ] &&
                                                                                        'checked'
                                                                                    }`}
                                                                                    htmlFor={
                                                                                        value +
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        allowedSeverities[
                                                                                            value as keyof typeof allowedSeverities
                                                                                        ]
                                                                                    }
                                                                                </FormLabel>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <InfoContainer>
                    <Alert severity="error">{INVALID_POLICY_FORMAT}</Alert>
                </InfoContainer>
            )}
        </EditContainer>
    );
};

import React, { useState, useEffect, useMemo, useRef } from 'react';

import Editor from '@monaco-editor/react';
import { Box, useTheme } from '@mui/material';
import { FaArrowsAltH } from 'react-icons/fa';
import { TOAST_ID } from 'src/constants/APIConstants';
import {
    CONFIRM_ACTION,
    CONTINUE,
    POLICY,
    POLICY_TEMPLATE_DIFF_OVERRIDE_WARNING,
    PROMOTE,
    PROMOTE_TO_TEMPLATE,
    RESOURCE,
    RESOURCE_SYNCED_SUCCESS,
    SAVE_CHANGES,
    TEMPLATE,
    TEMPLATE_CREATED,
    TENANT_POLICY
} from 'src/constants/LabelText';
import {
    displayErrorMessage,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import { Modal } from 'src/pages/components/Modal';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';
import { PolicyActionModal } from 'src/pages/containers/TenantView/Policies/Policies.style';
import {
    AvailablePolicyActions,
    Policy,
    PolicyActionsProps
} from 'src/pages/containers/TenantView/Policies/Policies.types';
import { ComparePolicy } from 'src/pages/containers/TenantView/Policies/PolicyActions/ComparePolicy';
import { PromotePolicy } from 'src/pages/containers/TenantView/Policies/PolicyActions/PromotePolicy';
import {
    createPolicyTemplate,
    getPolicyTemplate,
    updateResource
} from 'src/services/api.service';
import { UpdateResourcePayload } from 'src/types/APIPayloadTypes';

export const PolicyActions = ({
    modalOpen,
    policy,
    actionType,
    onModalClose,
    onActionSuccess,
    setIsLoading
}: PolicyActionsProps) => {
    const [localTemplate, setLocalTemplate] = useState<Policy | null>(null);
    const [localPolicy, setLocalPolicy] = useState<Policy>(policy!);
    const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
    const [showDiffOverrideWarning, setShowDiffOverrideWarning] =
        useState(false);

    const [syncSelectedOption, setSyncSelectedOption] = useState(POLICY);

    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const templateData = useRef<Policy | null>(null);
    const modalProperties = useRef<{
        title: string | JSX.Element;
        submitBtnTitle: string;
    }>({
        title: '',
        submitBtnTitle: ''
    });

    useEffect(() => {
        if (actionType === AvailablePolicyActions.VIEW_DIFF) {
            setIsLoading(true);
            if (policy?.templatePolicyId && policy.isModified) {
                getPolicyTemplate(policy.templatePolicyId)
                    .then(res => {
                        templateData.current = res.data;
                        setLocalTemplate(res.data);
                    })
                    .catch(err => {
                        displayErrorMessage(err);
                        templateData.current = null;
                        onModalClose();
                        setLocalTemplate(null);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        }
    }, [actionType]);

    useEffect(() => {
        setLocalPolicy(policy!);
    }, [policy]);

    const saveModelContentChanges = () => {
        setIsLoading(true);
        if (actionType === AvailablePolicyActions.PROMOTE) {
            createPolicyTemplate({
                tenantPolicyId: localPolicy.tenantPolicyId,
                tags: selectedTags,
                tenantId: localPolicy.sourceTenantDto.id
            })
                .then(() => {
                    showToast(TEMPLATE_CREATED, TOAST_TYPE.SUCCESS, TOAST_ID);
                    onActionSuccess();
                    onModalClose();
                })
                .catch(err => {
                    displayErrorMessage(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else if (actionType === AvailablePolicyActions.VIEW_DIFF) {
            const data: UpdateResourcePayload = {
                from: (syncSelectedOption === POLICY
                    ? TEMPLATE
                    : RESOURCE
                ).toUpperCase(),
                to: (syncSelectedOption === POLICY
                    ? RESOURCE
                    : TEMPLATE
                ).toUpperCase(),
                tenantPolicyId: localPolicy.tenantPolicyId,
                templateId: localTemplate?.id!,
                tenantId: localPolicy.sourceTenantDto.id
            };

            updateResource(data)
                .then(() => {
                    showToast(
                        RESOURCE_SYNCED_SUCCESS.replace(
                            '%s',
                            syncSelectedOption === POLICY ? POLICY : TEMPLATE
                        ),
                        TOAST_TYPE.SUCCESS,
                        TOAST_ID
                    );
                    onActionSuccess();
                    onModalClose();
                })
                .catch(err => {
                    displayErrorMessage(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const theme = useTheme();

    const renderSelectedAction = useMemo(() => {
        let isEditorContentDiffrent = false;
        let selectedAction: string | JSX.Element = '';
        switch (actionType) {
            case AvailablePolicyActions.PROMOTE:
                modalProperties.current.title = PROMOTE_TO_TEMPLATE;
                modalProperties.current.submitBtnTitle = PROMOTE;
                selectedAction = (
                    <PromotePolicy
                        policyName={localPolicy.name}
                        selectedTags={selectedTags}
                        setSelectedTags={setSelectedTags}
                    />
                );
                setIsSubmitBtnDisabled(false);
                break;
            case AvailablePolicyActions.VIEW_DIFF:
                modalProperties.current.title = (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            columnGap: 1
                        }}
                    >
                        {TENANT_POLICY} <FaArrowsAltH size={20} /> {TEMPLATE}
                    </Box>
                );
                modalProperties.current.submitBtnTitle = SAVE_CHANGES;
                isEditorContentDiffrent =
                    JSON.stringify(localPolicy?.policyJson) !==
                        JSON.stringify(policy?.policyJson) ||
                    JSON.stringify(templateData.current?.policyJson) !==
                        JSON.stringify(localTemplate?.policyJson);
                selectedAction = (
                    <ComparePolicy
                        isEditorContentDiffrent={isEditorContentDiffrent}
                        localPolicy={localPolicy}
                        localTemplate={localTemplate}
                        policy={policy!}
                        setLocalPolicy={setLocalPolicy}
                        setLocalTemplate={setLocalTemplate}
                        setSyncSelectedOption={setSyncSelectedOption}
                        syncSelectedOption={syncSelectedOption}
                        templateData={templateData.current}
                    />
                );
                setIsSubmitBtnDisabled(!isEditorContentDiffrent);
                break;
            case AvailablePolicyActions.VIEW_JSON:
                modalProperties.current.title = localPolicy?.name!;
                modalProperties.current.submitBtnTitle = '';
                selectedAction = (
                    <Editor
                        defaultLanguage="json"
                        defaultValue={JSON.stringify(
                            localPolicy?.policyJson,
                            null,
                            2
                        )}
                        height="50vh"
                        options={{
                            readOnly: true
                        }}
                        theme={theme.palette.mode === 'dark' ? 'vs-dark' : ''}
                    />
                );
                break;
            default:
                break;
        }
        return selectedAction;
    }, [
        actionType,
        localPolicy,
        localTemplate,
        syncSelectedOption,
        selectedTags
    ]);

    return (
        <>
            {showDiffOverrideWarning && (
                <Modal
                    displayBtn="all"
                    isModalOpen={showDiffOverrideWarning}
                    onCancel={() => setShowDiffOverrideWarning(false)}
                    onSubmit={() => {
                        setShowDiffOverrideWarning(false);
                        saveModelContentChanges();
                    }}
                    submitBtnLabel={CONTINUE}
                    title={CONFIRM_ACTION}
                >
                    <WarningModalContainer
                        text={POLICY_TEMPLATE_DIFF_OVERRIDE_WARNING}
                    />
                </Modal>
            )}
            <PolicyActionModal
                className="x-wide"
                displayBtn={
                    actionType === AvailablePolicyActions.VIEW_JSON
                        ? 'cancel'
                        : 'all'
                }
                isDisabledSubmitBtn={isSubmitBtnDisabled}
                isModalOpen={modalOpen}
                onCancel={() => {
                    onModalClose();
                    setLocalPolicy(policy!);
                    setLocalTemplate(templateData.current);
                    setSelectedTags([]);
                    setSyncSelectedOption(POLICY);
                }}
                onSubmit={() => {
                    if (actionType === AvailablePolicyActions.VIEW_DIFF) {
                        setShowDiffOverrideWarning(true);
                    } else {
                        saveModelContentChanges();
                    }
                }}
                submitBtnLabel={modalProperties.current.submitBtnTitle}
                title={modalProperties.current.title as string}
            >
                {renderSelectedAction}
            </PolicyActionModal>
        </>
    );
};

import React, { useEffect, useState } from 'react';

import { Button, Select, TextBox, Upload } from '@armis/armis-ui-library';
import { InputAdornment, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AxiosResponse } from 'axios';
import { IoIosClose } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { TOAST_ID } from 'src/constants/APIConstants';
import { SAML_SP_MAPPING } from 'src/constants/APIResponse';
import { Privileges, Resources } from 'src/constants/CommonConstants';
import {
    ATTRIBUTE_BINDING,
    ATTRIBUTE_ENTITY,
    ATTRIBUTE_LOCATION,
    CHOOSE_METADATA_FORMAT,
    DONE,
    HTTP_POST_LABEL,
    METADATA_ERROR,
    METADATA_FILE,
    METADATA_URL,
    METADATA_URL_ERROR,
    QUERY_SELECTOR_CERTIFICATE,
    QUERY_SELECTOR_ENTITYDESCRIPTOR,
    QUERY_SELECTOR_SINGLESIGNONSERVICE,
    SAML_IDP_DESCRIPTION,
    SAML_IDP_DETAILS,
    SAML_SP_DESCRIPTION,
    SAML_SP_DETAILS,
    SAVE,
    SSO_AND_SAML,
    UPLOAD_FILE
} from 'src/constants/LabelText';
import {
    displayErrorMessage,
    isActionHasPermissions,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { Header } from 'src/pages/components/Header';
import { TextToClipBoard } from 'src/pages/components/TextToClipBoard/TextToClipBoard';
import {
    StyledActionsBox,
    StyledInput
} from 'src/pages/containers/SSO&SAML/SamlSettings.style';
import {
    IDPDetails,
    SamlSettingsProps,
    SPDetails
} from 'src/pages/containers/SSO&SAML/SamlSettings.types';
import {
    getSPDetails,
    getURLDetails,
    postIDPDetails
} from 'src/services/api.service';
import { selectUser } from 'src/store/slices/userSlice';

const dropDownValues = [METADATA_FILE, METADATA_URL];

const SamlSettingsComp = ({ setIsLoading }: SamlSettingsProps) => {
    const [metadataFormat, setMetadataFormat] = useState(0);
    const [metadataURL, setMetadataURL] = useState('');
    const [spDetails, setSPDetails] = useState<SPDetails | {}>({});
    const [idpDetails, setIDPDetails] = useState<IDPDetails | {}>({});
    const [disableSaveButton, setDisableSaveButton] = useState({
        0: true,
        1: true
    });
    const [filename, setFileName] = useState('');
    const currentUser = useSelector(selectUser);

    const theme = useTheme();

    const makeAnApiCall = async () => {
        try {
            setIsLoading(true, true);
            const {
                data: { url, entityid }
            } = await getSPDetails();
            setSPDetails({ url, entityid });
        } catch (err: any) {
            displayErrorMessage(err);
        } finally {
            setIsLoading(false);
        }
    };

    const parseXMLData = (xmlFileData: string) => {
        const parser = new DOMParser();
        const xml = parser.parseFromString(xmlFileData, 'text/xml');
        const certificateValue = xml.querySelector(QUERY_SELECTOR_CERTIFICATE)
            ?.childNodes[0]?.nodeValue;
        const entityID = xml
            .querySelector(QUERY_SELECTOR_ENTITYDESCRIPTOR)
            ?.getAttribute(ATTRIBUTE_ENTITY);
        const singleSignOnElements = xml.querySelectorAll(
            QUERY_SELECTOR_SINGLESIGNONSERVICE
        );
        const signOnUrlElement = Array.from(singleSignOnElements).find(
            element =>
                element
                    .getAttribute(ATTRIBUTE_BINDING)
                    ?.includes(HTTP_POST_LABEL)
        );
        const signOnUrl = signOnUrlElement?.getAttribute(ATTRIBUTE_LOCATION);

        return {
            url: signOnUrl,
            entityid: entityID,
            certificate: certificateValue
        };
    };

    const handleCapture = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = async (evt: any) => {
            setIDPDetails(parseXMLData(evt.target.result));
            setFileName(file.name);
            setDisableSaveButton({
                ...disableSaveButton,
                [metadataFormat]: false
            });
        };
    };

    const postApiData = async () => {
        let finalIdpDetails: IDPDetails;
        if (metadataFormat) {
            try {
                const res: AxiosResponse<any> = await getURLDetails(
                    metadataURL
                );
                finalIdpDetails = parseXMLData(res.data) as IDPDetails;
            } catch (err: any) {
                if (err.response) {
                    showToast(METADATA_URL_ERROR, TOAST_TYPE.ERROR, TOAST_ID);
                } else {
                    displayErrorMessage(err);
                }
                return;
            }
        } else {
            finalIdpDetails = idpDetails as IDPDetails;
        }
        const emptyCheck = !Object.values(finalIdpDetails).every(
            element => !!element
        );
        if (emptyCheck) {
            showToast(METADATA_ERROR, TOAST_TYPE.ERROR, TOAST_ID);
            return;
        }
        setIsLoading(true);
        postIDPDetails(finalIdpDetails!)
            .then(() => {
                showToast(DONE, TOAST_TYPE.SUCCESS, TOAST_ID);

                // Reload page first time only when SAML is not configured.
                if (
                    !isActionHasPermissions(currentUser, Resources.roles, [
                        Privileges.edit
                    ])
                ) {
                    window.location.reload();
                }
            })
            .catch((err: any) => {
                displayErrorMessage(err);
            })
            .finally(() => {
                if (metadataFormat) {
                    setMetadataURL('');
                } else {
                    setFileName('');
                }
                setDisableSaveButton({
                    ...disableSaveButton,
                    [metadataFormat]: true
                });
                setIsLoading(false);
            });
    };
    useEffect(() => {
        makeAnApiCall();
    }, []);

    useEffect(() => {
        setDisableSaveButton({
            ...disableSaveButton,
            [metadataFormat]: metadataURL.length === 0
        });
    }, [metadataURL]);

    return (
        <>
            <div style={{ marginBottom: '4px' }}>
                <Header title={SSO_AND_SAML} />
            </div>
            <div
                className="saml"
                style={{
                    color: theme.palette.mode === 'dark' ? 'white' : '#121212',
                    marginTop: '-30px'
                }}
            >
                <div className="saml-settings">
                    <div className="sp-details">
                        <div className="title-wrapper">
                            <div
                                className="title"
                                style={{ fontFamily: 'Proxima Nova Bld' }}
                            >
                                {SAML_SP_DETAILS}
                            </div>
                        </div>
                        <div className="description">{SAML_SP_DESCRIPTION}</div>
                        {Object.keys(spDetails as SPDetails).map(
                            (key, index) => (
                                <div
                                    key={index}
                                    className="control code-block theme-default horizontal"
                                >
                                    <span className="title">
                                        {
                                            SAML_SP_MAPPING[
                                            key as keyof typeof SAML_SP_MAPPING
                                            ]
                                        }
                                    </span>
                                    <TextToClipBoard
                                        text={
                                            spDetails[
                                            key as keyof typeof spDetails
                                            ]
                                        }
                                    />
                                </div>
                            )
                        )}
                    </div>
                    <div className="idp-details">
                        <div className="title-wrapper">
                            <div
                                className="title"
                                style={{ fontFamily: 'Proxima Nova Bld' }}
                            >
                                {SAML_IDP_DETAILS}
                            </div>
                        </div>
                        <div className="description">
                            {SAML_IDP_DESCRIPTION}
                        </div>
                        <div className="metadata-select-wrapper">
                            <span className="metadata-select-text">
                                {CHOOSE_METADATA_FORMAT}{' '}
                            </span>
                            <Select
                                MenuProps={{
                                    sx: { zIndex: 10002 }
                                }}
                                onChange={e =>
                                    setMetadataFormat(e.target.value as number)
                                }
                                value={metadataFormat}
                                variant="outlined"
                            >
                                {dropDownValues.map((element, index) => (
                                    <MenuItem
                                        key={index}
                                        style={{
                                            color:
                                                theme.palette.mode === 'dark'
                                                    ? 'white'
                                                    : ''
                                        }}
                                        value={index}
                                    >
                                        {element}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>

                        {metadataFormat === 0 ? (
                            <div
                                className="file-wrapper"
                                style={{ marginTop: '5px' }}
                            >
                                <div className="element field">
                                    <span className="text">
                                        {METADATA_FILE}
                                    </span>
                                </div>
                                <span className="file-name">{filename}</span>
                                <label
                                    className="control file-input"
                                    htmlFor="file-upload"
                                >
                                    <div className="input">
                                        <div
                                        // className="control has-inline-title svg-symbol-button transition theme-3"
                                        // style={{ width: '160px' }}
                                        >
                                            <input hidden type="file" />
                                            <Button
                                                className="control svg-symbol-button transition theme-5"
                                                color="primary"
                                                component="span"
                                                startIcon={<Upload />}
                                                variant="text"
                                            >
                                                {UPLOAD_FILE}
                                            </Button>
                                        </div>
                                    </div>
                                </label>
                                <StyledInput
                                    accept=".xml"
                                    id="file-upload"
                                    onChange={handleCapture}
                                    type="file"
                                />
                            </div>
                        ) : (
                            <div className="url-wrapper">
                                <span className="metadata-url-text">
                                    {METADATA_URL}
                                </span>
                                <TextBox
                                    autoComplete="off"
                                    className="url"
                                    fullWidth
                                    hiddenLabel
                                    InputProps={{
                                        endAdornment: metadataURL && (
                                            <InputAdornment
                                                onClick={() =>
                                                    setMetadataURL('')
                                                }
                                                position="end"
                                                variant="outlined"
                                            >
                                                <IoIosClose />
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={e => {
                                        setMetadataURL(e.target.value);
                                    }}
                                    size="small"
                                    value={metadataURL as string}
                                    variant="outlined"
                                />
                            </div>
                        )}
                    </div>
                    <StyledActionsBox>
                        <Button
                            disabled={
                                disableSaveButton[
                                metadataFormat as keyof typeof disableSaveButton
                                ]
                            }
                            onClick={postApiData}
                            variant="contained"
                        >
                            {SAVE}
                        </Button>
                    </StyledActionsBox>
                </div>
            </div>
        </>
    );
};

export const SamlSettings = IsLoadingHOC(SamlSettingsComp);

import { CHECKBOX_STATUS } from '@armis/armis-ui-library';
import { Map, FilterItems } from 'src/types/CommonTypes';

export const NO_DATA_TO_SHOW = 'No data to show';
export const DEFAULT_PAGESIZE = 50;
export const TILE_VIEW_DEFAULT_PAGESIZE = 10;
export const DEFAULT_PAGE = 0;
export const DEFAULT_PAGE_SIZE_ARRAY = [50, 100, 150, 200];
export const DEFAULT_TILE_PAGE_SIZE_ARRAY = [10, 20, 30, 40];
export const DEFAULT_QUERY_PROPERTIES = {
    searchBy: '',
    page: DEFAULT_PAGE,
    size: DEFAULT_PAGESIZE,
    directions: '',
    properties: ''
};

export const aggregatedAPIMapping: Map<string | Map<string>> = {
    tenantName: 'Tenant Name',
    device: 'Devices',
    site: 'Sites',
    alert: 'Alerts',
    activePolicy: 'Active Policies',
    Integrations: {
        activeIntegration: 'Active',
        inactiveIntegration: 'Inactive',
        draftIntegration: 'Draft',
        errorIntegration: 'Error'
    },
    Collectors: {
        activeCollector: 'Active',
        offlineCollector: 'Offline',
        initializingCollector: 'Initializing',
        pendingActivationCollector: 'Pending Activation'
    },
    tenantUrl: 'Tenant URL'
};

export const auditLogAPIMapping: Map<string | Map<string>> = {
    actiontype: 'Trigger',
    action: 'Action',
    username: 'User',
    userip: 'User IP',
    createdon: 'Time',
    info: 'Info'
};

export const tenantAPIMapping: Map<string> = {
    name: 'Name',
    totalPolicies: 'No. of Policies',
    totalRunReports: 'No. of Reports',
    lastSynced: 'Last Sync Date',
    tenantUrl: 'URL',
    actions: 'Actions'
};

export const tenantPolicyAPIMapping: Map<string> = {
    name: 'Title',
    actionType: 'Action Type',
    enabled: 'Status',
    tags: 'Tags',
    linkedTemplateStatus: 'Linked Template Status',
    policyActions: 'Actions'
};

// Report History
export const ReporthistoryAPIMapping: Map<string> = {
    title: 'Title',
    name: 'Template Used',
    tenantName: 'Tenant',
    createdby: 'Ran By',
    createddate: 'Date',
    outputFormat: 'Format',
    status: 'Status'
};

export const ReporthistoryAPIMappingByTenant: Map<string> = {
    title: 'Title',
    name: 'Template Used',
    createdby: 'Ran By',
    createddate: 'Date',
    outputFormat: 'Format',
    status: 'Status'
};

export const ReporthistoryAPIMappingByTenantByReport: Map<string> = {
    title: 'Title',
    createdby: 'Ran By',
    createddate: 'Date',
    outputFormat: 'Format',
    status: 'Status'
};

// User Management
export const userManagementAPIMapping: Map<string | Map<string>> = {
    firstname: 'Name',
    username: 'Email',
    roles: 'Roles',
    lastloggedin: 'Last Login Time'
};

export const templatePolicyAPIMapping: Map<string> = {
    name: 'Title',
    tags: 'Tags',
    tenantCount: 'Tenants',
    policyTemplateActionType: 'Action Type',
    createdby: 'Created By',
    updateddate: 'Last Modified',
    lastPushed: 'Last Pushed',
    pushJobPolicyTemplate: 'Last Push Status',
    templatePolicyActions: 'Actions'
};

export const templateLinkedTenantAPIMapping: Map<string> = {
    name: 'Tenant Name',
    tenantUrl: 'Tenant URL',
    modified: 'Template Status',
    actions: 'Action'
};

export const reportTemplateLinkedTenantAPIMapping: Map<string> = {
    name: 'Tenant Name',
    tenantUrl: 'Tenant URL',
    totalRunReports: 'No. of Reports'
};

export const templatePushSelectTenantAPIMapping: Map<string> = {
    name: 'Tenant Name',
    tenantUrl: 'Tenant URL'
};

export const templateValidatePushAPIMapping: Map<string> = {
    templateName: 'Template Name',
    tags: 'Tags',
    conflictStatus: 'Conflict Status'
};

export const commonDashboardAPIMapping: Map<string> = {
    name: 'Tenant Name',
    results: '# Of Results'
};

export const pushHistoryAPIMapping: Map<string> = {
    jobId: 'Push ID',
    createdby: 'Pushed By',
    createddate: 'Time',
    type: 'Type',
    status: 'Status'
};

export const pushHistoryDetailAPIMapping: Map<string> = {
    name: 'Tenant Name',
    status: 'Template Push Status',
    total: 'Templates'
};

export const pushHistoryTemplateDetailAPIMapping: Map<string> = {
    templateName: 'Name',
    status: 'Status'
};

export const reportTemplateAPIMapping: Map<string> = {
    name: 'Name',
    tags: 'Tags',
    tenantCount: 'Tenants',
    lastRun: 'Last Run',
    createdby: 'Created By',
    updateddate: 'Last Modified'
};

export const createRelatedObject = (
    APIMappingObject: Map<string | Map<string>>,
    sortOrder?: Map<number>,
    sortStatus?: Map<string>,
    columnFilter?: FilterItems[],
    hideColumns?: String[]
) => {
    const sortOrderAvailable = !!sortOrder;
    const sortStatusAvailable = !!sortStatus;
    Object.keys(APIMappingObject).forEach(element => {
        const keyValue = APIMappingObject[element];
        if (typeof keyValue === 'string') {
            if (sortOrderAvailable) sortOrder[element] = -1;
            if (sortStatusAvailable) sortStatus[element] = '';
            columnFilter?.push({
                id: element,
                label: keyValue,
                labelRender: <span>{keyValue}</span>,
                checkStatus: hideColumns?.includes(element)
                    ? CHECKBOX_STATUS.UNCHECKED
                    : CHECKBOX_STATUS.CHECKED
            });
        } else {
            Object.keys(keyValue).forEach(element1 => {
                if (sortOrderAvailable) sortOrder[element1] = -1;
                if (sortStatusAvailable) sortStatus[element1] = '';
                columnFilter?.push({
                    id: element1,
                    label: `${element} ${keyValue[element1]}`,
                    labelRender: (
                        <span>{`${element} ${keyValue[element1]}`}</span>
                    ),
                    checkStatus: hideColumns?.includes(element1)
                        ? CHECKBOX_STATUS.UNCHECKED
                        : CHECKBOX_STATUS.CHECKED
                });
            });
        }
    });
};

export const entityMappingUrl = {
    alerts: '/entities/alerts#search=',
    activity: '/entities/activity#search=',
    applications: '/entities/applications#search=',
    connections: '/entities/connections#search=',
    users: '/entities/users#search=',
    devices: '/inventory/devices/table#search=',
    operatingSystems: '/entities/operating-systems#search=',
    riskFactors: '/entities/risk-factors#search=',
    vulnerabilities: '/entities/vulnerabilities#search='
};

export const alertRedirectLink = '/entities/alerts#search=';

export const activeIntegrationRedirectLink =
    '/settings/integrations#search=in%3Aintegrations+statusTitle%3AActive%2C"Active%2C+Cycle+Ended"';
export const inActiveIntegrationRedirectLink =
    '/settings/integrations#search=in%3Aintegrations+statusTitle%3AInactive';
export const draftIntegrationRedirectLink =
    '/settings/integrations#search=in%3Aintegrations+statusTitle%3ADraft';
export const integrationRedirectLink =
    '/settings/integrations#search=in%3Aintegrations+statusTitle%3A"Authentication+Error"%2C"Connection+Error"%2CError%2C"Error+with+Collector"%2C"Internal+error"%2C"Unexpected+Error"';

export const collectorRedirectLink =
    '/settings/collectors#search=in%3Acollectors+statusTitle%3AOffline';
export const activeCollectorRedirectLink =
    '/settings/collectors#search=in%3Acollectors+statusTitle%3AActive';
export const pendingActivationCollectorRedirectLink =
    '/settings/collectors#search=in%3Acollectors+statusTitle%3A"Pending+activation"';
export const initializingCollectorRedirectLink =
    '/settings/collectors#search=in%3Acollectors+statusTitle%3AInitializing';
export const sitesRedirectLink = '/settings/sites';

export const policyRedirectLink = '/entities/policy';
export const deviceRedirectLink = '/inventory/devices/table#search=';

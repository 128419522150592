import { Tenant } from 'src/pages/containers/TenantManagement/TenantManagement.types';

export interface PolicyRules {
    [key: string]: (PolicyRules | string)[];
}
export interface PolicyJsonType {
    actions: {
        params: {
            severity: string;
            type: string;
        };
        type: string;
    }[];
    name: string;
    ruleType: string;
    rules: PolicyRules;
    [key: string]: any;
}

export interface Policy {
    id: string;
    name: string;
    templateName?: string;
    enabled: boolean;
    isModified: boolean;
    policyJson: PolicyJsonType;
    tags: string[];
    templatePolicyId?: string;
    sourceTenantDto: Tenant;
    tenantPolicyId: string;
    tenantPolicyActionTypes: {
        id: string;
        actionType: string;
    }[];
    isValidRules?: boolean;
    pushJobPolicyTemplate?: {
        id: string;
        pushJobPolicy: {
            id: string;
            jobId: number;
            status: string;
        };
        success: number;
        total: number;
    };
    conflictStatus?: string;
    isSelected?: boolean;
}

export interface PoliciesProps {
    tenantId: string;
    setIsLoading: (
        isComponentLoading: boolean,
        needFullPageLoading?: boolean
    ) => undefined;
}

export const AvailablePolicyActions = {
    PROMOTE: 'PROMOTE',
    VIEW_DIFF: 'VIEW_DIFF',
    VIEW_JSON: 'VIEW_JSON'
} as const;

export interface PolicyActionsProps {
    modalOpen: boolean;
    policy: Policy | undefined;
    actionType: string;
    tags: string[];
    onModalClose: () => void;
    onActionSuccess: () => void;
    setIsLoading: (
        isComponentLoading: boolean,
        needFullPageLoading?: boolean
    ) => undefined;
}

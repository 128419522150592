import React, { useEffect, useState, useMemo, useRef } from 'react';

import {
    Breadcrumbs,
    CustomHeader,
    IconButton,
    MultiSelect,
    Pagination,
    Refresh,
    StyledMenuPaper,
    Table,
    TableNoData,
    TableSkeleton,
    Tooltip
} from '@armis/armis-ui-library';
import { Menu, PaperProps, Typography } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GET_TENANTS } from 'src/constants/APIConstants';
import {
    SEARCH_TENANT_PLACEHOLDER,
    TENANT,
    TENANTS,
    REFRESH,
    REPORT_TEMPLATES
} from 'src/constants/LabelText';
import { REPORTS, TEMPLATE_ROUTE } from 'src/constants/RouteConstants';
import {
    createRelatedObject,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    NO_DATA_TO_SHOW,
    reportTemplateLinkedTenantAPIMapping
} from 'src/constants/TableConstants';
import { reportTemplateLinkedTenantConfig } from 'src/helpers/ColumnsConfig';
import { StyledLink } from 'src/helpers/Common.style';
import {
    convertQueryObjectToParams,
    displayErrorMessage,
    getDirectionsAndProperties
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { useTable } from 'src/hooks/useTable';
import { Header } from 'src/pages/components/Header';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import { TableHeader } from 'src/pages/components/TableHeader';
import { PolicyTemplateViewProps } from 'src/pages/containers/PolicyTemplate/PolicyTemplate.types';
import { Tenant } from 'src/pages/containers/TenantManagement/TenantManagement.types';
import {
    getSingleReportTemplate,
    getTenantsFromReportTemplate
} from 'src/services/api.service';
import { selectUser } from 'src/store/slices/userSlice';
import { ChildRefProp, FilterItems, Map } from 'src/types/CommonTypes';

import { Report } from './ReportTemplate.types';

const tenantSortOrder: Map<number> = {};
const tenantSortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];

createRelatedObject(
    reportTemplateLinkedTenantAPIMapping,
    tenantSortOrder,
    tenantSortStatus,
    columnsFilterItems
);

const ReportTenants = ({
    setIsLoading,
    loadingConfig
}: PolicyTemplateViewProps) => {
    const { reportTemplateId } = useParams();
    const navigate = useNavigate();
    const [report, setReport] = useState<Report>();

    const {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        filterItems,
        anchorEl,
        setModelOpen,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        onSelectionChanged,
        queryProperties,
        setQueryProperties
    } = useTable({
        sortOrderObj: tenantSortOrder,
        sortStatusObj: tenantSortStatus,
        columnsFilterItems
    });
    const [tenantList, setTenantList] = useState<Tenant[]>([]);
    const [selectedTenants, setSelectedTenants] = useState<string[]>([]);
    const [totalRows, setTotalRows] = useState<null | number>(null);
    const [shouldRefreshTenantData, setShouldRefreshTenantData] =
        useState(false);

    const paginationRef = useRef<ChildRefProp>();
    const firstRender = useRef(true);

    const currentUser = useSelector(selectUser);
    const isArmisUser = currentUser.username.includes('@armis.com');

    useEffect(() => {
        setIsLoading(true, true);

        getSingleReportTemplate(reportTemplateId!)
            .then(res => {
                setReport(res.data);
            })
            .catch(() => {
                navigate(`${TEMPLATE_ROUTE}/${REPORTS}`, { replace: true });
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [reportTemplateId, setIsLoading, navigate]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
    }, [columnSortOrder, columnSortStatus, setQueryProperties]);

    useEffect(() => {
        setTableLoading(true);

        if (!report) return;

        getTenantsFromReportTemplate(
            reportTemplateId!,
            convertQueryObjectToParams(queryProperties)
        )
            .then((res: AxiosResponse) => {
                setTenantList(res.data.content);
                setTotalRows(res.data.totalElements);
            })
            .catch(err => {
                displayErrorMessage(err);
                setTenantList([]);
                setTotalRows(0);
            })
            .finally(() => {
                setTableLoading(false);
                setSelectedTenants([]);
                if (
                    queryProperties.page === DEFAULT_PAGE &&
                    queryProperties.size === DEFAULT_PAGESIZE
                )
                    paginationRef.current?.resetPagination();
            });
    }, [
        queryProperties,
        setTableLoading,
        report,
        reportTemplateId,
        shouldRefreshTenantData
    ]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (tenantList.length === 0 && !tableLoading && !firstRender.current) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [tenantList, tableLoading, gridRef]);

    const columnConfig = useMemo(
        () => [
            ...reportTemplateLinkedTenantConfig,
            {
                field: 'totalRunReports',
                headerName: 'Reports',
                suppressMovable: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                cellRenderer: (params: ICellRendererParams) => (
                    <div>
                        <StyledLink
                            to={`${TEMPLATE_ROUTE}/${REPORTS}/${reportTemplateId}${GET_TENANTS}/${params.data?.id}`}
                        >
                            <span>{params.data?.totalRunReports}</span>
                        </StyledLink>
                    </div>
                ),
                sortable: false
            } as ColDef
        ],
        [setModelOpen]
    );

    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                columnSortOrder,
                columnSortStatus
            },
            sortable: true,
            resizable: true
        }),
        [columnSortOrder, onSortChangedCall, columnSortStatus]
    );

    const searchTenantContainer = useMemo(
        () => (
            <div>
                <SearchBar
                    onChange={newValue => {
                        setQueryProperties(prevValue => ({
                            ...prevValue,
                            searchBy: newValue,
                            page: DEFAULT_PAGE,
                            size: DEFAULT_PAGESIZE
                        }));
                    }}
                    placeholder={SEARCH_TENANT_PLACEHOLDER}
                    searchValue={queryProperties.searchBy as string}
                />
            </div>
        ),
        [queryProperties.searchBy, setQueryProperties]
    );

    return (
        <>
            <Header>
                <Breadcrumbs>
                    <Link
                        className="parent-link"
                        to={`${TEMPLATE_ROUTE}/${REPORTS}`}
                    >
                        {REPORT_TEMPLATES}
                    </Link>
                    <Typography
                        style={{ fontFamily: 'Proxima Nova Bld' }}
                        variant="h4"
                    >
                        {report?.name}
                    </Typography>
                </Breadcrumbs>
            </Header>

            <div className="control table">
                {!loadingConfig.isFullPage && (
                    <TableHeader
                        childrenLeft={searchTenantContainer}
                        childrenRight={
                            <Tooltip
                                arrow
                                placement="bottom"
                                title={
                                    <div className="tooltip-arrow-text">
                                        {REFRESH}
                                    </div>
                                }
                            >
                                <span>
                                    <IconButton
                                        className="Icon-Hover-Effect"
                                        color="primary"
                                        disabled={tableLoading}
                                        onClick={() => {
                                            setShouldRefreshTenantData(
                                                prevValue => !prevValue
                                            );
                                        }}
                                    >
                                        <Refresh />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        }
                        loading={tableLoading}
                        onColumnMenuClick={handleMenuClick}
                        selectedCount={selectedTenants.length}
                        title={`${totalRows} ${
                            totalRows === 1 ? `${TENANT}` : `${TENANTS}`
                        }`}
                    />
                )}
                <Menu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    PaperProps={
                        {
                            component: StyledMenuPaper
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                >
                    <MultiSelect
                        items={filterItems}
                        onSelectionChanged={onSelectionChanged}
                        showSelectAllOption
                    />
                </Menu>
                <Table
                    ref={gridRef}
                    columnDefs={columnConfig}
                    context={{ isArmisUser }}
                    defaultColDef={defaultColDefs}
                    loadingOverlayComponent={TableSkeleton}
                    noRowsOverlayComponent={TableNoData}
                    noRowsOverlayComponentParams={{
                        content: NO_DATA_TO_SHOW
                    }}
                    rowData={tenantList}
                    suppressNoRowsOverlay={firstRender.current}
                />
                {!!totalRows && totalRows > 0 && (
                    <Pagination
                        ref={paginationRef}
                        onPaginationChanged={(page, size) => {
                            setQueryProperties(prevValue => ({
                                ...prevValue,
                                page,
                                size
                            }));
                        }}
                        totalRowsCount={totalRows}
                    />
                )}
            </div>
        </>
    );
};

export const ReportTemplateTenant = IsLoadingHOC(ReportTenants);

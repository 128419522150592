import React, { useState } from 'react';

import { Tab, TabGroup } from '@armis/armis-ui-library';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Privileges, Resources } from 'src/constants/CommonConstants';
import { HISTORY_TEXT, POLICIES, REPORTS } from 'src/constants/LabelText';
import { isActionHasPermissions } from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { Header } from 'src/pages/components/Header';
import { selectUser } from 'src/store/slices/userSlice';

import { ReportHistory } from './ReportHistory';
import { PushHistory } from '../PushHistory/PushHistory';

const allowedTabValue = {
    POLICY: 'policy',
    REPORT: 'report'
};

let tabs: Tab[] = [
    {
        label: POLICIES,
        tabValue: allowedTabValue.POLICY
    },
    {
        label: REPORTS,
        tabValue: allowedTabValue.REPORT
    }
];

interface HistoryViewProps {
    setIsLoading: (
        isComponentLoading: boolean,
        needFullPageLoading?: boolean
    ) => undefined;
}

const HistoryViewComponent = ({ setIsLoading }: HistoryViewProps) => {
    const { hash } = useLocation();
    const navigate = useNavigate();
    const currentUser = useSelector(selectUser);

    const [selectedTab, setSelectedTab] = useState(
        hash ? hash.substring(1) : tabs[0].tabValue
    );

    if (
        !isActionHasPermissions(currentUser, Resources.reports, [
            Privileges.runreport
        ])
    ) {
        tabs = tabs.filter(tab => tab.label !== REPORTS);
    }

    if (
        !isActionHasPermissions(currentUser, Resources.policy, [
            Privileges.push
        ])
    ) {
        tabs = tabs.filter(tab => tab.label !== POLICIES);
    }

    return (
        <>
            <Header title={HISTORY_TEXT} />
            <div>
                <TabGroup
                    onChange={(_, value) => {
                        setSelectedTab(value);
                        navigate({ hash: `#${value}` });
                    }}
                    style={{ marginLeft: '65px' }}
                    tabsItems={tabs}
                    value={selectedTab}
                />
            </div>
            {selectedTab === allowedTabValue.POLICY ? (
                <div className="control table" style={{ marginTop: '10px' }}>
                    <PushHistory />
                </div>
            ) : selectedTab === allowedTabValue.REPORT ? (
                <div className="control table" style={{ marginTop: '10px' }}>
                    <ReportHistory
                        reportHistoryByTenant={false}
                        reportHistoryByTenantByReport={false}
                        reportTemplateId=""
                        setIsLoading={setIsLoading}
                        tenantId=""
                    />
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export const History = IsLoadingHOC(HistoryViewComponent);

import { constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const StyledDiv = styled('div')`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & svg {
        margin-right: 5px;
    }
    & .red-color {
        color: #e31c3d;
    }
    & .yellow-color {
        color: #ffbe2f;
    }
    & .primary-color {
        color: ${constants.COLOR_1};
    }
`;

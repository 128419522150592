import { createSlice } from '@reduxjs/toolkit';
import { allowedPushWizardTab } from 'src/pages/containers/PolicyTemplate/constants';
import { Tenant } from 'src/pages/containers/TenantManagement/TenantManagement.types';
import type { RootState } from 'src/store/store';

interface InitialStateType {
    tenantList: Tenant[];
    validateTenantList: Tenant[];
    selectedTenants: string[];
    totalRows: null | number;
    selectedTab: string;
    isPolicyTemplatesEnabled: boolean;
}

const initialState: InitialStateType = {
    tenantList: [],
    validateTenantList: [],
    selectedTenants: [],
    totalRows: null,
    isPolicyTemplatesEnabled: true,
    selectedTab: allowedPushWizardTab.SELECT_TENANT
};

export const pushWizardStepSlice = createSlice({
    name: 'pushWizard',
    initialState,
    reducers: {
        setTenants: (state, action) => {
            state.tenantList = action.payload;
        },
        setValidateTenantList: (state, action) => {
            state.validateTenantList = action.payload;
        },
        setTotalRows: (state, action) => {
            state.totalRows = action.payload;
        },
        setSelectedTenants: (state, action) => {
            state.selectedTenants = action.payload;
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload;
        },
        setIsPolicyTemplatesEnabled: (state, action) => {
            state.isPolicyTemplatesEnabled = action.payload;
        }
    }
});

export const selectTenants = (state: RootState) =>
    state.pushWizard.tenantList as Tenant[];
export const selectSelectedTenants = (state: RootState) =>
    state.pushWizard.selectedTenants as string[];
export const selectTotalRows = (state: RootState) =>
    state.pushWizard.totalRows as number;
export const selectSelectedTab = (state: RootState) =>
    state.pushWizard.selectedTab as string;
export const selectValidateTenantList = (state: RootState) =>
    state.pushWizard.validateTenantList as Tenant[];
export const selectIsPolicyTemplatesEnabled = (state: RootState) =>
    state.pushWizard.isPolicyTemplatesEnabled as boolean;

export const {
    setSelectedTenants,
    setTenants,
    setTotalRows,
    setSelectedTab,
    setValidateTenantList,
    setIsPolicyTemplatesEnabled
} = pushWizardStepSlice.actions;

export default pushWizardStepSlice.reducer;

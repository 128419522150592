import React, {
    useRef,
    useState,
    useMemo,
    useEffect,
    useTransition
} from 'react';

import {
    CHECKBOX_STATUS,
    CustomHeader,
    IconButton,
    MultiSelect,
    Pagination,
    Refresh,
    StyledMenuPaper,
    Table,
    TableNoData,
    TableSkeleton,
    Tooltip
} from '@armis/armis-ui-library';
import { Menu, PaperProps } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import { GoDownload } from 'react-icons/go';
import { useLocation, useNavigate } from 'react-router-dom';
import { TOAST_ID } from 'src/constants/APIConstants';
import {
    DOWNLOAD,
    REFRESH,
    REPORT_GENERATION_ERROR,
    RUN,
    RUNS,
    SEARCH_REPORT_PLACEHOLDER,
    SUCCESSFUL_REPORT_GENERATED
} from 'src/constants/LabelText';
import {
    CREATE_REPORT,
    REPORT_ROUTE,
    TEMPLATE_ROUTE
} from 'src/constants/RouteConstants';
import {
    createRelatedObject,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    NO_DATA_TO_SHOW,
    ReporthistoryAPIMapping,
    ReporthistoryAPIMappingByTenant,
    ReporthistoryAPIMappingByTenantByReport
    // ReporthistoryAPIMappingByReport
} from 'src/constants/TableConstants';
import {
    reportHistoryColumnsConfig,
    reportHistoryColumnsConfigByTenant,
    reportHistoryColumnsConfigByTenantByReport
} from 'src/helpers/ColumnsConfig';
// import { reportHistoryColumnsConfigByReport } from 'src/helpers/ColumnsConfig';
import { StyledLink } from 'src/helpers/Common.style';
import {
    TOAST_TYPE,
    convertQueryObjectToParams,
    displayErrorMessage,
    getDirectionsAndProperties,
    showToast
} from 'src/helpers/utility';
import { useTable } from 'src/hooks/useTable';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import { TableHeader } from 'src/pages/components/TableHeader';
// import { PolicyActions } from 'src/pages/containers/TenantView/Policies/PolicyActions/PolicyActions';
import {
    getAllReportRun,
    getRunReportUniqueValueProps,
    getRunningReport,
    getTenantReportRun,
    getTenantReportRunbyReport,
    previewReportDownload
} from 'src/services/api.service';
import { ErrorResponse, GenericResponseData } from 'src/types/APIResponseTypes';
import { ChildRefProp, FilterItems, Map } from 'src/types/CommonTypes';

import { ReportHistoryData, ReportHistoryProps } from './ReportHistory.types';

const reportHistorySortOrder: Map<number> = {};
const reportHistorySortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];
const columnFilterObj: { [key: string]: FilterItems[] } = {
    createdby: [],
    status: []
};
const queryPropertiesObj: { [key: string]: string } = {
    createdby: '',
    status: '',
    filterByStartDate: '',
    filterByEndDate: ''
};

export const ReportHistory = ({
    reportHistoryByTenant,
    reportHistoryByTenantByReport,
    reportTemplateId,
    tenantId
}: ReportHistoryProps) => {
    const navigate = useNavigate();

    const historyColConfig = reportHistoryByTenantByReport
        ? reportHistoryColumnsConfigByTenantByReport
        : reportHistoryByTenant
            ? reportHistoryColumnsConfigByTenant
            : reportHistoryColumnsConfig;
    const historyAPIMapping = reportHistoryByTenantByReport
        ? ReporthistoryAPIMappingByTenantByReport
        : reportHistoryByTenant
            ? ReporthistoryAPIMappingByTenant
            : ReporthistoryAPIMapping;

    const navigateToCreateReportPage = (id: string) => {
        getRunningReport(id).then(res => {
            navigate(`${TEMPLATE_ROUTE}/${REPORT_ROUTE}/${CREATE_REPORT}`, {
                state: { data: res.data }
            });
        });
    };

    useEffect(() => {
        reportHistoryColumnsConfig.forEach(report => {
            if (report.field === 'name') {
                report.cellRenderer = (params: ICellRendererParams) => (
                    <StyledLink
                        onClick={() =>
                            navigateToCreateReportPage(params.data.id)
                        }
                        to=""
                    >
                        {params?.data?.reportConfig?.name}
                    </StyledLink>
                );
            }
        });

        reportHistoryColumnsConfigByTenant.forEach(report => {
            if (report.field === 'name') {
                report.cellRenderer = (params: ICellRendererParams) => (
                    <StyledLink
                        onClick={() =>
                            navigateToCreateReportPage(params.data.id)
                        }
                        to=""
                    >
                        {params?.data?.reportConfig?.name}
                    </StyledLink>
                );
            }
        });
    }, []);

    useMemo(
        () =>
            createRelatedObject(
                historyAPIMapping,
                reportHistorySortOrder,
                reportHistorySortStatus,
                columnsFilterItems
            ),
        [historyAPIMapping]
    );

    const {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        filterItems,
        anchorEl,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        onSelectionChanged,
        queryProperties,
        setQueryProperties,
        onFilterChanged,
        columnFilterData,
        setColumnFilterData
    } = useTable({
        sortOrderObj: reportHistorySortOrder,
        sortStatusObj: reportHistorySortStatus,
        columnsFilterItems,
        queryPropertiesObj,
        columnFilterObj
    });

    const { state } = useLocation();

    const [, startTransition] = useTransition();

    const [intervalId, setIntervalId] =
        useState<ReturnType<typeof setInterval>>();

    const [reportHisoryList, setReportHistoryList] = useState<
        ReportHistoryData[]
    >([]);
    const [reportHistoryListFlag, setReportHistoryListFlag] =
        useState<boolean>(false);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [shouldRefresh, setShouldRefresh] = useState<{
        refresh: boolean;
        refreshFilterData: boolean;
    }>({ refresh: false, refreshFilterData: false });

    const paginationRef = useRef<ChildRefProp>();
    const firstRender = useRef(true);

    const callAPIsSynchronously = async () => {
        try {
            if (
                columnFilterData.createdby.length === 0 ||
                columnFilterData.status.length === 0
            ) {
                const columnData = await getRunReportUniqueValueProps();

                setColumnFilterData({
                    createdby: columnData.data.createdby.map((uname: any) => ({
                        id: uname,
                        label: uname,
                        labelRender: <span>{uname}</span>,
                        checkStatus: CHECKBOX_STATUS.CHECKED
                    })),
                    status: columnData.data.status.map((status: any) => ({
                        id: status,
                        label: status,
                        labelRender: (
                            <span>
                                {status.slice(0, 1) +
                                    status.slice(1).toLowerCase()}
                            </span>
                        ),
                        checkStatus: CHECKBOX_STATUS.CHECKED
                    }))
                });
            }

            const tenantsResponse: AxiosResponse<
                GenericResponseData<ReportHistoryData>
            > = reportHistoryByTenantByReport
                    ? await getTenantReportRunbyReport(
                        tenantId,
                        reportTemplateId,
                        convertQueryObjectToParams(queryProperties)
                    )
                    : reportHistoryByTenant
                        ? await getTenantReportRun(
                            tenantId,
                            convertQueryObjectToParams(queryProperties)
                        )
                        : await getAllReportRun(
                            convertQueryObjectToParams(queryProperties)
                        );

            setReportHistoryList(() => {
                startTransition(() => setReportHistoryListFlag(true));
                setReportHistoryListFlag(true);
                return tenantsResponse.data.content;
            });
            setTotalRows(tenantsResponse.data.totalElements);
        } catch (err: any) {
            displayErrorMessage(err);
            setReportHistoryList([]);
            setTotalRows(0);
        } finally {
            setTableLoading(false);
            if (
                queryProperties.page === DEFAULT_PAGE &&
                queryProperties.size === DEFAULT_PAGESIZE
            )
                paginationRef.current?.resetPagination();
        }
    };

    const intervalFunction = () => {
        let interval: ReturnType<typeof setInterval>;

        if (state?.id && reportHisoryList.length > 0 && reportHistoryListFlag) {
            interval = setInterval(() => {
                getRunningReport(state.id).then(res => {
                    if (
                        res.data.status === 'FAILED' ||
                        res.data.status === 'COMPLETED'
                    ) {
                        clearInterval(interval);

                        setReportHistoryList(reportHisoryList.map((singleRow) => {
                            if (singleRow.id === res.data.id) {
                                return { ...singleRow, status: res.data.status };
                            }
                            return singleRow;
                        }));

                        showToast(
                            res.data.status === 'FAILED'
                                ? REPORT_GENERATION_ERROR.replace(
                                    '%s',
                                    res.data.name
                                )
                                : SUCCESSFUL_REPORT_GENERATED.replace(
                                    '%s',
                                    res.data.name
                                ),
                            res.data.status === 'FAILED'
                                ? TOAST_TYPE.ERROR
                                : TOAST_TYPE.SUCCESS,
                            TOAST_ID
                        );
                    }
                });
            }, 3000);
            setIntervalId(interval);
        }
    };

    useEffect(() => {
        intervalFunction();
        return () => {
            clearInterval(intervalId);
        };
    }, [reportHistoryListFlag]);

    const columnConfig = useMemo(
        () => [
            ...historyColConfig,
            {
                field: 'reporthistoryActions',
                headerName: 'Download',
                suppressAutoSize: true,
                suppressSizeToFit: true,
                suppressMovable: true,
                pinned: 'right',
                width: 120,
                // eslint-disable-next-line react/no-unstable-nested-components
                cellRenderer: (
                    params: ICellRendererParams<ReportHistoryData>
                ) =>
                    params?.data?.status === 'FAILED' ||
                        params?.data?.status === 'GENERATING' ? (
                        <IconButton
                            className="Icon-Hover-Effect"
                            data-testid="policy-actions"
                            disabled={
                                params?.data?.status === 'FAILED' ||
                                params?.data?.status === 'GENERATING'
                            }
                            onClick={() => { }}
                            size="small"
                        >
                            <GoDownload />
                        </IconButton>
                    ) : (
                        <Tooltip
                            arrow
                            placement="bottom"
                            sx={{
                                '.MuiTooltip-tooltip': {
                                    marginTop: '4px !important'
                                }
                            }}
                            title={
                                <div className="tooltip-arrow-text">
                                    {DOWNLOAD}
                                </div>
                            }
                        >
                            <IconButton
                                className="Icon-Hover-Effect"
                                data-testid="policy-actions"
                                disabled={
                                    params?.data?.status === 'FAILED' ||
                                    params?.data?.status === 'GENERATING'
                                }
                                onClick={() => {
                                    previewReportDownload(
                                        params?.data?.id as string,
                                        params?.data?.outputFormat
                                    )
                                        .then(res => {
                                            // Create blob link to download
                                            const url =
                                                window.URL.createObjectURL(
                                                    // res.data
                                                    new Blob([res.data])
                                                );
                                            const link =
                                                document.createElement('a');
                                            link.href = url;
                                            link.setAttribute(
                                                'download',
                                                `${params?.data?.name}.${params?.data?.outputFormat}`
                                            );

                                            // Append to html link element page
                                            document.body.appendChild(link);

                                            // Start download
                                            link.click();

                                            // Clean up and remove the link
                                            link.parentNode?.removeChild(link);
                                        })
                                        .catch(
                                            (
                                                err: AxiosError<ErrorResponse>
                                            ) => {
                                                displayErrorMessage(err);
                                            }
                                        );
                                }}
                                size="small"
                            >
                                <GoDownload />
                            </IconButton>
                        </Tooltip>
                    ),
                sortable: false
            } as ColDef
        ],
        []
    );

    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                onFilterChanged: (
                    columnName: string,
                    selectedItems: FilterItems[],
                    dates?: Date[],
                    filterType = 'dropdown'
                ) => {
                    let filterDates = {};
                    if (filterType === 'date' && dates) {
                        if (dates[0] && dates[1])
                            filterDates = {
                                filterByStartDate:
                                    moment(dates[0]).unix() * 1000,
                                filterByEndDate:
                                    moment(dates[1]).endOf('D').unix() * 1000
                            };
                    }
                    onFilterChanged(
                        columnName,
                        selectedItems,
                        filterDates,
                        filterType
                    );
                    paginationRef.current?.resetPagination();
                },
                columnSortOrder,
                columnSortStatus,
                getDateFilterValue: () => ({
                    createdon: [
                        (queryProperties as any).filterByStartDate,
                        (queryProperties as any).filterByEndDate
                    ]
                })
            },
            sortable: true,
            resizable: true,
            filter: false,
            filterParams: columnFilterData
        }),
        [
            columnSortOrder,
            onSortChangedCall,
            columnFilterData,
            onFilterChanged,
            columnSortStatus
        ]
    );

    useEffect(() => {
        setTableLoading(true);
        callAPIsSynchronously();
    }, [
        queryProperties,
        tenantId,
        setTableLoading,
        setColumnFilterData,
        shouldRefresh.refresh
    ]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
    }, [columnSortOrder, columnSortStatus, setQueryProperties]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (
            reportHisoryList.length === 0 &&
            !tableLoading &&
            !firstRender.current
        ) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [reportHisoryList, tableLoading, gridRef]);

    const searchReportContainer = useMemo(
        () => (
            <div>
                <SearchBar
                    onChange={newValue =>
                        setQueryProperties(prevValue => ({
                            ...prevValue,
                            searchBy: newValue,
                            page: DEFAULT_PAGE,
                            size: DEFAULT_PAGESIZE
                        }))
                    }
                    placeholder={SEARCH_REPORT_PLACEHOLDER}
                    searchValue={queryProperties.searchBy as string}
                />
            </div>
        ),
        [queryProperties.searchBy, setQueryProperties]
    );

    return (
        <>
            <TableHeader
                childrenLeft={
                    <>
                        {searchReportContainer}
                        <Tooltip
                            arrow
                            placement="bottom"
                            sx={{
                                '& .MuiTooltip-tooltip': {
                                    minWidth: 'unset'
                                }
                            }}
                            title={
                                <div className="tooltip-arrow-text">
                                    {REFRESH}
                                </div>
                            }
                        >
                            <span>
                                <IconButton
                                    className="Icon-Hover-Effect"
                                    color="primary"
                                    disabled={tableLoading}
                                    onClick={() => {
                                        setShouldRefresh(prevValue => ({
                                            refreshFilterData: true,
                                            refresh: !prevValue.refresh
                                        }));
                                    }}
                                >
                                    <Refresh />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </>
                }
                loading={tableLoading}
                onColumnMenuClick={handleMenuClick}
                title={`${totalRows} ${totalRows === 1 ? `${RUN}` : `${RUNS}`}`}
            />
            <Menu
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                open={Boolean(anchorEl)}
                PaperProps={
                    {
                        component: StyledMenuPaper
                    } as Partial<PaperProps<'div', {}>> | undefined
                }
            >
                <MultiSelect
                    items={filterItems}
                    onSelectionChanged={onSelectionChanged}
                    showSelectAllOption
                />
            </Menu>

            <Table
                ref={gridRef}
                columnDefs={columnConfig}
                defaultColDef={defaultColDefs}
                loadingOverlayComponent={TableSkeleton}
                noRowsOverlayComponent={TableNoData}
                noRowsOverlayComponentParams={{
                    content: NO_DATA_TO_SHOW
                }}
                onFirstDataRendered={() => { }}
                rowData={reportHisoryList}
                suppressNoRowsOverlay={firstRender.current}
            />
            {!!totalRows && totalRows > 0 && (
                <Pagination
                    ref={paginationRef}
                    onPaginationChanged={(page, size) => {
                        setQueryProperties(prevValue => ({
                            ...prevValue,
                            page,
                            size
                        }));
                    }}
                    totalRowsCount={totalRows}
                />
            )}
        </>
    );
};

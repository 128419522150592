import { constants } from '@armis/armis-ui-library';
import Highcharts from 'highcharts';
import { PIE } from 'src/constants/LabelText';
import { ChartData } from 'src/pages/containers/TileView/Tile/Tile.types';

export const getChartOptions = (
    containerId: string,
    titleCount: number,
    subTitle: string,
    chartData: ChartData[]
) => ({
    chart: {
        renderTo: containerId,
        type: PIE,
        style: {
            fontFamily: constants.PROXIMANOVA_W08_REGULAR,
            color: constants.COLOR_GENERAL_3,
            fontSize: '1.4rem'
        },
        spacing: [0, 0, 0, 0],
        height: 200,
        events: {
            load(this: Highcharts.Chart) {
                setTimeout(this.reflow.bind(this), 1);
            }
        }
    },
    credits: {
        enabled: false
    },
    title: {
        text: `<span>${titleCount}</span>`,
        useHTML: true,
        style: {
            fontFamily: constants.PROXIMANOVA_W08_SEMIBOLD,
            fontSize: '1.2rem',
            color: constants.COLOR_GENERAL_3
        },
        margin: 0,
        y: 95,
        floating: true
    },
    subtitle: {
        text: subTitle,
        style: {
            fontFamily: constants.PROXIMANOVA_W08_SEMIBOLD,
            fontSize: '1.2rem',
            color: constants.COLOR_GENERAL_3
        },
        margin: 0,
        y: 112,
        floating: true
    },
    tooltip: {
        style: {
            fontSize: '1.4rem',
            color: constants.COLOR_GENERAL_3
        },
        formatter(this: Highcharts.PointLabelObject): string {
            // eslint-disable-next-line max-len
            return `<span style="font-family: ${
                constants.PROXIMANOVA_W08_SEMIBOLD
            }">${this.point.name}</span><br><span style="font-family: ${
                constants.PROXIMANOVA_W08_REGULAR
            }">${Highcharts.numberFormat(
                this.point.percentage as number,
                2
            )}%<br>${this.y} ${subTitle}</span>`;
        }
    },
    plotOptions: {
        pie: {
            shadow: false,
            fillColor: constants.COLOR_GENERAL_8,
            color: constants.COLOR_GENERAL_8,
            dataLabels: {
                enabled: false // disable data labels for the chart
            },
            borderWidth: 0,
            cursor: 'default'
        }
    },
    legend: {
        floating: true,
        symbolHeight: 10,
        symbolWidth: 10,
        reversed: true
    },
    series: [
        {
            size: '70%',
            name: subTitle,
            data: chartData,
            innerSize: '70%',
            showInLegend: true,
            dataLabels: {
                connectorShape: 'straight',
                distance: 5,
                style: {
                    fontSize: '1.2rem',
                    fontFamily: constants.PROXIMANOVA_W08_SEMIBOLD
                },
                formatter(this: Highcharts.PointLabelObject) {
                    if (this.y !== 0) {
                        return this.y;
                    }
                    return null;
                }
            }
        }
    ]
});

import {
    FORGOT_PASSWORD,
    LOGIN,
    USER,
    LOGOUT,
    VERIFY_PASSWORD,
    CHANGE_PASSWORD,
    GET_ROLE_STRUCTURE,
    GET_ROLES,
    GET_AGGREGATED_DATA,
    CREATE_ROLE,
    DELETE_ROLES,
    UPDATE_ROLE,
    GET_TENANTS,
    SYNC_TENANTS,
    SEARCH_WITHIN_TENANTS,
    GET_TENANT_POLICIES,
    GET_AUDIT_LOGS,
    EXPORT_AUDIT_LOGS,
    CONFIGURATIONS,
    GET_TAGS,
    FILTER_AUDIT_LOGS,
    GET_POLICY_TEMPLATE,
    UPDATE_POLICY_TEMPLATE,
    UPDATE_RESOURCE,
    DELETE_BULK_USER,
    USERS,
    CREATE_POLICY_TEMPLATE,
    GET_TENANTS_BY_POLICY_TEMPLATE,
    DELETE_POLICY_TEMPLATE,
    POST_PIN_DATA,
    SP_DETAILS,
    IDP_DETAILS,
    VALIDATE_PUSH,
    PUSH_POLICY,
    GET_SSOID,
    SAML2_ENDPOINT,
    SET_PASSWORD,
    GET_DASHBOARD,
    GET_RECOMMENDED_ASQ,
    GET_ALL_PUSH_HISTORY,
    GET_PUSH_HISTORY_FILTER_COLUMNS,
    SYNC_AGGREGATION,
    TABLE,
    TILE,
    ALL_REPORTS_RUN,
    TENANT_REPORTS_RUN,
    GET_REPORT_TEMPLATE,
    DELETE_BULK_REPORT_TEMPLATE,
    BOUNDARIES,
    RUN_REPORT,
    GET_TENANTS_BY_REPORT_TEMPLATE,
    UNIQUE_PROPS_VALUES,
    DUPLICATE,
    TENANT_DASHLETS,
    TENANT_DASHBOARDS,
    REPORTLETS,
    REPORTS,
    LOGO,
    PREVIEW
} from 'src/constants/APIConstants';
import { Policy } from 'src/pages/containers/TenantView/Policies/Policies.types';
import {
    LoginApiPayload,
    ForgotPasswordPayload,
    UpdateUserPayload,
    VerifyPasswordPayload,
    ChangePasswordPayload,
    CreateRolePayload,
    ExportAuditLogPayload,
    CustomPropertiesPayload,
    UpdateResourcePayload,
    CreateTemplatePayload,
    AddUserPayload,
    DeleteBulkUsers,
    DeleteUserPayload,
    DeleteTemplatePayload,
    PushValidatePayload,
    PushPoliciesPayload,
    GetSSOIdPayload,
    PartnerTenatsForm
} from 'src/types/APIPayloadTypes';
import { User, LoginResponse } from 'src/types/APIResponseTypes';

import { axiosInstance } from './axiosInstance';

export const getSSOId = (data: GetSSOIdPayload) =>
    axiosInstance.post(GET_SSOID, data);

export const loginWithSAML2 = (idpProvider: string) =>
    `${axiosInstance.defaults.baseURL}${SAML2_ENDPOINT}${idpProvider}`;

export const login = (data: LoginApiPayload) =>
    axiosInstance.post<LoginResponse>(LOGIN, data);

export const forgotPassword = (data: ForgotPasswordPayload) =>
    axiosInstance.post(FORGOT_PASSWORD, data);

export const getUser = (partnerId?: string) =>
    axiosInstance.get<User>(
        `${USER}${partnerId ? `?partner=${encodeURIComponent(partnerId)}` : ''}`
    );

export const updateUser = (data: UpdateUserPayload) =>
    axiosInstance.post(USER, data);

export const verifyPassword = (data: VerifyPasswordPayload) =>
    axiosInstance.post(VERIFY_PASSWORD, data);

export const changePassword = (data: ChangePasswordPayload) =>
    axiosInstance.post(CHANGE_PASSWORD, data);

export const logout = () => axiosInstance.get(LOGOUT);

// RBAC
export const getRoleStructure = () => axiosInstance.get(GET_ROLE_STRUCTURE);
export const getRoles = (searchValue: string) =>
    axiosInstance.get(
        `${GET_ROLES}?searchBy=${encodeURIComponent(searchValue)}`
    );
export const createRole = (data: CreateRolePayload) =>
    axiosInstance.post(CREATE_ROLE, data);
export const deleteRoles = (data: string[]) =>
    axiosInstance.post(DELETE_ROLES, data);
export const updateRole = (id: string, data: CreateRolePayload) =>
    axiosInstance.post(`${UPDATE_ROLE}/${id}`, data);

// Aggregated View
export const getAggregatedData = (params: string, catalog: boolean) => {
    const type = catalog ? TILE : TABLE;
    return axiosInstance.get(`${GET_AGGREGATED_DATA}${type}?${params}`);
};
export const postPin = (data: any) => axiosInstance.post(POST_PIN_DATA, data);
export const syncAggregatedView = () => axiosInstance.post(SYNC_AGGREGATION);
export const getAggregatedViewSyncStatus = () =>
    axiosInstance.get(SYNC_AGGREGATION);

// Boundaries
export const getBoundaries = (tenantId: string) =>
    axiosInstance.get(`${BOUNDARIES}/${tenantId}`);

// Tenant Managements
export const getTenant = (tenantId: string) =>
    axiosInstance.get(`${GET_TENANTS}/${tenantId}`);
export const getTenants = (params?: string, isPaged: boolean = true) =>
    axiosInstance.get(`${GET_TENANTS}?isPaged=${isPaged}&${params}`);
export const syncTenants = (tenantIds: string[]) =>
    axiosInstance.post(SYNC_TENANTS, tenantIds);
export const getTenantsSyncStatus = () => axiosInstance.get(`${SYNC_TENANTS}`);
export const searchWithinTenants = (searchValue: string) =>
    axiosInstance.post(
        `${SEARCH_WITHIN_TENANTS}?searchBy=${encodeURIComponent(searchValue)}`
    );
export const editPartnerTenant = (tenantID: string, data: PartnerTenatsForm) =>
    axiosInstance.patch(`${GET_TENANTS}/${tenantID}`, data);

// Tenant Policies
export const getTenantPolicies = (tenantId: string, params: string = '') =>
    axiosInstance.get(`${GET_TENANT_POLICIES}/${tenantId}?${params}`);
export const updateResource = (data: UpdateResourcePayload) =>
    axiosInstance.post(UPDATE_RESOURCE, data);

// Report History
export const getAllReportRun = (params: string = '') =>
    axiosInstance.get(`${ALL_REPORTS_RUN}?${params}`);
export const getTenantReportRun = (tenantId: string, params: string = '') =>
    axiosInstance.get(`${TENANT_REPORTS_RUN}/${tenantId}?${params}`);
export const getTenantReportRunbyReport = (
    tenantId: string,
    reportId: string,
    params: string = ''
) =>
    axiosInstance.get(
        `${ALL_REPORTS_RUN}/${reportId}${GET_TENANTS}/${tenantId}?${params}`
    );

// Audit Logs
export const getAuditLogsData = (params: string) =>
    axiosInstance.get(`${GET_AUDIT_LOGS}?${params}`);
export const exportAuditLog = (data: ExportAuditLogPayload, params: string) =>
    axiosInstance.post(`${EXPORT_AUDIT_LOGS}?${params}`, data, {
        responseType: 'blob'
    });

export const getAuditLogsFilterColumns = () =>
    axiosInstance.get(FILTER_AUDIT_LOGS);

// Custom properties
export const getThresoldData = () => axiosInstance.get(CONFIGURATIONS);
export const updateCustomPropertiesData = (data: CustomPropertiesPayload) =>
    axiosInstance.post(CONFIGURATIONS, data);

// Tags
export const getTags = (tagType: string, searchBy: string = '', isPaged: boolean = false) =>
    axiosInstance.get(
        `${GET_TAGS}?type=${tagType}&searchBy=${encodeURIComponent(
            searchBy
        )}&isPaged=${isPaged}`
    );

// Template
export const getPolicyTemplate = (id: string) =>
    axiosInstance.get(`${GET_POLICY_TEMPLATE}/${id}`);
export const getAllPolicyTemplates = (params: string) =>
    axiosInstance.get(`${GET_POLICY_TEMPLATE}?${params}`);
export const createPolicyTemplate = (data: CreateTemplatePayload) =>
    axiosInstance.post(CREATE_POLICY_TEMPLATE, data);
export const updatePolicyTemplate = (id: string, data: Policy) =>
    axiosInstance.post(`${UPDATE_POLICY_TEMPLATE}/${id}`, data);
export const deletePolicyTemplate = (data: DeleteTemplatePayload) =>
    axiosInstance.post(`${DELETE_POLICY_TEMPLATE}`, data);
export const getTenantsFromPolicyTemplate = (
    policyTemplateId: string,
    params: string
) =>
    axiosInstance.get(
        `${GET_TENANTS_BY_POLICY_TEMPLATE.replace(
            '%s',
            policyTemplateId
        )}?${params}`
    );
export const validatePush = (data: PushValidatePayload) =>
    axiosInstance.post(VALIDATE_PUSH, data);
export const pushTemplates = (data: PushPoliciesPayload) =>
    axiosInstance.post(PUSH_POLICY, data);

// User Management
export const getUserData = (params: string) =>
    axiosInstance.get(`${USERS}?${params}`);
export const addUserData = (data: AddUserPayload) =>
    axiosInstance.post(`${USERS}`, data);
export const deleteUser = (id: string, data: DeleteUserPayload) =>
    axiosInstance.delete(`${USERS}/${id}`, { data });
export const getUserDetails = (id: string) =>
    axiosInstance.get(`${USERS}/${id}`);
export const deleteBulkUsers = (data: DeleteBulkUsers) =>
    axiosInstance.post(`${DELETE_BULK_USER}`, data);
export const updateUserDetails = (id: string, data: AddUserPayload) =>
    axiosInstance.post(`${USERS}/${id}`, data);

// SSO & SAML
export const getSPDetails = () => axiosInstance.get(SP_DETAILS);
export const getURLDetails = (url: string) => axiosInstance.get(url);
export const postIDPDetails = (data: any) =>
    axiosInstance.post(IDP_DETAILS, data);

// Set Password
export const postPassword = (token: string, data: any) =>
    axiosInstance.post(`${SET_PASSWORD}${token}`, data);

// Get DashBoards
export const getDashboards = () => axiosInstance.get(GET_DASHBOARD);
export const postDashboards = (data: any) =>
    axiosInstance.post(GET_DASHBOARD, data);
export const getDashboardDataStatus = (jobId: string) =>
    axiosInstance.get(`${GET_DASHBOARD}/${jobId}`);
export const getRecommendedAsq = () => axiosInstance.get(GET_RECOMMENDED_ASQ);

// Push History
export const getAllPushHistory = (params: string) =>
    axiosInstance.get(`${GET_ALL_PUSH_HISTORY}?${params}`);
export const getDetailedPushHistory = (id: string, params?: string) =>
    axiosInstance.get(`${GET_ALL_PUSH_HISTORY}/${id}?${params}`);
export const getDetailedTemplatePushHistory = (
    pushHistoryId: string,
    tenantId: string,
    params?: string
) =>
    axiosInstance.get(
        `${GET_ALL_PUSH_HISTORY}/${pushHistoryId}${GET_TENANTS}/${tenantId}?${params}`
    );
export const getPushHistoryFilterColumns = () =>
    axiosInstance.get(GET_PUSH_HISTORY_FILTER_COLUMNS);

// Repot Template

export const getReportTemplate = (params: string) =>
    axiosInstance.get(`${GET_REPORT_TEMPLATE}?${params}`);
export const deleteReportTemplate = (data: string[]) =>
    axiosInstance.post(`${DELETE_BULK_REPORT_TEMPLATE}`, data);
export const duplicateReportTemplate = (reportId: string) =>
    axiosInstance.post(`${GET_REPORT_TEMPLATE}/${reportId}${DUPLICATE}`);
export const getTenantsFromReportTemplate = (
    reportId: string,
    params: string
) =>
    axiosInstance.get(
        `${GET_TENANTS_BY_REPORT_TEMPLATE.replace('%s', reportId)}?${params}`
    );
export const getRunReportUniqueValueProps = () =>
    axiosInstance.get(`${RUN_REPORT}/${UNIQUE_PROPS_VALUES}`);
export const runReport = (data: any) =>
    axiosInstance.post(`${RUN_REPORT}`, data);
export const getRunningReport = (reportHistoryId: string) =>
    axiosInstance.get(`${RUN_REPORT}/${reportHistoryId}`);
export const deleteSingleReportTemplate = (reportId: string) =>
    axiosInstance.delete(`${GET_REPORT_TEMPLATE}/${reportId}`);
export const getSingleReportTemplate = (reportId: string) =>
    axiosInstance.get(`${GET_REPORT_TEMPLATE}/${reportId}`);
// Dashlets
export const getDashlets = (page: number, size: number) =>
    axiosInstance.get(`${TENANT_DASHLETS}?page=${page}&size=${size}`);

export const getTenantDashlets = (
    tenantId: string,
    page: number,
    size: number,
    dashboardIds = ''
) =>
    axiosInstance.get(
        `${TENANT_DASHLETS}/${tenantId}?page=${page}&size=${size}&dashboardIds=${dashboardIds}`
    );

export const getTenantDashboards = (tenantId: string) =>
    axiosInstance.get(`${TENANT_DASHBOARDS}/${tenantId}`);

// Reportlets
export const getAllReportlets = (
    page: number,
    size: number,
    reportIds: string
) =>
    axiosInstance.get(
        `${REPORTLETS}?page=${page}&size=${size}&reportIds=${reportIds}`
    );

// Reports
export const getAllReports = () =>
    axiosInstance.get(`${REPORTS}?isPaged=false`);
export const createReport = (data: any) => axiosInstance.post(REPORTS, data);

export const getReportDetail = (reportId: string) =>
    axiosInstance.get(`${REPORTS}/${reportId}`);
export const updateReportDetails = (reportId?: string, data?: any) =>
    axiosInstance.post(`${REPORTS}/${reportId}`, data);

export const reportLogoDownload = (reportId: string) =>
    axiosInstance.get(`${REPORTS}${LOGO}/${reportId}`);

export const previewReport = (data: any) =>
    axiosInstance.post(`${RUN_REPORT}${PREVIEW}`, data);

export const previewReportDownload = (reportId: string, type?: string) =>
    axiosInstance.get(`${RUN_REPORT}/${reportId}/download?format=${type}`, {
        responseType: 'blob'
    });

import React, { useEffect, useCallback, useMemo } from 'react';

import { Button, CheckMark, Reset, Select } from '@armis/armis-ui-library';
import { DiffEditor } from '@monaco-editor/react';
import { MenuItem, useTheme } from '@mui/material';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { Privileges, Resources } from 'src/constants/CommonConstants';
import {
    APPLY,
    OVERRIDE,
    POLICY,
    RESET,
    TEMPLATE,
    TENANT_POLICY,
    UPDATE,
    WITH
} from 'src/constants/LabelText';
import {
    StyledActionContainer,
    StyledActionFooter,
    StyledSyncResource
} from 'src/pages/containers/TenantView/Policies/Policies.style';
import { Policy } from 'src/pages/containers/TenantView/Policies/Policies.types';
import { selectUser } from 'src/store/slices/userSlice';

export interface ComparePolicyProps {
    policy: Policy | null;
    localPolicy: Policy;
    setLocalPolicy: React.Dispatch<React.SetStateAction<Policy>>;
    templateData: Policy | null;
    localTemplate: Policy | null;
    setLocalTemplate: React.Dispatch<React.SetStateAction<Policy | null>>;
    syncSelectedOption: string;
    setSyncSelectedOption: React.Dispatch<React.SetStateAction<string>>;
    isEditorContentDiffrent: boolean;
}

export const ComparePolicy = ({
    policy,
    localPolicy,
    setLocalPolicy,
    templateData,
    localTemplate,
    setLocalTemplate,
    syncSelectedOption,
    setSyncSelectedOption,
    isEditorContentDiffrent
}: ComparePolicyProps) => {
    const currentUser = useSelector(selectUser);
    const findResource = currentUser.resources.find(
        r => r.name === Resources.policy
    );

    const isActionHasPermissions = useCallback(
        (hasPermissions: string[]) =>
            hasPermissions.some(permission =>
                findResource?.privilegeNames.includes(permission)
            ),
        [currentUser]
    );

    const comparePolicyActions = useMemo(
        () => [
            {
                label: TENANT_POLICY,
                value: POLICY,
                disabled: !isActionHasPermissions([Privileges.push])
            },
            {
                label: TEMPLATE,
                value: TEMPLATE,
                disabled: !isActionHasPermissions([Privileges.edit])
            }
        ],
        [isActionHasPermissions]
    );

    useEffect(() => {
        if (findResource?.privilegeNames.includes(Privileges.push)) {
            setSyncSelectedOption(POLICY);
        } else {
            setSyncSelectedOption(TEMPLATE);
        }
    }, []);

    const clearChanges = () => {
        setLocalPolicy(policy!);
        setLocalTemplate(templateData);
    };

    const removeIDFromJson = (json: any) => {
        if (!json) return '';
        const clonedJson = cloneDeep(json);
        delete clonedJson.id;
        return JSON.stringify(clonedJson, null, 2);
    };

    const theme = useTheme();

    return (
        <StyledActionContainer>
            <DiffEditor
                height="50vh"
                language="json"
                modified={removeIDFromJson(localTemplate?.policyJson)}
                options={{
                    readOnly: true
                }}
                original={removeIDFromJson(localPolicy?.policyJson)}
                theme={theme.palette.mode === 'dark' ? 'vs-dark' : ''}
            />
            <StyledActionFooter>
                <div className="left">
                    {syncSelectedOption === POLICY ? OVERRIDE : UPDATE}
                    <Select
                        defaultValue={syncSelectedOption}
                        MenuProps={{
                            sx: { zIndex: 10002 },
                            PaperProps: {
                                sx: {
                                    minWidth: '0 !important',
                                    width: '125px'
                                }
                            }
                        }}
                        onChange={e => {
                            setSyncSelectedOption(e.target.value as string);
                            clearChanges();
                        }}
                        sx={{
                            minWidth: 0,
                            width: '125px'
                        }}
                        value={syncSelectedOption}
                        variant="outlined"
                    >
                        {comparePolicyActions.map(action => (
                            <MenuItem
                                key={action.value}
                                disabled={action.disabled}
                                value={action.value}
                            >
                                {action.label}
                            </MenuItem>
                        ))}
                    </Select>
                    {`${WITH} `}
                    <StyledSyncResource>
                        {syncSelectedOption === POLICY
                            ? TEMPLATE
                            : TENANT_POLICY}
                    </StyledSyncResource>
                </div>
                <div className="right">
                    <Button
                        className="control has-inline-title svg-symbol-button transition theme-5"
                        color="primary"
                        onClick={() => {
                            if (syncSelectedOption === POLICY) {
                                setLocalPolicy(prevValue => ({
                                    ...prevValue,
                                    policyJson: localTemplate?.policyJson!
                                }));
                            } else {
                                setLocalTemplate(
                                    prevValue =>
                                        prevValue && {
                                            ...prevValue,
                                            policyJson: localPolicy.policyJson
                                        }
                                );
                            }
                        }}
                        startIcon={<CheckMark />}
                    >
                        {APPLY}
                    </Button>
                    <Button
                        className="control has-inline-title svg-symbol-button transition theme-5"
                        color="primary"
                        disabled={!isEditorContentDiffrent}
                        onClick={() => {
                            clearChanges();
                        }}
                        startIcon={<Reset />}
                    >
                        {RESET}
                    </Button>
                </div>
            </StyledActionFooter>
        </StyledActionContainer>
    );
};

export const FIELD_TYPE_MAP = {
    TEXT: 'text',
    SCHEDULER: 'scheduler',
    NUMBER: 'number',
    DROPDOWN: 'dropdown',
    PASSWORD: 'password'
};

export const Privileges = {
    read: 'read',
    create: 'create',
    edit: 'edit',
    edittenant: 'edittenant',
    delete: 'delete',
    auditLog: 'auditLog',
    saml: 'saml',
    configurationUpdate: 'configuration.update',
    push: 'manage.push',
    runreport: 'runReport'
};

export const Resources = {
    policy: 'policy',
    users: 'users',
    roles: 'roles',
    settings: 'settings',
    tenant: 'tenant',
    reports: 'report'
};

export const TagTypes = {
    policy: 'policy',
    report: 'report'
};
import React, { useEffect, useState, useRef, useMemo } from 'react';

import {
    CustomHeader,
    MultiSelect,
    Pagination,
    StyledMenuPaper,
    Table,
    TableNoData,
    TableSkeleton
} from '@armis/armis-ui-library';
import { PaperProps, Menu } from '@mui/material';
import { SelectionChangedEvent } from 'ag-grid-community';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
    SEARCH_TENANT_PLACEHOLDER,
    TENANT,
    TENANTS
} from 'src/constants/LabelText';
import {
    createRelatedObject,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    NO_DATA_TO_SHOW,
    templatePushSelectTenantAPIMapping
} from 'src/constants/TableConstants';
import { templatePushSelectTenantColumnConfig } from 'src/helpers/ColumnsConfig';
import {
    convertQueryObjectToParams,
    displayErrorMessage,
    getDirectionsAndProperties
} from 'src/helpers/utility';
import { useTable } from 'src/hooks/useTable';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import { TableHeader } from 'src/pages/components/TableHeader';
import { StyledSelectTenantStepContainer } from 'src/pages/containers/PolicyTemplate/PolicyTemplate.style';
import { PushWizardProps } from 'src/pages/containers/PolicyTemplate/PolicyTemplate.types';
import { Tenant } from 'src/pages/containers/TenantManagement/TenantManagement.types';
import { getTenants } from 'src/services/api.service';
import {
    selectSelectedTenants,
    selectTenants,
    selectTotalRows,
    setSelectedTenants,
    setTenants,
    setTotalRows
} from 'src/store/slices/pushWizardStepSlice';
import { selectUser } from 'src/store/slices/userSlice';
import { GenericResponseData } from 'src/types/APIResponseTypes';
import { ChildRefProp, FilterItems, Map } from 'src/types/CommonTypes';

const tenantSortOrder: Map<number> = {};
const tenantSortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];

createRelatedObject(
    templatePushSelectTenantAPIMapping,
    tenantSortOrder,
    tenantSortStatus,
    columnsFilterItems
);

export const SelectTenantStep = ({ hideStep }: PushWizardProps) => {
    const {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        filterItems,
        anchorEl,
        queryProperties,
        setQueryProperties,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        onSelectionChanged
    } = useTable({
        sortOrderObj: tenantSortOrder,
        sortStatusObj: tenantSortStatus,
        columnsFilterItems
    });
    const [tenantList, setTenantList] = useState<Tenant[]>([]);

    const selectedTenants = useSelector(selectSelectedTenants);
    const totalRows = useSelector(selectTotalRows);
    const tenants = useSelector(selectTenants);
    const dispatch = useDispatch();

    const paginationRef = useRef<ChildRefProp>();
    const firstRender = useRef(true);

    const currentUser = useSelector(selectUser);
    const isArmisUser = currentUser.username.includes('@armis.com');

    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                columnSortOrder,
                columnSortStatus
            },
            sortable: true,
            resizable: true
        }),
        [columnSortOrder, onSortChangedCall, columnSortStatus]
    );

    const searchTenantContainer = useMemo(
        () => (
            <div>
                <SearchBar
                    onChange={newValue => {
                        setQueryProperties(prevValue => ({
                            ...prevValue,
                            searchBy: newValue,
                            page: DEFAULT_PAGE,
                            size: DEFAULT_PAGESIZE
                        }));
                    }}
                    placeholder={SEARCH_TENANT_PLACEHOLDER}
                    searchValue={queryProperties.searchBy as string}
                />
            </div>
        ),
        [queryProperties.searchBy]
    );

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
    }, [columnSortOrder, columnSortStatus]);

    useEffect(() => {
        if (tenants.length !== 0 && tenantList.length === 0) {
            setTenantList(tenants);
            return;
        }

        setTableLoading(true);
        getTenants(convertQueryObjectToParams(queryProperties))
            .then((res: AxiosResponse<GenericResponseData<Tenant>>) => {
                setTenantList(res.data.content);
                dispatch(setTenants(res.data.content));
                dispatch(setTotalRows(res.data.totalElements));
            })
            .catch(err => {
                displayErrorMessage(err);
                setTenantList([]);
                dispatch(setTenants([]));
                dispatch(setTotalRows(0));
            })
            .finally(() => {
                setTableLoading(false);
                dispatch(setSelectedTenants([]));
                gridRef.current?.api.deselectAll();
                if (
                    queryProperties.page === DEFAULT_PAGE &&
                    queryProperties.size === DEFAULT_PAGESIZE
                )
                    paginationRef.current?.resetPagination();
            });
    }, [queryProperties, setTableLoading]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (tenantList.length === 0 && !tableLoading && !firstRender.current) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [tenantList, tableLoading, gridRef]);

    const rowSelectionChangeHandler = (
        event: SelectionChangedEvent<Tenant>
    ) => {
        dispatch(
            setSelectedTenants(
                event.api.getSelectedRows().map(tenant => tenant.id)
            )
        );
    };

    return (
        <StyledSelectTenantStepContainer
            sx={{
                display: hideStep ? 'none !important' : 'flex'
            }}
        >
            <div
                className="control table"
                style={{
                    flex: '1',
                    width: '100%',
                    padding: '12px 30px'
                }}
            >
                <TableHeader
                    childrenLeft={searchTenantContainer}
                    loading={tableLoading}
                    onColumnMenuClick={handleMenuClick}
                    selectedCount={selectedTenants.length}
                    title={`${totalRows} ${
                        totalRows === 1 ? `${TENANT}` : `${TENANTS}`
                    }`}
                />
                <Menu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    PaperProps={
                        {
                            component: StyledMenuPaper
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                    sx={{
                        zIndex: '10002 !important'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                >
                    <MultiSelect
                        items={filterItems}
                        onSelectionChanged={onSelectionChanged}
                        showSelectAllOption
                    />
                </Menu>
                <Table
                    ref={gridRef}
                    columnDefs={templatePushSelectTenantColumnConfig}
                    context={{ isArmisUser }}
                    defaultColDef={defaultColDefs}
                    loadingOverlayComponent={TableSkeleton}
                    noRowsOverlayComponent={TableNoData}
                    noRowsOverlayComponentParams={{
                        content: NO_DATA_TO_SHOW
                    }}
                    onSelectionChanged={rowSelectionChangeHandler}
                    rowData={tenantList}
                    suppressNoRowsOverlay={firstRender.current}
                />
                {!!totalRows && totalRows > 0 && (
                    <Pagination
                        ref={paginationRef}
                        onPaginationChanged={(page, size) => {
                            setQueryProperties(prevValue => ({
                                ...prevValue,
                                page,
                                size
                            }));
                        }}
                        totalRowsCount={totalRows}
                    />
                )}
            </div>
        </StyledSelectTenantStepContainer>
    );
};

import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'src/store/store';

interface InitialStateType {
    dashBoardQuery: string;
    tenants: string[];
    isDataFetchInProgress: boolean;
}

const initialState: InitialStateType = {
    dashBoardQuery: '',
    tenants: [],
    isDataFetchInProgress: false
};

export const dashBoardSlice = createSlice({
    name: 'dashBoard',
    initialState,
    reducers: {
        setDashBoardQuery: (state, action) => {
            state.dashBoardQuery = action.payload;
        },
        setDashBoardTenants: (state, action) => {
            state.tenants = action.payload;
        },
        setIsDataFetchInProgress: (state, action) => {
            state.isDataFetchInProgress = action.payload;
        }
    }
});

export const selectDashBoardQuery = (state: RootState) =>
    state.dashBoard.dashBoardQuery;
export const selectDashBoardTenants = (state: RootState) =>
    state.dashBoard.tenants;
export const selectIsDataFetchInProgress = (state: RootState) =>
    state.dashBoard.isDataFetchInProgress as boolean;

// Action creators are generated for each case reducer function
export const {
    setDashBoardQuery,
    setDashBoardTenants,
    setIsDataFetchInProgress
} = dashBoardSlice.actions;

export default dashBoardSlice.reducer;

import { constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const StyledContainer = styled('div')`
    overflow-y: auto;
    padding: 20px 50px;
    color: ${constants.COLOR_GENERAL_3}
    font-size: 16px;
    line-height: 20px;
`;
export const StyledText = styled('div')`
    font-size: 15px;
    padding-bottom: 20px;
`;
export const StyledCSVText = styled('div')`
    font-size: 14px;
`;
export const StyledLinkedCSV = styled('span')`
    font-family: ${constants.PROXIMANOVA_W08_SEMIBOLD};
    font-size: 14px;
    color: ${constants.COLOR_1};
    text-decoration: none;
    cursor: pointer;
`;
export const StyledSpan = styled('span')`
    font-family: ${constants.PROXIMANOVA_W08_SEMIBOLD};
`;
export const StyledSection = styled('div')`
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
`;
export const StyledLabel = styled('span')`
    display: inline-block;
    margin-bottom: 4px;
    font-size: 14px;
`;

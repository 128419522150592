import React, { useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { FaArrowsAltH } from 'react-icons/fa';
import { TOAST_ID } from 'src/constants/APIConstants';
import {
    CONFIRM_ACTION,
    CONTINUE,
    POLICY,
    POLICY_TEMPLATE_DIFF_OVERRIDE_WARNING,
    RESOURCE,
    RESOURCE_SYNCED_SUCCESS,
    SAVE_CHANGES,
    TEMPLATE,
    TENANT_POLICY
} from 'src/constants/LabelText';
import {
    displayErrorMessage,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import { Modal } from 'src/pages/components/Modal';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';
import { PolicyTemplateActionProps } from 'src/pages/containers/PolicyTemplate/PolicyTemplate.types';
import { PolicyActionModal } from 'src/pages/containers/TenantView/Policies/Policies.style';
import { Policy } from 'src/pages/containers/TenantView/Policies/Policies.types';
import { ComparePolicy } from 'src/pages/containers/TenantView/Policies/PolicyActions/ComparePolicy';
import { updateResource } from 'src/services/api.service';
import { UpdateResourcePayload } from 'src/types/APIPayloadTypes';

export const PolicyTemplateAction = ({
    tenant,
    template,
    modalOpen,
    onClose,
    onSuccess,
    setIsLoading
}: PolicyTemplateActionProps) => {
    const [localPolicy, setLocalPolicy] = useState<Policy>({
        ...template,
        policyJson: tenant.policyJson
    });
    const [localTemplate, setLocalTemplate] = useState<Policy | null>(template);
    const [syncSelectedOption, setSyncSelectedOption] = useState(POLICY);
    const [showDiffOverrideWarning, setShowDiffOverrideWarning] =
        useState(false);

    const policyData = useRef<Policy | null>({
        ...template,
        policyJson: tenant.policyJson
    });

    const modalTitle = useMemo<string | JSX.Element>(
        () => (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    columnGap: 1
                }}
            >
                {TENANT_POLICY} <FaArrowsAltH size={20} /> {TEMPLATE}
            </Box>
        ),
        []
    );

    const isEditorContentDiffrent =
        JSON.stringify(localPolicy?.policyJson) !==
            JSON.stringify(policyData.current?.policyJson) ||
        JSON.stringify(template.policyJson) !==
            JSON.stringify(localTemplate?.policyJson);

    const onSaveChanged = () => {
        setIsLoading(true);
        const data: UpdateResourcePayload = {
            from: (syncSelectedOption === POLICY
                ? TEMPLATE
                : RESOURCE
            ).toUpperCase(),
            to: (syncSelectedOption === POLICY
                ? RESOURCE
                : TEMPLATE
            ).toUpperCase(),
            tenantPolicyId: policyData.current?.policyJson.id,
            templateId: localTemplate?.id!,
            tenantId: tenant.id
        };

        updateResource(data)
            .then(() => {
                showToast(
                    RESOURCE_SYNCED_SUCCESS.replace(
                        '%s',
                        syncSelectedOption === POLICY ? POLICY : TEMPLATE
                    ),
                    TOAST_TYPE.SUCCESS,
                    TOAST_ID
                );
                onSuccess();
            })
            .catch(err => {
                displayErrorMessage(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            {showDiffOverrideWarning && (
                <Modal
                    displayBtn="all"
                    isModalOpen={showDiffOverrideWarning}
                    onCancel={() => setShowDiffOverrideWarning(false)}
                    onSubmit={() => {
                        setShowDiffOverrideWarning(false);
                        onSaveChanged();
                    }}
                    submitBtnLabel={CONTINUE}
                    title={CONFIRM_ACTION}
                >
                    <WarningModalContainer
                        text={POLICY_TEMPLATE_DIFF_OVERRIDE_WARNING}
                    />
                </Modal>
            )}
            <PolicyActionModal
                className="x-wide"
                isDisabledSubmitBtn={!isEditorContentDiffrent}
                isModalOpen={modalOpen}
                onCancel={onClose}
                onSubmit={() => setShowDiffOverrideWarning(true)}
                submitBtnLabel={SAVE_CHANGES}
                title={modalTitle as string}
            >
                <ComparePolicy
                    isEditorContentDiffrent={isEditorContentDiffrent}
                    localPolicy={localPolicy}
                    localTemplate={localTemplate}
                    policy={policyData.current}
                    setLocalPolicy={setLocalPolicy}
                    setLocalTemplate={setLocalTemplate}
                    setSyncSelectedOption={setSyncSelectedOption}
                    syncSelectedOption={syncSelectedOption}
                    templateData={template}
                />
            </PolicyActionModal>
        </>
    );
};

import { useEffect, useRef, useMemo, useState } from 'react';

import {
    Breadcrumbs,
    CustomHeader,
    MultiSelect,
    Pagination,
    StyledMenuPaper,
    Table,
    TableNoData,
    TableSkeleton
} from '@armis/armis-ui-library';
import { Menu, PaperProps, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import moment, { MomentInput } from 'moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    PUSH_HISTORY_TEXT,
    SEARCH_TEMPLATE_PLACEHOLDER,
    TEMPLATE,
    TEMPLATES
} from 'src/constants/LabelText';
import {
    POLICY_HISTORY,
    PUSH_HISTORY,
    TEMPLATE_ROUTE
} from 'src/constants/RouteConstants';
import {
    createRelatedObject,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    NO_DATA_TO_SHOW,
    pushHistoryTemplateDetailAPIMapping
} from 'src/constants/TableConstants';
import { pushHistoryTemplateDetailedColumnsDef } from 'src/helpers/ColumnsConfig';
import {
    convertQueryObjectToParams,
    displayErrorMessage,
    getDirectionsAndProperties
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { useTable } from 'src/hooks/useTable';
import { Header } from 'src/pages/components/Header';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import { TableHeader } from 'src/pages/components/TableHeader';
import { getDetailedTemplatePushHistory } from 'src/services/api.service';
import { ChildRefProp, FilterItems, Map } from 'src/types/CommonTypes';

import {
    PushHistoryTemplateDetailProps,
    PushHistoryTemplateDetailViewData
} from './PushHistoryTemplateDetailView.types';
import PushSummary from './PushSummary';

const pushHistoryTemplateDetailSortOrder: Map<number> = {};
const pushHistoryTemplateDetailSortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];

createRelatedObject(
    pushHistoryTemplateDetailAPIMapping,
    pushHistoryTemplateDetailSortOrder,
    pushHistoryTemplateDetailSortStatus,
    columnsFilterItems
);

const PushHistoryTemplateDetailViewComponent = ({
    setIsLoading
}: PushHistoryTemplateDetailProps) => {
    const {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        anchorEl,
        filterItems,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        onSelectionChanged,
        queryProperties,
        setQueryProperties
    } = useTable({
        sortOrderObj: pushHistoryTemplateDetailSortOrder,
        sortStatusObj: pushHistoryTemplateDetailSortStatus,
        columnsFilterItems
    });
    const navigate = useNavigate();
    const { pushHistoryId } = useParams();
    const { tenantId } = useParams();
    const [currentPolicy, setCurrentPolicy] = useState<any>('');
    const [currentTenant, setCurrentTenant] = useState<any>('');
    const [rowData, setRowData] = useState<PushHistoryTemplateDetailViewData[]>(
        []
    );
    const [totalRows, setTotalRows] = useState(0);
    const paginationRef = useRef<ChildRefProp>();
    const firstRender = useRef(true);

    const doApiCall = () => {
        getDetailedTemplatePushHistory(
            pushHistoryId!,
            tenantId!,
            convertQueryObjectToParams(queryProperties)
        )
            .then((res: AxiosResponse) => {
                setCurrentPolicy(res.data.pushJobPolicy);
                setCurrentTenant(res.data.tenant);
                setRowData(res.data.pushJobPolicyDetails.content);
                setTotalRows(res.data.pushJobPolicyDetails.totalElements);
            })
            .catch(err => {
                displayErrorMessage(err);
                setRowData([]);
                setTotalRows(0);
                navigate(`${TEMPLATE_ROUTE}/${POLICY_HISTORY}`, {
                    replace: true
                });
            })
            .finally(() => {
                setIsLoading(false);
                setTableLoading(false);
                if (
                    queryProperties.page === DEFAULT_PAGE &&
                    queryProperties.size === DEFAULT_PAGESIZE
                )
                    paginationRef.current?.resetPagination();
            });
    };

    useEffect(() => {
        setIsLoading(true, true);
        doApiCall();
    }, [pushHistoryId, tenantId, navigate, setIsLoading]);

    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                columnSortOrder,
                columnSortStatus
            },
            sortable: true,
            resizable: true
        }),
        [columnSortOrder, columnSortStatus]
    );

    useEffect(() => {
        setTableLoading(true);
        doApiCall();
    }, [pushHistoryId, tenantId, queryProperties, setTableLoading]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
    }, [columnSortStatus, columnSortOrder, setQueryProperties]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (rowData.length === 0 && !tableLoading && !firstRender.current) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [rowData, tableLoading, gridRef]);

    const searchTemplateContainer = useMemo(
        () => (
            <div>
                <SearchBar
                    onChange={newValue =>
                        setQueryProperties(prevValue => ({
                            ...prevValue,
                            searchBy: newValue,
                            page: DEFAULT_PAGE,
                            size: DEFAULT_PAGESIZE
                        }))
                    }
                    placeholder={SEARCH_TEMPLATE_PLACEHOLDER}
                    searchValue={queryProperties.searchBy as string}
                />
            </div>
        ),
        [queryProperties.searchBy]
    );

    return (
        <>
            <Header>
                <Breadcrumbs>
                    <Link
                        className="parent-link"
                        to={`${TEMPLATE_ROUTE}/${POLICY_HISTORY}`}
                    >
                        {PUSH_HISTORY_TEXT}
                    </Link>
                    <Link
                        className="parent-link"
                        to={`${TEMPLATE_ROUTE}/${PUSH_HISTORY}/${currentPolicy.id}`}
                    >
                        {currentPolicy.jobId}
                    </Link>
                    <Typography
                        style={{ fontFamily: 'Proxima Nova Bld' }}
                        variant="h4"
                    >
                        {currentTenant.name}
                    </Typography>
                </Breadcrumbs>
            </Header>
            <div className="control table">
                <PushSummary
                    id={currentPolicy.jobId}
                    pushedBy={currentPolicy.createdby}
                    status={currentPolicy.status}
                    time={moment(
                        currentPolicy.createddate as MomentInput
                    ).format('MMM D, YYYY hh:mm A')}
                />
                <TableHeader
                    childrenLeft={searchTemplateContainer}
                    loading={tableLoading}
                    onColumnMenuClick={handleMenuClick}
                    title={`${totalRows} ${
                        totalRows === 1 ? TEMPLATE : TEMPLATES
                    }`}
                />
                <Menu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    PaperProps={
                        {
                            component: StyledMenuPaper
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                >
                    <MultiSelect
                        items={filterItems}
                        onSelectionChanged={onSelectionChanged}
                        showSelectAllOption
                    />
                </Menu>
                <Table
                    ref={gridRef}
                    columnDefs={pushHistoryTemplateDetailedColumnsDef}
                    defaultColDef={defaultColDefs}
                    loadingOverlayComponent={TableSkeleton}
                    noRowsOverlayComponent={TableNoData}
                    noRowsOverlayComponentParams={{
                        content: NO_DATA_TO_SHOW
                    }}
                    rowData={rowData}
                    suppressNoRowsOverlay={firstRender.current}
                />
                {!!totalRows && totalRows > 0 && (
                    <Pagination
                        ref={paginationRef}
                        onPaginationChanged={(page, size) => {
                            setQueryProperties(prevValue => ({
                                ...prevValue,
                                page,
                                size
                            }));
                        }}
                        totalRowsCount={totalRows}
                    />
                )}
            </div>
        </>
    );
};

export const PushHistoryTemplateDetailView = IsLoadingHOC(
    PushHistoryTemplateDetailViewComponent
);

import { TextBox } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const LabelContainer = styled('div')`
    color: #363636;
    height: 36px;
    vertical-align: middle;
    display: table-cell;
    color: ${props => (props.theme.palette.mode === 'dark' ? 'white' : '')};
`;

export const StyledInput = styled(TextBox)`
    width: 50%;
    @media (max-width: 1024px) {
        width: 100%;
    }
    & .MuiInputAdornment-root {
        cursor: pointer;
        height: 100%;
    }
`;

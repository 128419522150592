import React from 'react';

import { PaperProps } from '@mui/material';

export const ALLOW_BUTTON = {
    ALL: 'all',
    SUBMIT: 'submit',
    CANCEL: 'cancel'
} as const;

export interface ModalProps {
    title: string;
    className?: string;
    isModalOpen: boolean;
    isDisabledSubmitBtn?: boolean;
    children: React.ReactNode;
    submitBtnLabel: string;
    displayBtn?: 'all' | 'submit' | 'cancel';
    dialogActionsButton?: React.ReactNode;
    paperProps?: Partial<PaperProps>;
    onCancel?: () => void;
    onSubmit: () => void;
    customHeaderComponent?: boolean;
    headerComponent?: React.ReactNode;
}

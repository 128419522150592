import { constants } from '@armis/armis-ui-library';
import { styled, Tab, Tabs } from '@mui/material';

export const StyledActions = styled('div')`
    display: flex;
    column-gap: 20px;
    margin-top: 5px;
`;

export const StyledStatusContainer = styled('div')`
    display: flex;
    align-items: center;
    column-gap: 10px;
`;

export const EditContainer = styled('div')`
    padding: 20px;
    min-height: 200px;

    .policy-template-segment {
        padding: 24px;
        max-width: 800px;

        &:not(:first-of-type) {
            border-top: 1px solid ${constants.COLOR_GENERAL_7};
        }

        .header {
            .title {
                height: 40px;
                display: flex;

                .icon-title-separator {
                    flex: 1;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    pointer-events: none;

                    svg {
                        margin-right: 5px;
                    }

                    .title-name {
                        font-family: ${constants.PROXIMANOVA_W08_SEMIBOLD};
                        font-size: 1.5rem;
                        line-height: 1.3;
                        margin-left: 5px;
                        white-space: pre-line;
                    }
                }
            }
        }

        .content {
            padding-left: 24px;

            .trigger {
                line-height: 17px;
                display: flex;
                padding-bottom: 20px;

                .label {
                    font-size: 1.6rem;
                    margin-right: 4px;

                    .text {
                        font-family: ${constants.PROXIMANOVA_W08_SEMIBOLD};
                    }
                }
            }

            .alert-action-container {
                line-height: 15px;
                cursor: pointer;
                transition: all 0.3s ease;

                &:hover {
                    background-color: ${constants.COLOR_GENERAL_7};
                    transition: all 0.3s ease;
                }

                .action-border {
                    border: 1px solid ${constants.COLOR_GENERAL_7};
                    margin-bottom: 13px;

                    .action-content {
                        margin: 0 20px 0;
                        .header {
                            display: flex;
                            justify-content: space-between;
                            padding-top: 20px;
                            padding-bottom: 20px;
                            user-select: none;

                            .compound-title {
                                display: flex;
                                font-size: 1.4rem;
                                line-height: 15px;
                                letter-spacing: 0.2px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                margin-right: 10px;

                                .highlight {
                                    margin: 0 3px;
                                    font-family: ${constants.PROXIMANOVA_W08_SEMIBOLD};
                                    color: ${constants.COLOR_1};
                                }
                            }

                            .open {
                                transform: rotate(180deg);
                            }
                        }

                        .body {
                            .alert-severity {
                                display: flex;
                                align-items: center;
                                padding: 20px;

                                .label {
                                    width: 150px;
                                    margin-bottom: 4px;
                                    font-size: 1.4rem;
                                    display: block;
                                }

                                .radio-btn-group {
                                    margin: 0 20px 0;

                                    .radio-btn {
                                        margin-right: 22px;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        position: relative;
                                        display: inline-flex;
                                        align-items: center;

                                        .btn-label {
                                            color: ${constants.COLOR_GENERAL_3};
                                            font-size: 1.3rem;
                                            &.checked,
                                            &:hover {
                                                font-weight: bold;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const ActivityRow = styled('div')`
    display: flex;

    .tree-container {
        margin-right: 4px;
        flex: 1 1 0%;
        max-height: 200px;
        overflow-y: auto;
    }

    .logic-action {
        margin-left: 4px;
        align-items: center;
        display: flex;
    }
`;

export const InfoContainer = styled('div')`
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PushWizardContainer = styled('div')`
    height: 65vh;
`;

export const StyledPushWizardContainer = styled('div')`
    height: calc(100% - 51px) !important;

    @media (min-width: 1340px) and (max-width: 1529px) {
        width: calc(100% - 45px) !important;
    }
    @media (min-width: 1530px) {
        width: calc(100% - 50px) !important;
    }

    & .ag-root-wrapper {
        border-radius: 0px 0px 8px 8px;
    }
`;

export const StyledSelectTenantStepContainer = styled('div')`
    height: calc(100% - 55px) !important;
    margin-left: -4%;
    margin-top: 20px;
    width: 73vw;
`;

export const StyledTenantSearchPanel = styled('div')`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    max-width: 20%;
`;

export const StyledTenantTabs = styled(Tabs)`
    .MuiTabs-indicator {
        display: none;
    }
    .MuiTabs-flexContainerVertical {
        height: 100%;
        overflow-y: auto;
    }
`;

export const StyledTenantTab = styled(Tab)`
    text-transform: none;
    font-size: 1.5rem;
    align-items: flex-start !important;
    padding: 0px 10px;

    &.Mui-selected {
        border-left: 4px solid ${constants.COLOR_1};
    }

    &:hover {
        background-color: ${constants.COLOR_GENERAL_7};
    }
`;

export const StyledTenantTabLabel = styled('div')`
    display: flex;
    width: 100%;
    column-gap: 5px;
    align-items: center;
    justify-content: center;

    div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        flex: 1;
        align-items: flex-start;
    }

    .alert-icon {
        path {
            stroke: ${constants.COLOR_GENERAL_13};
        }
    }
`;

import {
    PermissionsReadOnly,
    PermissionsRestricted,
    PermissionsLimited,
    PermissionsFull,
    CHECKBOX_STATUS,
    PrivilegeType
} from '@armis/armis-ui-library';

import { PrivilegeStatusType } from './RoleManagement.types';

// This privilege will be automatically selected when some other privileges are selected.
export const LOW_PRIVILEGE = 'Read';

export const getIcon = (privileges: PrivilegeType[]) => {
    let anyOneInderminate = false;
    let readOnly = false;

    const unchecked = privileges.filter((child: PrivilegeType) => {
        if (child.checkStatus === CHECKBOX_STATUS.INDETERMINATE)
            anyOneInderminate = true;
        if (
            child.label === LOW_PRIVILEGE &&
            child.checkStatus === CHECKBOX_STATUS.CHECKED
        )
            readOnly = true;
        return child.checkStatus === CHECKBOX_STATUS.UNCHECKED;
    }).length;
    if (unchecked === privileges.length) return PermissionsRestricted;
    if (unchecked === privileges.length - 1 && readOnly)
        return PermissionsReadOnly;
    if (unchecked || anyOneInderminate) return PermissionsLimited;
    return PermissionsFull;
};

export const recursivelyUpdateChildStatus = (
    privileges: PrivilegeType[],
    status?: PrivilegeStatusType,
    isDisabled?: boolean,
    level = 0
) => {
    privileges.forEach(childPrivilege => {
        if (status) childPrivilege.checkStatus = status;
        if (isDisabled !== undefined) {
            childPrivilege.isDisabled = isDisabled;
        }
        if (level === 0 && childPrivilege.label === LOW_PRIVILEGE)
            childPrivilege.isDisabled = true;
        recursivelyUpdateChildStatus(
            childPrivilege.childrens || [],
            status,
            isDisabled,
            level + 1
        );
    });
};

import { Box, styled } from '@mui/material';

export const StyledInput = styled('input')`
    display: none;
`;

export const StyledActionsBox = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

import React, { useEffect, useState, useRef, useMemo } from 'react';

import {
    CustomHeader,
    MultiSelect,
    StyledMenuPaper,
    Table,
    TableNoData,
    TableSkeleton,
    Tooltip
} from '@armis/armis-ui-library';
import { PaperProps, Menu } from '@mui/material';
import { RowNode, SelectionChangedEvent } from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import { GrAlert } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import {
    CONFLICT_ON_TENANT_PUSH_MSG,
    SEARCH_TENANTS_NAME,
    TEMPLATE,
    TEMPLATES
} from 'src/constants/LabelText';
import {
    createRelatedObject,
    NO_DATA_TO_SHOW,
    templateValidatePushAPIMapping
} from 'src/constants/TableConstants';
import { templateValidatePushColumnConfig } from 'src/helpers/ColumnsConfig';
import { useTable } from 'src/hooks/useTable';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import { TableHeader } from 'src/pages/components/TableHeader';
import {
    StyledPushWizardContainer,
    StyledTenantSearchPanel,
    StyledTenantTab,
    StyledTenantTabLabel,
    StyledTenantTabs
} from 'src/pages/containers/PolicyTemplate/PolicyTemplate.style';
import { Tenant } from 'src/pages/containers/TenantManagement/TenantManagement.types';
import { Policy } from 'src/pages/containers/TenantView/Policies/Policies.types';
import {
    selectValidateTenantList,
    setValidateTenantList
} from 'src/store/slices/pushWizardStepSlice';
import { FilterItems, Map } from 'src/types/CommonTypes';

const tenantSortOrder: Map<number> = {};
const tenantSortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];

createRelatedObject(
    templateValidatePushAPIMapping,
    tenantSortOrder,
    tenantSortStatus,
    columnsFilterItems
);

export const ValidatePushStep = () => {
    const {
        tableLoading,
        gridRef,
        filterItems,
        anchorEl,
        handleMenuClick,
        handleMenuClose,
        onSelectionChanged
    } = useTable({
        sortOrderObj: tenantSortOrder,
        sortStatusObj: tenantSortStatus,
        columnsFilterItems
    });
    const firstRender = useRef(true);
    const tenants = useSelector(selectValidateTenantList);

    const [tenantList, setTenantList] = useState<Tenant[]>(tenants);
    const [templateList, setTemplateList] = useState<Policy[]>([]);
    const [selectedTenant, setSelectedTenant] = useState(tenants[0]?.id);
    const [selectedCount, setSelectedCount] = useState(0);
    const [searchTenant, setSearchTenant] = useState('');

    const dispatch = useDispatch();

    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            sortable: false,
            resizable: true
        }),
        []
    );

    useEffect(() => {
        if (tenants.length) {
            setSelectedTenant(tenants[0].id);
            setTenantList(tenants);
        }
    }, [tenants.length]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (
            templateList.length === 0 &&
            !tableLoading &&
            !firstRender.current
        ) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [templateList, tableLoading, gridRef]);

    const rowSelectionChangeHandler = (
        event: SelectionChangedEvent<Policy>
    ) => {
        const updatedTenants = cloneDeep(tenants);
        const selectedTemplates = event.api
            .getSelectedRows()
            .map(template => template.id);

        setSelectedCount(selectedTemplates.length);

        updatedTenants
            .find(tenant => tenant.id === selectedTenant)
            ?.templates?.forEach(template =>
                selectedTemplates.includes(template.id)
                    ? (template.isSelected = true)
                    : (template.isSelected = false)
            );
        dispatch(setValidateTenantList(updatedTenants));
    };

    useEffect(() => {
        let cnt = 0;
        gridRef.current?.api?.forEachNode((node: RowNode<Policy>) => {
            if (node.data?.isSelected) {
                node.setSelected(true);
                cnt += 1;
            }
        });

        setSelectedCount(cnt);
    }, [templateList]);

    useEffect(() => {
        setTenantList(
            tenants.filter(tenant =>
                tenant.name.toLowerCase().includes(searchTenant.toLowerCase())
            )
        );
    }, [searchTenant]);

    useEffect(() => {
        setTemplateList(
            tenants.find(tenant => tenant.id === selectedTenant)?.templates ||
                []
        );
    }, [selectedTenant]);

    return (
        <StyledPushWizardContainer
            sx={{
                display: 'flex',
                columnGap: '20px',
                marginTop: '15px'
            }}
        >
            <StyledTenantSearchPanel>
                <SearchBar
                    onChange={newValue => {
                        setSearchTenant(newValue);
                    }}
                    placeholder={SEARCH_TENANTS_NAME}
                    searchValue={searchTenant}
                    sx={{
                        minWidth: 'unset',
                        fontSize: '1.4rem'
                    }}
                    variant="standard"
                />
                <StyledTenantTabs
                    aria-label="vertical tenants tabs"
                    onChange={(_, value) => {
                        setSelectedTenant(value);
                    }}
                    orientation="vertical"
                    scrollButtons={false}
                    value={selectedTenant}
                    variant="scrollable"
                >
                    {tenantList.map(tenant => (
                        <StyledTenantTab
                            key={tenant.id}
                            label={
                                <StyledTenantTabLabel>
                                    <Tooltip
                                        arrow
                                        componentsProps={{
                                            popper: {
                                                sx: {
                                                    zIndex: '10002 !important'
                                                }
                                            }
                                        }}
                                        placement="bottom"
                                        title={
                                            <div className="tooltip-arrow-text">
                                                {tenant.name}
                                            </div>
                                        }
                                    >
                                        <div> {tenant.name} </div>
                                    </Tooltip>
                                    {!tenant.valid && (
                                        <Tooltip
                                            arrow
                                            componentsProps={{
                                                popper: {
                                                    sx: {
                                                        zIndex: '10002 !important'
                                                    }
                                                }
                                            }}
                                            placement="right"
                                            title={
                                                <div className="tooltip-arrow-text">
                                                    {
                                                        CONFLICT_ON_TENANT_PUSH_MSG
                                                    }
                                                </div>
                                            }
                                        >
                                            <span>
                                                <GrAlert className="alert-icon" />
                                            </span>
                                        </Tooltip>
                                    )}
                                </StyledTenantTabLabel>
                            }
                            value={tenant.id}
                        />
                    ))}
                </StyledTenantTabs>
            </StyledTenantSearchPanel>
            <div
                className="control table"
                style={{
                    flex: '1',
                    width: '100%',
                    marginRight: '-10%',
                    padding: '16px 45px'
                }}
            >
                <TableHeader
                    loading={tableLoading}
                    onColumnMenuClick={handleMenuClick}
                    selectedCount={selectedCount}
                    title={`${templateList.length} ${
                        templateList.length === 1
                            ? `${TEMPLATE}`
                            : `${TEMPLATES}`
                    }`}
                />
                <Menu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    PaperProps={
                        {
                            component: StyledMenuPaper
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                    sx={{
                        zIndex: '10002 !important'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                >
                    <MultiSelect
                        items={filterItems}
                        onSelectionChanged={onSelectionChanged}
                        showSelectAllOption
                    />
                </Menu>
                <Table
                    ref={gridRef}
                    columnDefs={templateValidatePushColumnConfig}
                    defaultColDef={defaultColDefs}
                    isRowSelectable={(node: RowNode<Policy>) =>
                        !['ERROR', 'ALREADY_PUSHED'].includes(
                            node.data?.conflictStatus || ''
                        )
                    }
                    loadingOverlayComponent={TableSkeleton}
                    noRowsOverlayComponent={TableNoData}
                    noRowsOverlayComponentParams={{
                        content: NO_DATA_TO_SHOW
                    }}
                    onFirstDataRendered={() => {}}
                    onSelectionChanged={rowSelectionChangeHandler}
                    rowData={templateList}
                    suppressNoRowsOverlay={firstRender.current}
                />
            </div>
        </StyledPushWizardContainer>
    );
};

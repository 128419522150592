import React, { useEffect, useRef, useState } from 'react';

import { Grid } from '@mui/material';
import { COUNT_CAPS, DAYS_CAPS } from 'src/constants/LabelText';
import { RETENTION_TYPES, RETENTION_HELPER_TEXT } from 'src/helpers/utility';

import {
    LabelContainer,
    StyledInput,
    StyledDropDownInput,
    StyledMenuItem
} from './RetentionEditField.style';
import { RetentionEditFieldProps } from './RetentionEditField.types';

const RetentionTypes = [
    {
        value: RETENTION_TYPES.COUNT,
        label: COUNT_CAPS
    },
    {
        value: RETENTION_TYPES.DAYS,
        label: DAYS_CAPS
    }
];

export const RetentionEditField = ({
    id,
    disabled = false,
    type = 'text',
    label,
    value,
    retentiontype,
    onChange,
    error = false
}: RetentionEditFieldProps) => {
    const inpRef = useRef<null | HTMLInputElement>(null);
    const [selectedRetentionType, setSelectedRetentionType] =
        useState<string>(retentiontype);
    const [inputState, setInputState] = useState<string>(value);
    const [localHelperText, setLocalHelperText] = useState<string>('');

    useEffect(() => {
        setSelectedRetentionType(retentiontype);
        setInputState(value);
    }, [value, retentiontype]);

    useEffect(() => {
        if (error)
            setLocalHelperText(
                RETENTION_HELPER_TEXT[selectedRetentionType as string]
            );
        else setLocalHelperText('');
    }, [error]);

    return (
        <>
            <Grid item xs={2.5}>
                <LabelContainer>{label}</LabelContainer>
            </Grid>
            <Grid item xs={9.5}>
                <StyledInput
                    ref={inpRef}
                    autoComplete="off"
                    disabled={disabled}
                    error={error}
                    helperText={localHelperText}
                    id={id}
                    InputProps={{
                        endAdornment: (
                            <StyledDropDownInput
                                autoWidth
                                defaultValue={RETENTION_TYPES.COUNT}
                                onChange={e => {
                                    setSelectedRetentionType(
                                        e.target.value as string
                                    );
                                    onChange(
                                        e.target.value as string,
                                        inputState
                                    );
                                }}
                                value={selectedRetentionType}
                                variant="outlined"
                            >
                                {RetentionTypes.map((retention: any) => (
                                    <StyledMenuItem
                                        key={retention.value}
                                        value={retention.value}
                                    >
                                        {retention.label}
                                    </StyledMenuItem>
                                ))}
                            </StyledDropDownInput>
                        )
                    }}
                    onChange={e => {
                        setInputState(e.target.value);
                        onChange(
                            selectedRetentionType,
                            e.target.value as string
                        );
                    }}
                    onKeyPress={event => {
                        if (!event.key.match(/[0-9]/)) {
                            event.preventDefault();
                        }
                    }}
                    placeholder={label}
                    type={type}
                    value={inputState}
                    variant="outlined"
                />
            </Grid>
        </>
    );
};

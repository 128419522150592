import React, { useRef } from 'react';

import { InputAdornment, Grid } from '@mui/material';
import { IoIosClose } from 'react-icons/io';

import { LabelContainer, StyledInput } from './EditField.style';
import { EditFieldProps } from './EditField.types';

export const EditField = ({
    id,
    disabled = false,
    type = 'text',
    label,
    value,
    onChange,
    showRemove = false,
    error = false,
    helperText = ''
}: EditFieldProps) => {
    const inpRef = useRef<null | HTMLInputElement>(null);

    return (
        <>
            <Grid item xs={2.5}>
                <LabelContainer>{label}</LabelContainer>
            </Grid>
            <Grid item xs={9.5}>
                <StyledInput
                    ref={inpRef}
                    autoComplete="off"
                    disabled={disabled}
                    error={error}
                    fullWidth
                    helperText={helperText}
                    id={id}
                    InputProps={{
                        endAdornment: showRemove && value && (
                            <InputAdornment
                                onClick={() => onChange('')}
                                position="end"
                            >
                                <IoIosClose />
                            </InputAdornment>
                        )
                    }}
                    onChange={e => onChange(e.target.value)}
                    placeholder={label}
                    type={type}
                    value={value}
                    variant="outlined"
                />
            </Grid>
        </>
    );
};

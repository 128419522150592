import React from 'react';

export const Pinned = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect fill="white" height="24" width="24" />
        <path
            d="M11 13L4 20M16 4L20 8L15 17L6.99998 9L16 4Z"
            stroke="#001A72"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        />
    </svg>
);

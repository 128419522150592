import { constants } from '@armis/armis-ui-library';
import { Box, styled, Typography } from '@mui/material';

export const StyledTileView = styled('div')`
    display: grid;
    padding: 10px 20px;
    gap: 16px 16px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
`;

export const StyledTypography = styled(Typography)`
    font-family: ${constants.PROXIMANOVA_W08_THIN_REGULAR};
    padding: 10px 20px;
    color: ${props => (props.theme.palette.mode === 'dark' ? 'white' : '')};
`;

export const StyledTilesContainer = styled(Box)`
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    flex: 1 1 0;
    background: ${props =>
        props.theme.palette.mode === 'dark' ? '#251E33' : '#ffffff'};
    border: 1px solid #e3e3e8;
    border-top: none;
    border: ${props =>
        props.theme.palette.mode === 'dark'
            ? '1px solid #5C5C5C'
            : '1px solid #e3e3e8'};

    & .highcharts-background {
        fill: ${props =>
            props.theme.palette.mode === 'dark' ? '#251e33' : ''};
    }

    & .highcharts-subtitle {
        fill: ${props =>
            props.theme.palette.mode === 'dark' ? 'white' : ''} !important;
    }

    & .highcharts-title {
        color: ${props =>
            props.theme.palette.mode === 'dark' ? 'white' : ''} !important;
    }

    & .highcharts-pie-series {
        > text {
            fill: ${props =>
                props.theme.palette.mode === 'dark' ? 'white' : ''} !important;
        }
    }

    /* Safari */
    :-webkit-full-screen {
        background-color: ${props =>
            props.theme.palette.mode === 'dark' ? '#15111D' : '#FAFAFC'};
    }

    /* IE11 */
    :-ms-fullscreen {
        background-color: ${props =>
            props.theme.palette.mode === 'dark' ? '#15111D' : '#FAFAFC'};
    }

    /* Standard syntax */
    :fullscreen {
        background-color: ${props =>
            props.theme.palette.mode === 'dark' ? '#15111D' : '#FAFAFC'};
    }
`;

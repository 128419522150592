import React, { useEffect, useState } from 'react';

import { Loader } from '@armis/armis-ui-library';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Editor } from '@monaco-editor/react';
import { Box, useTheme } from '@mui/material';
import { FIELD_TYPE_MAP } from 'src/constants/CommonConstants';
import { DOWNLOAD_PREVIEW } from 'src/constants/LabelText';
import { previewReportDownload } from 'src/services/api.service';

import { StyledModal } from './ReportPreview.style';
import { ReportPreviewActionProps } from './ReportPreview.types';
import { ModalField } from '../ModalField/ModalField';

const exportTypes = ['PDF', 'DOC', 'PPT', 'JSON'];

export const ReportPreview = ({
    previewfiles,
    onClickCancel,
    onSubmitSuccess,
    isModalLoading,
    previewFileType,
    setIsModalLoading
}: ReportPreviewActionProps) => {
    const [exportType, setexportType] = useState(
        exportTypes.indexOf(previewFileType as string) || 0
    );
    const [selectedReportJSON, setSelectedReportJSON] = useState<JSON>();
    const [isJSONLoaded, setIsJSONLoaded] = useState(false);

    const theme = useTheme();

    const getJSONdata = async (currentIndex: number) => {
        const response = await previewReportDownload(
            previewfiles[currentIndex].previewReportId,
            previewfiles[currentIndex].fileType.toLocaleUpperCase()
        );
        const jsonData = await response.data.text();
        setSelectedReportJSON(JSON.parse(jsonData));
        setIsJSONLoaded(true);
    };

    const onChangeFileType = (e: any) => {
        setIsModalLoading(true);
        // Specifically for JSON type we need to handle the JSON Viewer
        if (e === 3) {
            getJSONdata(e);
        } else {
            setIsJSONLoaded(false);
        }
        setexportType(e);
        setIsModalLoading(false);
    };

    useEffect(() => {
        if (
            exportType === 3 &&
            previewfiles.length > 0 &&
            previewfiles[3].previewReportId
        ) {
            setIsModalLoading(true);
            getJSONdata(3);
            setIsModalLoading(false);
        }
    }, [previewfiles]);

    const onClickSubmitHandler = () => {
        onSubmitSuccess(exportTypes[exportType]);
    };

    return (
        <StyledModal
            customHeaderComponent
            displayBtn="all"
            headerComponent={
                <header className="header">
                    <span className="title"> Report Viewer </span>
                    <span className="spacer right" />
                    <ModalField
                        key={2}
                        disable={isModalLoading}
                        error={false}
                        helperText=""
                        index={1}
                        items={exportTypes}
                        labelName=""
                        onChange={onChangeFileType}
                        type={FIELD_TYPE_MAP.DROPDOWN}
                        value={exportType}
                    />
                </header>
            }
            isModalOpen
            onCancel={onClickCancel}
            onSubmit={onClickSubmitHandler}
            paperProps={{
                style: {
                    // minHeight: '88%',
                    // maxHeight: '88%',
                    minWidth: '50%'
                }
            }}
            submitBtnLabel={DOWNLOAD_PREVIEW}
            title="Report Preview"
        >
            {isModalLoading && (
                <Box
                    sx={{
                        display: 'flex',
                        padding: '20px',
                        justifyContent: 'center',
                        boxSizing: 'content-box',
                        marginTop: '23%'
                    }}
                >
                    <Loader
                        fade={false}
                        loaderType={{ name: 'spinner', size: 100 }}
                        // @ts-ignore
                        show
                    >
                        <div className="circle" data-testid="loading-circle" />
                    </Loader>
                </Box>
            )}

            {previewfiles?.length > 0 &&
                !isModalLoading &&
                (exportType === 3 ? (
                    isJSONLoaded && (
                        <Editor
                            defaultLanguage="json"
                            defaultValue={JSON.stringify(
                                selectedReportJSON,
                                null,
                                2
                            )}
                            height="70vh"
                            options={{
                                readOnly: true
                            }}
                            theme={
                                theme.palette.mode === 'dark' ? 'vs-dark' : ''
                            }
                        />
                    )
                ) : (
                    <DocViewer
                        key={exportType}
                        activeDocument={previewfiles[exportType]}
                        documents={previewfiles}
                        pluginRenderers={DocViewerRenderers}
                        style={{
                            height: '70vh'
                        }}
                    />
                ))}
        </StyledModal>
    );
};

import React, {
    RefObject,
    createRef,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';

import 'gridstack/dist/gridstack.css';
import 'gridstack/dist/gridstack.min.css';
import 'gridstack/dist/gridstack-extra.min.css';
import { Box } from '@mui/material';
import { AxiosError } from 'axios';
import { GridStack, GridStackWidget } from 'gridstack';
import { debounce } from 'lodash';
import { EXPAND_TILE_VIEW } from 'src/constants/LabelText';
import { displayErrorMessage } from 'src/helpers/utility';
import { postPin } from 'src/services/api.service';
import { ErrorResponse } from 'src/types/APIResponseTypes';
import { Map } from 'src/types/CommonTypes';

import { PinnedTilesProps } from './PinnedTiles.types';

export const TILE_GRID_MIN_WIDTH_WITH_GAP = 416;
export const TILE_GRID_GAP = 16;
export const UNPINNED_SECTION_PADDING = 40;

export const PinnedTiles = ({ rowData, getTileComp }: PinnedTilesProps) => {
    const refs = useRef<Map<RefObject<HTMLElement>>>({});
    const gridRef = useRef<GridStack>();
    const getNumberOfColumns = () => {
        const widthOfEle =
            document.getElementById(EXPAND_TILE_VIEW)!.clientWidth;
        const actualWidth =
            widthOfEle - UNPINNED_SECTION_PADDING + TILE_GRID_GAP;
        const numberOfColumns = Math.floor(
            actualWidth / TILE_GRID_MIN_WIDTH_WITH_GAP
        );
        return numberOfColumns;
    };
    const [columnGrid, setColumnGrid] = useState(() => getNumberOfColumns());
    if (Object.keys(refs.current).length !== rowData?.pinned?.length) {
        rowData?.pinned?.forEach((element, index) => {
            refs.current[index] = refs.current[index] || createRef();
        });
    }
    const debouncedPinOrderChange = useMemo(
        () =>
            debounce((newItems: GridStackWidget[]) => {
                const postPinData = newItems.reduce(
                    (newArray: any, item, index) => {
                        newArray.push({
                            id: item.id,
                            pinOrder: index + 1
                        });
                        return newArray;
                    },
                    []
                );
                postPin(postPinData)
                    .then(() => {})
                    .catch((err: AxiosError<ErrorResponse>) => {
                        displayErrorMessage(err);
                    });
            }, 1000),
        []
    );

    const resizeFunction = () => {
        const numberOfColumns = getNumberOfColumns();
        const gridElement = document.getElementsByClassName('grid-stack');
        gridElement[0].classList.remove(`grid-stack-${columnGrid}`);
        gridElement[0].classList.add(`grid-stack-${numberOfColumns}`);
        setColumnGrid(numberOfColumns);
    };

    useEffect(() => {
        window.addEventListener('resize', resizeFunction);
        return () => {
            window.removeEventListener('resize', resizeFunction);
        };
    }, [columnGrid]);

    useEffect(() => {
        gridRef.current?.destroy(false);
        gridRef.current = GridStack.init(
            {
                cellHeight: columnGrid >= 14 ? '327.8' : '319.8',
                cellHeightUnit: 'px',
                disableResize: true,
                row: Math.ceil((rowData?.pinned?.length ?? 0) / columnGrid),
                margin: '8 8 8 8',
                marginUnit: 'px',
                column: columnGrid
            },
            '.controlled'
        )?.on('change', () => {
            if (gridRef.current?.engine) {
                const newItems = gridRef.current?.save(false);
                debouncedPinOrderChange(newItems as GridStackWidget[]);
            }
        });
        const grid = gridRef.current;
        grid?.batchUpdate();
        grid?.removeAll();
        rowData?.pinned?.forEach((element, index: any) =>
            grid?.addWidget(refs.current[index].current!, {
                x: index % columnGrid,
                y: Math.floor(index / columnGrid)
            })
        );
        grid?.batchUpdate(false);
    }, [rowData?.pinned, debouncedPinOrderChange, columnGrid]);

    return (
        <div
            style={{
                width: '100%',
                padding: '2px 12px',
                cursor: 'move'
            }}
        >
            <Box className="grid-stack controlled">
                {rowData?.pinned?.map((element, index) => (
                    <Box
                        key={element.tenant.id + index}
                        ref={refs.current[index]}
                        className="grid-stack-item"
                        gs-id={element.tenant.id}
                    >
                        <Box
                            className="grid-stack-item-content"
                            sx={{
                                height: 'max-content'
                            }}
                        >
                            {getTileComp(element, index)}
                        </Box>
                    </Box>
                ))}
            </Box>
        </div>
    );
};

import { combineReducers, configureStore, Store } from '@reduxjs/toolkit';
import { LOGOUT_REQUEST } from 'src/constants/LabelText';

import {
    roleReducer,
    errorReducer,
    userReducer,
    customPropertiesReducer,
    pushWizardStepSlice,
    dashBoardReducer,
    themeReducer
} from './slices';

const appReducer = combineReducers({
    user: userReducer,
    error: errorReducer,
    role: roleReducer,
    customProperties: customPropertiesReducer,
    pushWizard: pushWizardStepSlice,
    dashBoard: dashBoardReducer,
    theme: themeReducer,
});

const rootReducer = (state: RootState, action: any) => {
    if (action.type === LOGOUT_REQUEST) {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

export const createStore = (props?: any) =>
    configureStore({
        reducer: rootReducer,
        ...props
    });

export const store: Store = createStore();

export type RootState = ReturnType<typeof store.getState>;

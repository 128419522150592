import React, { useState, useEffect, useRef } from 'react';

import { Button, TextBox } from '@armis/armis-ui-library';
import { InputAdornment } from '@mui/material';
import { cloneDeep } from 'lodash';
import { IoIosClose } from 'react-icons/io';
import { allowedRulesOperator } from 'src/pages/containers/PolicyTemplate/constants';
import {
    AllowedRulesOperator,
    LogicTreeProps,
    PolicyRuleInputType,
    PolicyRulesStateType
} from 'src/pages/containers/PolicyTemplate/PolicyTemplate.types';

const LogicTree = ({
    conditionsInputRules,
    onChangeRuleGroup,
    level,
    inputPlaceholder
}: LogicTreeProps) => {
    const operator = Object.keys(conditionsInputRules)[0];
    const firstRender = useRef(true);

    const [currentRuleGroup, setCurrentRuleGroup] = useState(() => ({
        operator,
        inputList: conditionsInputRules[operator] ?? []
    }));
    const [inputListLength, setInputListLength] = useState(
        currentRuleGroup.inputList.length
    );

    useEffect(() => {
        setCurrentRuleGroup(prevValue => ({
            ...prevValue,
            inputList: conditionsInputRules[prevValue.operator]
        }));
    }, [inputListLength]);

    useEffect(() => {
        const op = Object.keys(conditionsInputRules)[0];
        setInputListLength(conditionsInputRules[op].length);
    }, [conditionsInputRules]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        onChangeRuleGroup({
            [currentRuleGroup.operator]: currentRuleGroup.inputList
        });
    }, [currentRuleGroup]);

    const onChangeChildRuleGroup = (
        childRuleGroup: PolicyRulesStateType,
        childIndex: number
    ) => {
        const upCurrentRuleGroup = cloneDeep(currentRuleGroup);
        const childOp = Object.keys(childRuleGroup)[0];

        // Remove the group if it has empty rule list.
        if (childRuleGroup[childOp].length === 0) {
            upCurrentRuleGroup.inputList = upCurrentRuleGroup.inputList.filter(
                (_, index) => index !== childIndex
            );
        } else {
            upCurrentRuleGroup.inputList[childIndex] = childRuleGroup;
        }
        setCurrentRuleGroup(upCurrentRuleGroup);
    };

    return (
        <div className="group">
            <span className="lines" />
            <div className="draggable">
                {(currentRuleGroup.inputList.length > 1 ||
                    (currentRuleGroup.inputList.length === 1 &&
                        !currentRuleGroup.inputList[0].key)) && (
                    <Button
                        className={`operator ${currentRuleGroup.operator}`}
                        color="secondary"
                        onClick={() =>
                            setCurrentRuleGroup(prevValue => ({
                                ...prevValue,
                                operator:
                                    prevValue.operator === 'and' ? 'or' : 'and'
                            }))
                        }
                        variant="contained"
                    >
                        {
                            allowedRulesOperator[
                                currentRuleGroup.operator as AllowedRulesOperator
                            ]
                        }
                    </Button>
                )}
                <div className="nodes">
                    {currentRuleGroup.inputList.map((input, index, list) =>
                        input.key ? (
                            <div
                                key={input.key as string}
                                className={list.length > 1 ? 'node' : ''}
                            >
                                <span className="lines" />
                                <TextBox
                                    key={input.key as string}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: input.value && (
                                            <InputAdornment
                                                className="clear"
                                                onClick={() => {
                                                    if (
                                                        currentRuleGroup
                                                            .inputList.length >
                                                            1 ||
                                                        (currentRuleGroup
                                                            .inputList
                                                            .length === 1 &&
                                                            level !== 0)
                                                    ) {
                                                        // Remove entire textbox.
                                                        setCurrentRuleGroup(
                                                            prevValue => ({
                                                                ...prevValue,
                                                                inputList:
                                                                    prevValue.inputList.filter(
                                                                        pInput =>
                                                                            pInput.key !==
                                                                            input.key
                                                                    )
                                                            })
                                                        );
                                                    } else {
                                                        // Reset the input value.
                                                        setCurrentRuleGroup(
                                                            prevValue => ({
                                                                ...prevValue,
                                                                inputList: (
                                                                    prevValue.inputList as PolicyRuleInputType[]
                                                                ).map(pInput =>
                                                                    pInput.key ===
                                                                    input.key
                                                                        ? {
                                                                              ...pInput,
                                                                              value: ''
                                                                          }
                                                                        : pInput
                                                                )
                                                            })
                                                        );
                                                    }
                                                }}
                                                position="end"
                                                variant="outlined"
                                            >
                                                <IoIosClose />
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={e =>
                                        setCurrentRuleGroup(prevValue => ({
                                            ...prevValue,
                                            inputList: (
                                                prevValue.inputList as PolicyRuleInputType[]
                                            ).map(pInput =>
                                                pInput.key === input.key
                                                    ? {
                                                          ...pInput,
                                                          value: e.target.value
                                                      }
                                                    : pInput
                                            )
                                        }))
                                    }
                                    placeholder={inputPlaceholder}
                                    value={input.value as string}
                                />
                            </div>
                        ) : (
                            <LogicTree
                                key={`${level}_${index}`}
                                conditionsInputRules={
                                    input as unknown as PolicyRulesStateType
                                }
                                inputPlaceholder={inputPlaceholder}
                                level={level + 1}
                                onChangeRuleGroup={childRuleGroup =>
                                    onChangeChildRuleGroup(
                                        childRuleGroup,
                                        index
                                    )
                                }
                            />
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default LogicTree;

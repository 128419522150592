import React from 'react';

import { IconError, IconWarning, NewTab } from '@armis/armis-ui-library';
import {
    CLOSE,
    DELETE,
    DELETE_ROLE,
    DELETE_ROLES_MESSAGE,
    DELETE_ROLE_MESSAGE,
    DELETE_ROLE_NOT_ALLOWED,
    DELETE_ROLES,
    DELETE_ROLES_NOT_ALLOWED,
    USER_MANAGEMENT_TAB
} from 'src/constants/LabelText';
import {
    SETTINGS_ROUTE,
    USER_MANAGEMENT_ROUTE
} from 'src/constants/RouteConstants';
import { Modal } from 'src/pages/components/Modal';
import { StyledDiv } from 'src/pages/containers/RoleManagement/RoleManagement.style';

import { DeleteRoleModalProps } from './DeleteRoleModal.types';

const DeleteRoleModal = ({
    showDeleteWarningModal,
    selectedRoleName,
    selectedRoleLength,
    allowDelete,
    onClickCancel,
    onClickSubmit
}: DeleteRoleModalProps) => {
    const deleteRoleModalTitle = () =>
        selectedRoleName
            ? `${DELETE_ROLE} - "${selectedRoleName}"`
            : DELETE_ROLES;

    const deleteNotAllowed = (message: string) => (
        <StyledDiv>
            <div className="control svg-symbol-button transition theme- icon">
                <IconError />
            </div>
            <div className="text">
                {message}
                <a
                    className="control external-link"
                    href={`${window.origin}${SETTINGS_ROUTE}/${USER_MANAGEMENT_ROUTE}`}
                    rel="noreferrer"
                    target="_blank"
                >
                    <NewTab />
                    {USER_MANAGEMENT_TAB}
                </a>
            </div>
        </StyledDiv>
    );

    const deleteAllowed = (message: string) => (
        <StyledDiv>
            <div className="control svg-symbol-button transition theme- icon">
                <IconWarning />
            </div>
            <div className="text">{message}</div>
        </StyledDiv>
    );

    return (
        <Modal
            displayBtn={allowDelete ? 'all' : 'submit'}
            isModalOpen={showDeleteWarningModal}
            onCancel={onClickCancel}
            onSubmit={onClickSubmit}
            submitBtnLabel={allowDelete ? DELETE : CLOSE}
            title={deleteRoleModalTitle()}
        >
            {selectedRoleName
                ? allowDelete
                    ? deleteAllowed(
                          DELETE_ROLE_MESSAGE.replace('%s', selectedRoleName)
                      )
                    : deleteNotAllowed(DELETE_ROLE_NOT_ALLOWED)
                : allowDelete
                ? deleteAllowed(
                      DELETE_ROLES_MESSAGE.replace(
                          '%s',
                          selectedRoleLength.toString()
                      )
                  )
                : deleteNotAllowed(DELETE_ROLES_NOT_ALLOWED)}
        </Modal>
    );
};

export default DeleteRoleModal;

import React from 'react';

export const UnPinned = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g opacity="0.3">
            <rect fill="white" height="24" width="24" />
            <path
                d="M11 13L4 20M16 4.00001L20 8.00001L15 17L6.99998 9.00001L16 4.00001Z"
                stroke="#001A72"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </g>
    </svg>
);

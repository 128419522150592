import React from 'react';

import { Copy } from '@armis/armis-ui-library';
import { useTheme } from '@mui/material/styles';
import { COPY } from 'src/constants/LabelText';

interface TextToClipBoardProps {
    text: string;
}

export const TextToClipBoard = ({ text }: TextToClipBoardProps) => {
    const theme = useTheme();

    return (
        <pre
            style={{
                border: theme.palette.mode === 'dark' ? '1px solid #5C5C5C' : ''
            }}
        >
            <code>
                <div
                    className="control text-to-clipboard with-button-view"
                    style={{
                        background:
                            theme.palette.mode === 'dark'
                                ? '#352d45'
                                : '#f0f2f5'
                    }}
                >
                    <span
                        className="control content"
                        spellCheck="false"
                        style={{
                            color:
                                theme.palette.mode === 'dark'
                                    ? '#DDDDDD'
                                    : '#717171'
                        }}
                    >
                        {text}
                    </span>
                    <span
                        className="control copy"
                        onClick={() => navigator.clipboard.writeText(text)}
                        style={{
                            background:
                                theme.palette.mode === 'dark'
                                    ? '#2b2732'
                                    : 'white',
                            color:
                                theme.palette.mode === 'dark'
                                    ? 'white'
                                    : 'black',
                            boxShadow: `0 0 0 1px ${
                                theme.palette.mode === 'dark'
                                    ? '#5C5C5C'
                                    : '#cbcbcb'
                            }`,
                            fontFamily: 'Proxima Nova Bld'
                        }}
                        title="Copy to clipboard"
                    >
                        <Copy />
                        {COPY}
                    </span>
                </div>
            </code>
        </pre>
    );
};

import React, {
    useState,
    useEffect,
    useRef,
    useMemo,
    useCallback
} from 'react';

import {
    CHECKBOX_STATUS,
    CustomHeader,
    Delete,
    Duplicate,
    IconButton,
    IconDelete,
    IconPencil,
    Loader,
    MoreAction,
    MultiSelect,
    Pagination,
    Refresh,
    Run,
    StyledMenuPaper,
    Table,
    TableNoData,
    TableSkeleton,
    Tooltip,
    Upload,
    View,
    WhitePlus
} from '@armis/armis-ui-library';
import { Editor } from '@monaco-editor/react';
import { PaperProps, Menu, Button, useTheme } from '@mui/material';
import {
    ColDef,
    ICellRendererParams,
    SelectionChangedEvent
} from 'ag-grid-community';
import { AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';
import { LuDownload } from 'react-icons/lu';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TOAST_ID } from 'src/constants/APIConstants';
import { Privileges, Resources, TagTypes } from 'src/constants/CommonConstants';
import {
    ADD_REPORT,
    DELETE,
    DELETE_MULTIPLE_REPORT,
    DELETE_REPORT,
    DELETE_REPORTS,
    DELETE_SINGLE_REPORT,
    DOWNLOAD_JSON,
    DUPLICATE,
    DUPLICATE_REPORT,
    DUPLICATE_REPORT_WARNING,
    EDIT,
    IMPORT_REPORT,
    INVALID_JSON_FILE,
    REFRESH,
    REPORT,
    REPORT_CREATED_SUCCESSFULLY,
    REPORT_DELETED_SUCCESS,
    REPORT_DUPLICATED_SUCCESS,
    REPORT_GENERATION_ERROR,
    REPORT_TEMPLATES,
    REPORTS,
    REPORTS_DELETED_SUCCESS,
    RUN_REPORT,
    SEARCH_REPORT_PLACEHOLDER,
    SUCCESSFUL_REPORT_GENERATED,
    VIEW_JSON
} from 'src/constants/LabelText';
import {
    CREATE_REPORT,
    EDIT_REPORT,
    REPORT_ROUTE,
    TEMPLATE_ROUTE
} from 'src/constants/RouteConstants';
import {
    createRelatedObject,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    NO_DATA_TO_SHOW,
    reportTemplateAPIMapping
} from 'src/constants/TableConstants';
import { reportTemplateColumnsConfig } from 'src/helpers/ColumnsConfig';
import {
    TOAST_TYPE,
    convertQueryObjectToParams,
    displayErrorMessage,
    getDirectionsAndProperties,
    isActionHasPermissions,
    showToast
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { useTable } from 'src/hooks/useTable';
import { ProtectedAction } from 'src/pages/common/ProtectedAction';
import { GenerateReport } from 'src/pages/components/GenerateReport/GenerateReport';
import { GenerateReportResponse } from 'src/pages/components/GenerateReport/GenerateReport.types';
import { Header } from 'src/pages/components/Header';
import { Modal } from 'src/pages/components/Modal';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import { TableHeader } from 'src/pages/components/TableHeader';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';
import {
    createReport,
    deleteReportTemplate,
    deleteSingleReportTemplate,
    duplicateReportTemplate,
    getReportTemplate,
    getRunningReport,
    getSingleReportTemplate,
    getTags
} from 'src/services/api.service';
import { selectUser } from 'src/store/slices/userSlice';
import { GenericResponseData } from 'src/types/APIResponseTypes';
import { ChildRefProp, FilterItems, Map } from 'src/types/CommonTypes';

import { Report, ReportTemplateProps } from './ReportTemplate.types';
import { StyledMenuItem } from '../CustomProperties/CronEditField/CronEditField.style';
import { AvailableReportTemplateActions } from '../PolicyTemplate/constants';
import { StyledInput } from '../SSO&SAML/SamlSettings.style';
import { StyledOption } from '../TenantView/Policies/Policies.style';

const templateReportSortOrder: Map<number> = {};
const templateReportSortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];
const columnFilterObj: { [key: string]: FilterItems[] } = {
    tags: []
};
const queryPropertiesObj: { [key: string]: string } = {
    tags: ''
};
createRelatedObject(
    reportTemplateAPIMapping,
    templateReportSortOrder,
    templateReportSortStatus,
    columnsFilterItems
);

const reportTemplateActionOptions = [
    {
        type: AvailableReportTemplateActions.DUPLICATE,
        icon: <Duplicate />,
        label: DUPLICATE,
        privileges: [Privileges.create]
    },
    {
        type: AvailableReportTemplateActions.DELETE,
        icon: <IconDelete />,
        label: DELETE,
        privileges: [Privileges.delete]
    },
    {
        type: AvailableReportTemplateActions.VIEW_JSON,
        icon: <View height={14} width={14} />,
        label: VIEW_JSON,
        privileges: [Privileges.read]
    },
    {
        type: AvailableReportTemplateActions.DOWNLOAD_JSON,
        icon: <LuDownload height={14} width={14} />,
        label: DOWNLOAD_JSON,
        privileges: [Privileges.read]
    }
];

const ReportTemplateComponent = ({ setIsLoading }: ReportTemplateProps) => {
    const {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        filterItems,
        // setFilterItems,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        modelOpen,
        setModelOpen,
        anchorEl,
        // setAnchorEl,
        onSelectionChanged,
        onFilterChanged,
        queryProperties,
        columnFilterData,
        setColumnFilterData,
        setQueryProperties
        // setColumnSortOrder,
        // setColumnSortStatus
    } = useTable({
        sortOrderObj: templateReportSortOrder,
        sortStatusObj: templateReportSortStatus,
        columnsFilterItems,
        queryPropertiesObj,
        columnFilterObj
    });

    const navigate = useNavigate();
    const paginationRef = useRef<ChildRefProp>();
    const [reportList, setReportList] = useState<Report[]>([]);
    const [totalRows, setTotalRows] = useState<null | number>(null);
    const firstRender = useRef(true);
    const currentUser = useSelector(selectUser);
    const [selectedReports, setselectedReports] = useState<string[]>([]);
    const [selectedReportTemplateName, setselectedReportsTemplateName] =
        useState<string[]>([]);
    // const [currentlyRunningReports, setcurrentlyRunningReports] = useState<
    //     string[]
    // >([]);

    const [currentlyRunningReports, setcurrentlyRunningReports] =
        React.useState<
            Array<{
                reportId: string;
                reportHistoryId: string;
                reportTemplateName: string;
            }>
        >([]);
    const [selectedReportAction, setSelectedReportAction] = useState('');
    const [selectedReportJSON, setSelectedReportJSON] = useState<JSON>();

    const [modelOpenGenerate, setmodelOpenGenerate] = useState(false);
    const [runReport, setRunReport] = useState<string>('');
    const [reportTemplateName, setreportTemplateName] = useState<string>('');
    const [anchorElForReportActions, setanchorElForReportActions] =
        useState<null | HTMLElement>(null);
    const selectedReport = useRef<Report | null>(null);

    const [refreshPage, setRefreshPage] = useState(false);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
    }, [columnSortOrder, columnSortStatus, setQueryProperties]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (reportList.length === 0 && !tableLoading && !firstRender.current) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [reportList, tableLoading, gridRef]);

    useEffect(() => {
        setTableLoading(true);
        const callAPIsSynchronously = async () => {
            try {
                if (columnFilterData.tags.length === 0) {
                    const tagsResponse = await getTags(TagTypes.report);
                    // Preserve old filtered tags while adding new tags if found.
                    setColumnFilterData({
                        tags: tagsResponse.data.content.map(
                            ({ name }: any) => ({
                                id: name,
                                label: name,
                                labelRender: <span>{name}</span>,
                                checkStatus: CHECKBOX_STATUS.CHECKED
                            })
                        )
                    });
                }

                const reportResponse: AxiosResponse<
                    GenericResponseData<Report>
                > = await getReportTemplate(
                    convertQueryObjectToParams(queryProperties)
                );

                setReportList(reportResponse.data.content);
                setTotalRows(reportResponse.data.totalElements);
            } catch (err: any) {
                displayErrorMessage(err);
                setReportList([]);
                setTotalRows(0);
            } finally {
                setTableLoading(false);
                setselectedReports([]);
                selectedReport.current = null;
                gridRef.current?.api.deselectAll();
                if (
                    queryProperties.page === DEFAULT_PAGE &&
                    queryProperties.size === DEFAULT_PAGESIZE
                )
                    paginationRef.current?.resetPagination();
            }
        };

        callAPIsSynchronously();
    }, [queryProperties, setTableLoading, setColumnFilterData, refreshPage]);

    const theme = useTheme();

    const searchReportContainer = useMemo(
        () => (
            <div>
                <SearchBar
                    onChange={newValue => {
                        setQueryProperties(prevValue => ({
                            ...prevValue,
                            searchBy: newValue,
                            page: DEFAULT_PAGE,
                            size: DEFAULT_PAGESIZE
                        }));
                    }}
                    placeholder={SEARCH_REPORT_PLACEHOLDER}
                    searchValue={queryProperties.searchBy as string}
                />
            </div>
        ),
        [queryProperties.searchBy, setQueryProperties]
    );

    const addRunningReport = (e: {
        reportId: string;
        reportHistoryId: string;
        reportTemplateName: string;
    }) => {
        setcurrentlyRunningReports(oldArray => [
            ...oldArray,
            {
                reportId: e.reportId,
                reportHistoryId: e.reportHistoryId,
                reportTemplateName: e.reportTemplateName
            }
        ]);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentlyRunningReports.length > 0) {
                const copyRunningReports = currentlyRunningReports.map(
                    singleReport => singleReport
                );
                setTableLoading(true);
                copyRunningReports.forEach(singleReport => {
                    getRunningReport(singleReport.reportHistoryId)
                        .then((res: AxiosResponse<GenerateReportResponse>) => {
                            if (
                                res.data.status === 'FAILED' ||
                                res.data.status === 'COMPLETED'
                            ) {
                                // console.log(res);
                                showToast(
                                    res.data.status === 'FAILED'
                                        ? REPORT_GENERATION_ERROR.replace(
                                              '%s',
                                              singleReport.reportTemplateName
                                          )
                                        : SUCCESSFUL_REPORT_GENERATED.replace(
                                              '%s',
                                              singleReport.reportTemplateName
                                          ),
                                    res.data.status === 'FAILED'
                                        ? TOAST_TYPE.ERROR
                                        : TOAST_TYPE.SUCCESS,
                                    TOAST_ID
                                );

                                setReportList(
                                    reportList.map(singleRow => {
                                        if (
                                            singleRow.id ===
                                            res.data.reportConfig.id
                                        ) {
                                            return {
                                                ...singleRow,
                                                tenantCount:
                                                    res.data.reportConfig
                                                        .tenantCount,
                                                lastRun:
                                                    res.data.reportConfig
                                                        .lastRun
                                            };
                                        }
                                        return singleRow;
                                    })
                                );

                                setcurrentlyRunningReports(
                                    currentlyRunningReports.filter(
                                        filterReport =>
                                            filterReport.reportHistoryId !==
                                            res.data.id
                                    )
                                );
                            }
                        })
                        .catch(err => {
                            displayErrorMessage(err);
                        })
                        .finally(() => {});
                });
                setTableLoading(false);
            }
        }, 3000);
        return () => {
            clearInterval(intervalId);
        };
    }, [currentlyRunningReports]);

    const isRowSelectable = useCallback(
        (params: any) => !!params.data && !params?.data?.isPredefined,
        []
    );

    const columnConfig = useMemo(() => {
        let columnInfo = cloneDeep(reportTemplateColumnsConfig);
        const hasAccess = isActionHasPermissions(
            currentUser,
            Resources.reports,
            [Privileges.delete]
        );
        if (!hasAccess) columnInfo = cloneDeep(columnInfo).slice(1);
        return [
            ...columnInfo.map(singleColumn => {
                if (
                    singleColumn.field === 'tenantCount' &&
                    !isActionHasPermissions(currentUser, Resources.reports, [
                        Privileges.runreport
                    ])
                ) {
                    delete singleColumn.cellRenderer;
                    return singleColumn;
                }

                if (singleColumn.field === 'checkbox') {
                    return { ...singleColumn, showDisabledCheckboxes: true };
                }

                return singleColumn;
            }),
            {
                field: 'actions',
                headerName: 'Actions',
                suppressAutoSize: true,
                suppressSizeToFit: true,
                suppressMovable: true,
                pinned: 'right',
                initialWidth: 120,
                // eslint-disable-next-line react/no-unstable-nested-components
                cellRenderer: (params: ICellRendererParams<Report>) => (
                    <div
                        style={{
                            display: 'flex',
                            columnGap: '8px',
                            marginTop: '5px'
                        }}
                    >
                        {params?.data?.id &&
                        currentlyRunningReports.findIndex(
                            currentItem =>
                                currentItem.reportId === params?.data?.id
                        ) >= 0 ? (
                            <IconButton
                                className="Icon-Hover-Effect"
                                disabled
                                size="small"
                            >
                                {' '}
                                <Loader
                                    fade={false}
                                    loaderPosition={{ right: 16 }}
                                    loaderType={{
                                        name: 'spinner',
                                        size: 16
                                    }}
                                    show
                                >
                                    <div
                                        className="circle"
                                        data-testid="loader-dots"
                                    />
                                </Loader>
                            </IconButton>
                        ) : (
                            <Tooltip
                                arrow
                                placement="bottom"
                                sx={{
                                    '.MuiTooltip-tooltip': {
                                        marginTop: '4px !important'
                                    }
                                }}
                                title={
                                    <div className="tooltip-arrow-text">
                                        {RUN_REPORT}
                                    </div>
                                }
                            >
                                <IconButton
                                    className="Icon-Hover-Effect"
                                    disabled={
                                        !isActionHasPermissions(
                                            currentUser,
                                            Resources.reports,
                                            [Privileges.runreport]
                                        )
                                    }
                                    onClick={() => {
                                        setRunReport(params?.data?.id || '');
                                        setreportTemplateName(
                                            params?.data?.name || ''
                                        );
                                        setmodelOpenGenerate(true);
                                    }}
                                    size="small"
                                >
                                    <Run />
                                </IconButton>
                            </Tooltip>
                        )}

                        <Tooltip
                            arrow
                            placement="bottom"
                            sx={{
                                '.MuiTooltip-tooltip': {
                                    marginTop: '4px !important'
                                }
                            }}
                            title={
                                <div className="tooltip-arrow-text">{EDIT}</div>
                            }
                        >
                            <IconButton
                                className="Icon-Hover-Effect"
                                disabled={
                                    !isActionHasPermissions(
                                        currentUser,
                                        Resources.reports,
                                        [Privileges.edit]
                                    ) || params?.data?.isPredefined
                                }
                                onClick={() =>
                                    navigate(
                                        `${TEMPLATE_ROUTE}/${REPORT_ROUTE}/${EDIT_REPORT}/${params.data?.id}`
                                    )
                                }
                                size="small"
                            >
                                <IconPencil height={16} width={16} />
                            </IconButton>
                        </Tooltip>
                        <IconButton
                            className="Icon-Hover-Effect"
                            data-testid="template-actions"
                            disabled={
                                !isActionHasPermissions(
                                    currentUser,
                                    Resources.reports,
                                    [
                                        Privileges.delete,
                                        Privileges.read,
                                        Privileges.create
                                    ]
                                )
                            }
                            onClick={e => {
                                setanchorElForReportActions(e.currentTarget);
                                selectedReport.current = params.data!;
                            }}
                            size="small"
                        >
                            <MoreAction />
                        </IconButton>
                    </div>
                ),
                sortable: false
            } as ColDef
        ];
    }, [setModelOpen, currentlyRunningReports]);

    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                onFilterChanged: (
                    columnName: string,
                    selectedItems: FilterItems[]
                ) => {
                    onFilterChanged(columnName, selectedItems);
                    paginationRef.current?.resetPagination();
                },
                columnSortOrder,
                columnSortStatus
            },
            sortable: true,
            resizable: true,
            filter: false,
            filterParams: columnFilterData
        }),
        [
            columnSortOrder,
            onSortChangedCall,
            columnSortStatus,
            columnFilterData,
            onFilterChanged
        ]
    );

    const onCloseReportActionMenu = () => {
        setanchorElForReportActions(null);
    };

    const onCloseReportActionModal = () => {
        setModelOpen(false);
        setSelectedReportAction('');
        selectedReport.current = null;
    };

    useEffect(() => {
        if (
            (selectedReportAction ===
                AvailableReportTemplateActions.VIEW_JSON ||
                selectedReportAction ===
                    AvailableReportTemplateActions.DOWNLOAD_JSON) &&
            selectedReport?.current?.id
        ) {
            setIsLoading(true);
            getSingleReportTemplate(selectedReport?.current?.id)
                .then(res => {
                    const reportJson = res.data;
                    delete reportJson.id;
                    delete reportJson.partnerId;
                    delete reportJson.createdby;
                    delete reportJson.updateddate;
                    delete reportJson.tenantCount;

                    for (let i = 0; i < reportJson?.elements?.length; i++) {
                        if (reportJson.elements[i].isReportlet) {
                            delete reportJson.elements[i].reportlet.id;
                            delete reportJson.elements[i].reportlet.reportId;
                            delete reportJson.elements[i].reportlet.partnerId;

                            delete reportJson.elements[i].reportlet
                                .reportDashlet.id;
                            delete reportJson.elements[i].reportlet
                                .reportDashlet.partnerId;
                            delete reportJson.elements[i].reportlet
                                .reportDashlet.createdby;
                            delete reportJson.elements[i].reportlet
                                .reportDashlet.updateddate;
                        } else {
                            delete reportJson.elements[i].reportElement.id;
                            delete reportJson.elements[i].reportElement
                                .reportId;
                            delete reportJson.elements[i].reportElement
                                .partnerId;
                        }
                    }

                    if (
                        selectedReportAction ===
                        AvailableReportTemplateActions.VIEW_JSON
                    ) {
                        setSelectedReportJSON(reportJson);
                        setModelOpen(true);
                    } else {
                        const link = document.createElement('a');

                        const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
                            JSON.stringify(reportJson)
                        )}`;
                        link?.setAttribute('href', dataStr);

                        link.setAttribute(
                            'download',
                            `${reportJson.name}.json`
                        );

                        // Append to html link element page
                        document.body.appendChild(link);

                        // Start download
                        link.click();

                        // Clean up and remove the link
                        link.parentNode?.removeChild(link);

                        onCloseReportActionModal();
                    }
                })
                .catch(err => {
                    displayErrorMessage(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [selectedReportAction]);

    const deleteReports = () => {
        setTableLoading(true);
        deleteReportTemplate(selectedReports)
            .then(() => {
                setModelOpen(false);
                showToast(
                    selectedReports.length === 1
                        ? REPORT_DELETED_SUCCESS
                        : REPORTS_DELETED_SUCCESS,
                    TOAST_TYPE.SUCCESS,
                    TOAST_ID
                );
            })
            .catch(err => {
                displayErrorMessage(err);
            })
            .finally(() => {
                setRefreshPage(prevValue => !prevValue);
                setTableLoading(false);
            });
    };

    const deleteSingleReport = (currentReportId: string) => {
        setTableLoading(true);
        deleteSingleReportTemplate(currentReportId)
            .then(() => {
                setModelOpen(false);
                showToast(REPORT_DELETED_SUCCESS, TOAST_TYPE.SUCCESS, TOAST_ID);
            })
            .catch(err => {
                displayErrorMessage(err);
            })
            .finally(() => {
                setRefreshPage(prevValue => !prevValue);
                setTableLoading(false);
            });
    };

    const duplicateReport = (
        currentReportId: string,
        currentReportName: string
    ) => {
        setTableLoading(true);
        duplicateReportTemplate(currentReportId)
            .then(() => {
                setModelOpen(false);
                showToast(
                    REPORT_DUPLICATED_SUCCESS.replace('%s', currentReportName),
                    TOAST_TYPE.SUCCESS,
                    TOAST_ID
                );
            })
            .catch(err => {
                displayErrorMessage(err);
            })
            .finally(() => {
                setRefreshPage(prevValue => !prevValue);
                setTableLoading(false);
            });
    };

    const rowSelectionChangeHandler = (
        event: SelectionChangedEvent<Report>
    ) => {
        setselectedReports(
            event.api.getSelectedRows().map(report => report.id)
        );
        setselectedReportsTemplateName(
            event.api.getSelectedRows().map(report => report.name)
        );
    };

    const isJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const handleCapture = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onloadend = async (evt: any) => {
            const filedata = evt.currentTarget.result;

            if (isJsonString(filedata)) {
                setIsLoading(true);

                const formData = new FormData();

                // @ts-ignore
                formData.append(
                    'report',
                    new Blob([filedata], {
                        type: 'application/json'
                    })
                );

                createReport(formData)
                    .then(() => {
                        showToast(
                            REPORT_CREATED_SUCCESSFULLY,
                            TOAST_TYPE.SUCCESS,
                            TOAST_ID
                        );
                        setRefreshPage(prevValue => !prevValue);
                    })
                    .catch(err => displayErrorMessage(err))
                    .finally(() => {
                        event.target.value = null;
                        setIsLoading(false);
                    });
                onCloseReportActionModal();
            } else {
                showToast(INVALID_JSON_FILE, TOAST_TYPE.ERROR, TOAST_ID);
            }
        };
    };

    return (
        <>
            <Header title={REPORT_TEMPLATES}>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <ProtectedAction
                        hasPermissions={[Privileges.create]}
                        resource={Resources.reports}
                    >
                        <Button
                            className="header-add-button"
                            color="primary"
                            disabled={tableLoading}
                            onClick={() =>
                                navigate(
                                    `${TEMPLATE_ROUTE}/${REPORT_ROUTE}/${CREATE_REPORT}`
                                )
                            }
                            startIcon={<WhitePlus />}
                            style={{
                                width: '137px',
                                paddingLeft: '15px'
                            }}
                            variant="contained"
                        >
                            {ADD_REPORT}
                        </Button>
                    </ProtectedAction>
                    <ProtectedAction
                        hasPermissions={[Privileges.create]}
                        resource={Resources.reports}
                    >
                        <div className="file-wrapper">
                            <label
                                className="control file-input"
                                htmlFor="file-upload"
                            >
                                <div className="input">
                                    <input hidden type="file" />
                                    <Button
                                        className="header-add-button"
                                        color="primary"
                                        component="span"
                                        disabled={tableLoading}
                                        onClick={() => {}}
                                        startIcon={<Upload />}
                                        style={{
                                            width: '155px',
                                            paddingLeft: '15px',
                                            height: '32px'
                                        }}
                                        variant="outlined"
                                    >
                                        {IMPORT_REPORT}
                                    </Button>
                                </div>
                            </label>
                            <StyledInput
                                accept=".json"
                                id="file-upload"
                                onChange={handleCapture}
                                type="file"
                            />
                        </div>
                    </ProtectedAction>
                </div>
            </Header>
            <div className="control table">
                <TableHeader
                    childrenLeft={
                        <>
                            {searchReportContainer}

                            <div>
                                <ProtectedAction
                                    hasPermissions={[Privileges.delete]}
                                    resource={Resources.reports}
                                >
                                    <Button
                                        color="primary"
                                        disabled={
                                            !selectedReports.length ||
                                            tableLoading
                                        }
                                        onClick={() => {
                                            setSelectedReportAction(
                                                'DELETE_MULTIPLE'
                                            );
                                            setModelOpen(true);
                                        }}
                                        startIcon={<Delete />}
                                        variant="text"
                                        // sx={{ padding: 0 }}
                                    >
                                        {DELETE_REPORTS}
                                    </Button>
                                </ProtectedAction>
                            </div>
                        </>
                    }
                    childrenRight={
                        <Tooltip
                            arrow
                            placement="bottom"
                            title={
                                <div className="tooltip-arrow-text">
                                    {REFRESH}
                                </div>
                            }
                        >
                            <IconButton
                                className="Icon-Hover-Effect"
                                color="primary"
                                disabled={tableLoading}
                                onClick={() => {
                                    setRefreshPage(prevValue => !prevValue);
                                }}
                            >
                                <Refresh />
                            </IconButton>
                        </Tooltip>
                    }
                    loading={tableLoading}
                    onColumnMenuClick={handleMenuClick}
                    selectedCount={selectedReports.length}
                    title={`${totalRows} ${
                        totalRows === 1 ? `${REPORT}` : `${REPORTS}`
                    }`}
                />
                <Menu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    PaperProps={
                        {
                            component: StyledMenuPaper
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                >
                    <MultiSelect
                        items={filterItems}
                        onSelectionChanged={onSelectionChanged}
                        showSelectAllOption
                    />
                </Menu>
                <Menu
                    anchorEl={anchorElForReportActions}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    onClose={onCloseReportActionMenu}
                    open={Boolean(anchorElForReportActions)}
                    PaperProps={
                        {
                            component: StyledMenuPaper,
                            sx: {
                                marginTop: '0px !important'
                            }
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    {reportTemplateActionOptions.map(action => (
                        <ProtectedAction
                            key={action.type}
                            hasAnyPermission={action.privileges}
                            resource={Resources.reports}
                        >
                            <StyledMenuItem
                                key={action.type}
                                disabled={
                                    action.type ===
                                        AvailableReportTemplateActions.DELETE &&
                                    selectedReport?.current?.isPredefined
                                }
                                onClick={() => {
                                    onCloseReportActionMenu();
                                    setSelectedReportAction(action.type);
                                    if (
                                        action.type !==
                                            AvailableReportTemplateActions.VIEW_JSON &&
                                        action.type !==
                                            AvailableReportTemplateActions.DOWNLOAD_JSON
                                    ) {
                                        setModelOpen(true);
                                    }
                                }}
                            >
                                {action.icon}
                                <StyledOption> {action.label} </StyledOption>
                            </StyledMenuItem>
                        </ProtectedAction>
                    ))}
                </Menu>
                {modelOpen && selectedReportAction === 'DELETE_MULTIPLE' && (
                    <Modal
                        displayBtn="all"
                        isModalOpen={modelOpen}
                        onCancel={() => setModelOpen(false)}
                        onSubmit={() => {
                            setModelOpen(false);
                            deleteReports();
                        }}
                        submitBtnLabel={DELETE}
                        title={
                            selectedReports.length > 1
                                ? DELETE_REPORTS
                                : DELETE_REPORT
                        }
                    >
                        <WarningModalContainer
                            text={
                                selectedReports.length > 1
                                    ? DELETE_MULTIPLE_REPORT.replace(
                                          '%s',
                                          selectedReports.length.toString()
                                      )
                                    : DELETE_SINGLE_REPORT.replace(
                                          '%s',
                                          selectedReportTemplateName.toString()
                                      )
                            }
                        />
                    </Modal>
                )}
                {modelOpen &&
                    selectedReportAction ===
                        AvailableReportTemplateActions.DELETE && (
                        <Modal
                            displayBtn="all"
                            isModalOpen={modelOpen}
                            onCancel={() => {
                                setModelOpen(false);
                                onCloseReportActionModal();
                            }}
                            onSubmit={() => {
                                setModelOpen(false);
                                deleteSingleReport(
                                    selectedReport?.current?.id || ''
                                );
                            }}
                            submitBtnLabel={DELETE}
                            title={DELETE_REPORT}
                        >
                            <WarningModalContainer
                                text={DELETE_SINGLE_REPORT.replace(
                                    '%s',
                                    selectedReport.current?.name || ''
                                )}
                            />
                        </Modal>
                    )}
                {modelOpen &&
                    selectedReportAction ===
                        AvailableReportTemplateActions.DUPLICATE && (
                        <Modal
                            displayBtn="all"
                            isModalOpen={modelOpen}
                            onCancel={() => {
                                setModelOpen(false);
                                onCloseReportActionModal();
                            }}
                            onSubmit={() => {
                                setModelOpen(false);
                                duplicateReport(
                                    selectedReport?.current?.id || '',
                                    selectedReport.current?.name || ''
                                );
                            }}
                            submitBtnLabel={DUPLICATE}
                            title={DUPLICATE_REPORT}
                        >
                            <WarningModalContainer
                                text={DUPLICATE_REPORT_WARNING.replace(
                                    '%s',
                                    selectedReport.current?.name || ''
                                )}
                            />
                        </Modal>
                    )}
                {modelOpen &&
                    selectedReportAction ===
                        AvailableReportTemplateActions.VIEW_JSON && (
                        <Modal
                            className="x-wide"
                            displayBtn="cancel"
                            isModalOpen={modelOpen}
                            onCancel={() => {
                                setModelOpen(false);
                                onCloseReportActionModal();
                            }}
                            onSubmit={() => {
                                setModelOpen(false);
                            }}
                            submitBtnLabel={DELETE}
                            title={selectedReport?.current?.name || ''}
                        >
                            <Editor
                                defaultLanguage="json"
                                defaultValue={JSON.stringify(
                                    selectedReportJSON,
                                    null,
                                    2
                                )}
                                height="50vh"
                                options={{
                                    readOnly: true
                                }}
                                theme={
                                    theme.palette.mode === 'dark'
                                        ? 'vs-dark'
                                        : ''
                                }
                            />
                        </Modal>
                    )}

                {modelOpenGenerate && (
                    <GenerateReport
                        onClickCancel={() => {
                            setmodelOpenGenerate(false);
                        }}
                        onSubmitSuccess={e => {
                            addRunningReport(e);
                            setmodelOpenGenerate(false);
                        }}
                        reportId={runReport}
                        reportTemplateName={reportTemplateName}
                        setIsLoading={setIsLoading}
                        submitBtnLabel="Run Report"
                        title="Run Report"
                        type="generate"
                        // submitBtnLabel="Generate"
                        // title="Generate Report"
                    />
                )}
                <Table
                    ref={gridRef}
                    columnDefs={columnConfig}
                    defaultColDef={defaultColDefs}
                    isRowSelectable={isRowSelectable}
                    loadingOverlayComponent={TableSkeleton}
                    noRowsOverlayComponent={TableNoData}
                    noRowsOverlayComponentParams={{
                        content: NO_DATA_TO_SHOW
                    }}
                    onSelectionChanged={rowSelectionChangeHandler}
                    rowData={reportList}
                    suppressNoRowsOverlay={firstRender.current}
                />
                {!!totalRows && totalRows > 0 && (
                    <span>
                        <Pagination
                            ref={paginationRef}
                            onPaginationChanged={(page, size) => {
                                setQueryProperties(prevValue => ({
                                    ...prevValue,
                                    page,
                                    size
                                }));
                            }}
                            totalRowsCount={totalRows}
                        />
                    </span>
                )}
            </div>
        </>
    );
};

export const ReportTemplate = IsLoadingHOC(ReportTemplateComponent);

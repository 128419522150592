import React, { useEffect, useState } from 'react';

import {
    ArrowDown,
    Button,
    CHECKBOX_STATUS,
    PermissionsReadOnly,
    PrivilegeType,
    IconDelete,
    WhitePlus
} from '@armis/armis-ui-library';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { TOAST_ID } from 'src/constants/APIConstants';
import { Privileges, Resources } from 'src/constants/CommonConstants';
import {
    ROLE_MANAGEMENT,
    PREDEFINED_ROLES,
    CUSTOM_ROLES,
    SHOW_ALL,
    SEARCH_ROLE_PLACEHOLDER,
    SHOW_LESS,
    DEFAULT_ROLE_NAME,
    ROLE_UPDATE_SUCCESS,
    ADD_ROLE,
    DELETE_ROLES,
    DELETE_ROLE_SUCCESS,
    DELETE_ROLES_SUCCESS,
    ROLE_CREATE_SUCCESS,
    DUPLICATE_ROLE_SUCCESS
} from 'src/constants/LabelText';
import {
    showToast,
    TOAST_TYPE,
    displayErrorMessage,
    isActionHasPermissions
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { ProtectedAction } from 'src/pages/common/ProtectedAction';
import { Header } from 'src/pages/components/Header';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import {
    createRole,
    deleteRoles,
    getRoles,
    getRoleStructure,
    updateRole
} from 'src/services/api.service';
import {
    selectRoleStructure,
    setRoleStructure
} from 'src/store/slices/roleSlice';
import { selectUser } from 'src/store/slices/userSlice';
import {
    ErrorResponse,
    PrivilegeRes,
    RoleResponse,
    RoleStructureResponse
} from 'src/types/APIResponseTypes';

import { ContentHeader } from './ContentHeader/ContentHeader';
import DeleteRoleModal from './DeleteRoleModal/DeleteRoleModal';
import {
    getIcon,
    LOW_PRIVILEGE,
    recursivelyUpdateChildStatus
} from './helpers';
import { RoleElement } from './RoleElement/RoleElement';
import {
    AllRolesContainer,
    PredefinedRoleContainer,
    RoleManagementContainer,
    RolesContainer,
    ShowMoreFooter,
    ShowMorePredefinedRoles
} from './RoleManagement.style';
import {
    RoleManagementProps,
    RoleState,
    SelectedRole
} from './RoleManagement.types';

const PREDEFINED_ROLE_LIMIT = 3;

const RoleManagementComponent = ({ setIsLoading }: RoleManagementProps) => {
    const [searchValue, setSearchValue] = useState('');
    const [roles, setRoles] = useState<RoleState[]>([]);
    const [customRoles, setCustomRoles] = useState<RoleState[]>([]);
    const [selectedRoles, setselectedRoles] = useState<SelectedRole[]>([]);
    const [selectedRole, setSelectedRole] = useState<SelectedRole | null>(null); // When performing actions like delete, duplicate on single role.
    const [showAllRoles, setShowAllRoles] = useState(false);
    const [addOrCopyRoleMode, setAddOrCopyRoleMode] = useState(false);
    const [copyToBeRole, setCopyToBeRole] = useState<
        (SelectedRole & { isDefault: boolean }) | null
    >(null);
    const [showDeleteWarningModal, setshowDeleteWarningModal] = useState(false);
    const [allowDeleteRole, setAllowDeleteRole] = useState(true);
    const [setRoleRefresh, setSetRoleRefresh] = useState(false);

    const [newReadOnlyRole, setNewReadOnlyRole] = useState<RoleState | null>(
        null
    );

    const roleStructure = useSelector(selectRoleStructure);
    const currentUser = useSelector(selectUser);
    const dispatch = useDispatch();

    useEffect(() => {
        // Create a new read only role.
        if (!newReadOnlyRole && roles.length) {
            setNewReadOnlyRole({
                ...roles[0],
                resources: roles[0].resources.map(resource => {
                    const structurePrivileges = cloneDeep(resource.privileges);
                    recursivelyUpdateChildStatus(
                        structurePrivileges,
                        CHECKBOX_STATUS.UNCHECKED,
                        false
                    );

                    /**
                     * Recursively add the checkbox status for all privileges. Make read privilege as checked.
                     */
                    return {
                        ...resource,
                        icon: PermissionsReadOnly,
                        privileges: structurePrivileges.map(privilege => ({
                            ...privilege,
                            checkStatus:
                                privilege.label === LOW_PRIVILEGE
                                    ? CHECKBOX_STATUS.CHECKED
                                    : CHECKBOX_STATUS.UNCHECKED
                        }))
                    };
                }),
                userDetails: [],
                name: DEFAULT_ROLE_NAME,
                id: '',
                isdefault: false
            });
        }
    }, [roles, newReadOnlyRole]);

    /**
     * Map roles response with role definition and create roles.
     */
    const mapStructureWithRoles = (
        rolesRes: RoleResponse[],
        structureRes: RoleStructureResponse
    ) => {
        const isUserHasEditPermission = isActionHasPermissions(
            currentUser,
            Resources.roles,
            [Privileges.edit]
        );

        const generatedRoles: RoleState[] = rolesRes.map(roleRes => ({
            ...roleRes,
            resources: structureRes.map(resource => {
                const currRolePrivileges: Set<string> = new Set(
                    roleRes.resources.find(
                        rolResource => rolResource.name === resource.name
                    )?.privilegeIds
                );

                /**
                 * Recursively adding the checkbox status for all privileges.
                 */
                const fillPrivilegeRecursively = (
                    currPrivilege: PrivilegeRes,
                    level: number
                ): PrivilegeType => {
                    const generatedChilds = currPrivilege.children?.map(
                        childPrivilege =>
                            fillPrivilegeRecursively(childPrivilege, level + 1)
                    );

                    const findIntermidiateLabelStatus = () => {
                        let notChecked = 0;
                        let anyoneIndeterminate = false;
                        generatedChilds.forEach(child => {
                            if (
                                child.checkStatus ===
                                CHECKBOX_STATUS.INDETERMINATE
                            ) {
                                anyoneIndeterminate = true;
                            }
                            notChecked +=
                                child.checkStatus === CHECKBOX_STATUS.UNCHECKED
                                    ? 1
                                    : 0;
                        });

                        if (notChecked === generatedChilds.length)
                            return CHECKBOX_STATUS.UNCHECKED;
                        if (notChecked || anyoneIndeterminate)
                            return CHECKBOX_STATUS.INDETERMINATE;
                        return CHECKBOX_STATUS.CHECKED;
                    };

                    return {
                        id: currPrivilege.id,
                        isExpanded: false,
                        isDisabled:
                            roleRes.isdefault ||
                            (level === 0 &&
                                currPrivilege.label === LOW_PRIVILEGE) ||
                            !isUserHasEditPermission,
                        childrens: generatedChilds,
                        label: currPrivilege.label,
                        checkStatus: generatedChilds.length
                            ? findIntermidiateLabelStatus()
                            : currRolePrivileges.has(currPrivilege.id)
                            ? CHECKBOX_STATUS.CHECKED
                            : CHECKBOX_STATUS.UNCHECKED
                    };
                };

                const privileges = resource.privileges.map(privilege =>
                    fillPrivilegeRecursively(privilege, 0)
                );

                return {
                    ...resource,
                    privileges,
                    icon: getIcon(privileges)
                };
            })
        }));

        setRoles(generatedRoles.filter(role => role.isdefault));
        setCustomRoles(generatedRoles.filter(role => !role.isdefault));
    };

    useEffect(() => {
        let rolesResponse: RoleResponse[];
        let structureResponse: RoleStructureResponse;
        setIsLoading(true);
        getRoles(searchValue)
            .then(res => {
                rolesResponse = res.data as RoleResponse[];
                if (roleStructure) {
                    mapStructureWithRoles(rolesResponse, roleStructure);
                    return null;
                }
                return getRoleStructure();
            })
            .then(res => {
                if (res) {
                    structureResponse = res.data;
                    dispatch(setRoleStructure(res.data));
                    mapStructureWithRoles(rolesResponse, structureResponse);
                }
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                setRoles([]);
                setCustomRoles([]);
                displayErrorMessage(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [searchValue, setRoleRefresh]);

    const onRoleSave = async (
        rolePayload: RoleResponse,
        updatedRole: RoleState,
        isNewRole: boolean
    ) => {
        setIsLoading(true);
        if (isNewRole) {
            return createRole(rolePayload)
                .then(() => {
                    setAddOrCopyRoleMode(false);

                    let successMsg = '';

                    if (copyToBeRole) {
                        const roleNameList = [
                            copyToBeRole.name,
                            rolePayload.name
                        ];
                        let idx = 0;
                        successMsg = DUPLICATE_ROLE_SUCCESS.replace(
                            /%s/g,
                            () => roleNameList[idx++]
                        );
                        setCopyToBeRole(null);
                    } else {
                        successMsg = ROLE_CREATE_SUCCESS.replace(
                            '%s',
                            rolePayload.name
                        );
                    }

                    showToast(successMsg, TOAST_TYPE.SUCCESS, TOAST_ID);
                    setSetRoleRefresh(prevValue => !prevValue);

                    return true;
                })
                .catch((err: AxiosError<ErrorResponse>) => {
                    displayErrorMessage(err);
                    setIsLoading(false);
                    return false;
                });
        }
        return updateRole(rolePayload.id, rolePayload)
            .then(() => {
                showToast(
                    ROLE_UPDATE_SUCCESS.replace('%s', rolePayload.name),
                    TOAST_TYPE.SUCCESS,
                    TOAST_ID
                );
                setCustomRoles(prevRoles => {
                    const oldRoles = cloneDeep(prevRoles);
                    const updatedRoleIdx = oldRoles.findIndex(
                        role => role.id === updatedRole.id
                    );
                    oldRoles[updatedRoleIdx] = updatedRole;
                    oldRoles.sort((role1, role2) =>
                        role1.name.localeCompare(role2.name)
                    );
                    return oldRoles;
                });
                return true;
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                displayErrorMessage(err);
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const selectedRoleName =
        selectedRole || selectedRoles.length === 1
            ? (selectedRole || selectedRoles[0]).name
            : '';

    const onConfirmDeleteHandler = () => {
        if (allowDeleteRole) {
            setIsLoading(true);
            let roleIds: string[] = [];

            if (selectedRole) {
                roleIds = [selectedRole.id];
            } else {
                roleIds = selectedRoles.map(sRole => sRole.id);
            }

            deleteRoles(roleIds)
                .then(() => {
                    let successMessage = DELETE_ROLE_SUCCESS.replace(
                        '%s',
                        selectedRoleName
                    );

                    if (roleIds.length > 1) {
                        successMessage = DELETE_ROLES_SUCCESS.replace(
                            '%s',
                            roleIds.length.toString()
                        );
                    }

                    if (selectedRole)
                        setselectedRoles(prevValue =>
                            prevValue.filter(
                                sRole => sRole.id !== selectedRole.id
                            )
                        );
                    else setselectedRoles([]);
                    setSelectedRole(null);
                    setshowDeleteWarningModal(false);
                    setSetRoleRefresh(prevValue => !prevValue);
                    showToast(successMessage, TOAST_TYPE.SUCCESS, TOAST_ID);
                })
                .catch((err: AxiosError<ErrorResponse>) => {
                    displayErrorMessage(err);
                    setIsLoading(false);
                });
        } else {
            setAllowDeleteRole(true);
            setshowDeleteWarningModal(false);
            setSelectedRole(null);
        }
    };

    return (
        <RoleManagementContainer>
            <Header title={ROLE_MANAGEMENT}>
                <ProtectedAction
                    hasPermissions={[Privileges.create]}
                    resource={Resources.roles}
                >
                    <Button
                        className="control has-inline-title svg-symbol-button transition theme-5 header-add-button"
                        color="primary"
                        disabled={addOrCopyRoleMode || !newReadOnlyRole}
                        onClick={() => setAddOrCopyRoleMode(true)}
                        startIcon={<WhitePlus />}
                        variant="contained"
                    >
                        {ADD_ROLE}
                    </Button>
                </ProtectedAction>
            </Header>
            <DeleteRoleModal
                allowDelete={allowDeleteRole}
                onClickCancel={() => {
                    setshowDeleteWarningModal(false);
                    setSelectedRole(null);
                }}
                onClickSubmit={onConfirmDeleteHandler}
                selectedRoleLength={selectedRoles.length}
                selectedRoleName={selectedRoleName}
                showDeleteWarningModal={showDeleteWarningModal}
            />

            <AllRolesContainer>
                <ContentHeader
                    roleCount={roles.length}
                    title={PREDEFINED_ROLES}
                >
                    <SearchBar
                        onChange={setSearchValue}
                        placeholder={SEARCH_ROLE_PLACEHOLDER}
                        searchValue={searchValue}
                    />
                </ContentHeader>
                <PredefinedRoleContainer>
                    <RolesContainer>
                        {roles.slice(0, PREDEFINED_ROLE_LIMIT).map(role => (
                            <RoleElement
                                key={role.id}
                                newRoleOrCopyMode={addOrCopyRoleMode}
                                onClickDuplicate={() => {
                                    setAddOrCopyRoleMode(true);
                                    setCopyToBeRole({
                                        name: role.name,
                                        id: role.id,
                                        isDefault: true
                                    });
                                }}
                                role={role}
                            />
                        ))}
                    </RolesContainer>
                    <ShowMorePredefinedRoles
                        sx={{
                            opacity: showAllRoles ? 1 : 0,
                            maxHeight: showAllRoles ? 14691 : 0
                        }}
                    >
                        <RolesContainer>
                            {roles.slice(PREDEFINED_ROLE_LIMIT).map(role => (
                                <RoleElement
                                    key={role.id}
                                    newRoleOrCopyMode={addOrCopyRoleMode}
                                    onClickDuplicate={() => {
                                        setAddOrCopyRoleMode(true);
                                        setCopyToBeRole({
                                            name: role.name,
                                            id: role.id,
                                            isDefault: true
                                        });
                                    }}
                                    role={role}
                                />
                            ))}
                        </RolesContainer>
                    </ShowMorePredefinedRoles>
                    {roles.length > PREDEFINED_ROLE_LIMIT && (
                        <ShowMoreFooter
                            onClick={() =>
                                setShowAllRoles(prevValue => !prevValue)
                            }
                        >
                            <span className="action">
                                {showAllRoles ? SHOW_LESS : SHOW_ALL}
                            </span>
                            <ArrowDown
                                className={`arrow ${
                                    showAllRoles ? 'open' : ''
                                }`}
                            />
                        </ShowMoreFooter>
                    )}
                </PredefinedRoleContainer>
                <ContentHeader
                    roleCount={customRoles.length}
                    selectedRoleCount={selectedRoles.length}
                    title={CUSTOM_ROLES}
                >
                    <ProtectedAction
                        hasPermissions={[Privileges.delete]}
                        resource={Resources.roles}
                    >
                        <Button
                            className="control svg-symbol-button transition theme-5"
                            color="primary"
                            disabled={!selectedRoles.length}
                            onClick={() => {
                                setshowDeleteWarningModal(true);
                                const anyOneHasUsers = selectedRoles.some(
                                    role =>
                                        customRoles.find(
                                            cRole => cRole.id === role.id
                                        )?.userDetails?.length
                                );

                                setAllowDeleteRole(!anyOneHasUsers);
                            }}
                            startIcon={<IconDelete />}
                            variant="text"
                        >
                            {DELETE_ROLES}
                        </Button>
                    </ProtectedAction>
                </ContentHeader>
                <RolesContainer>
                    {customRoles.map(role => (
                        <RoleElement
                            key={role.id}
                            isSelected={
                                !!selectedRoles.find(
                                    sRole => sRole.id === role.id
                                )
                            }
                            newRoleOrCopyMode={addOrCopyRoleMode}
                            onClickDelete={() => {
                                setSelectedRole({
                                    name: role.name,
                                    id: role.id
                                });
                                setshowDeleteWarningModal(true);
                                setAllowDeleteRole(!role.userDetails?.length);
                            }}
                            onClickDuplicate={() => {
                                setAddOrCopyRoleMode(true);
                                setCopyToBeRole({
                                    name: role.name,
                                    id: role.id,
                                    isDefault: false
                                });
                            }}
                            onRoleSave={onRoleSave}
                            onRoleSelection={setselectedRoles}
                            role={role}
                        />
                    ))}
                    {addOrCopyRoleMode && (
                        <RoleElement
                            isNewRole
                            newRoleOrCopyMode={addOrCopyRoleMode}
                            onClickDelete={() => {
                                setAddOrCopyRoleMode(false);
                                setCopyToBeRole(null);
                            }}
                            onRoleSave={onRoleSave}
                            role={(() => {
                                if (copyToBeRole) {
                                    let clonedRole: RoleState | null = null;

                                    const getClonedRole = (
                                        oldRoles: RoleState[]
                                    ) =>
                                        cloneDeep(
                                            oldRoles.find(
                                                cRole =>
                                                    cRole.id === copyToBeRole.id
                                            )
                                        ) as RoleState;

                                    if (copyToBeRole.isDefault) {
                                        clonedRole = getClonedRole(roles);
                                    } else {
                                        clonedRole = getClonedRole(customRoles);
                                    }

                                    clonedRole.resources =
                                        clonedRole.resources.map(resource => {
                                            recursivelyUpdateChildStatus(
                                                resource.privileges,
                                                undefined,
                                                false
                                            );

                                            return resource;
                                        });

                                    return {
                                        ...clonedRole,
                                        name: `${clonedRole.name} (copy)`,
                                        userDetails: [],
                                        id: '',
                                        isdefault: false,
                                        resources: clonedRole.resources
                                    };
                                }
                                return newReadOnlyRole as RoleState;
                            })()}
                        />
                    )}
                </RolesContainer>
            </AllRolesContainer>
        </RoleManagementContainer>
    );
};

export const RoleManagement = IsLoadingHOC(RoleManagementComponent);

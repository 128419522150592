import React from 'react';

import { Grid } from '@mui/material';
import {
    STATUS,
    PUSHED_BY,
    PUSH_ID,
    PUSH_SUMMARY,
    TIME
} from 'src/constants/LabelText';

import {
    PushHistoryDiv,
    StyledDiv,
    StyledLabel,
    StyledValue
} from './PushSummary.style';

type PushSummaryProps = {
    id: string;
    pushedBy: string;
    time: string;
    status: string;
};

const PushSummary = ({ id, pushedBy, time, status }: PushSummaryProps) => {
    const PUSH_SUMMARY_MAP = [
        {
            label: PUSH_ID,
            value: id
        },
        {
            label: TIME,
            value: time
        },
        {
            label: PUSHED_BY,
            value: pushedBy
        },
        {
            label: STATUS,
            value: status
        }
    ];
    return (
        <PushHistoryDiv>
            <Grid
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                container
                rowSpacing={1}
                sx={{
                    margin: '10px 0px 8px -30px !important',
                    maxWidth: '100%'
                }}
            >
                <Grid
                    item
                    sx={{ padding: '10 0 10 0px', marginBottom: '10px' }}
                    xs={12}
                >
                    <StyledLabel sx={{ fontSize: '1.6rem' }}>
                        {PUSH_SUMMARY}
                    </StyledLabel>
                </Grid>
                {PUSH_SUMMARY_MAP.map(item => (
                    <Grid
                        key={item.label}
                        item
                        sx={{
                            paddingTop: '0px !important'
                        }}
                        xs={5}
                    >
                        <StyledDiv>
                            <StyledLabel>{item.label}</StyledLabel>
                            <StyledValue>{item.value}</StyledValue>
                        </StyledDiv>
                    </Grid>
                ))}
            </Grid>
        </PushHistoryDiv>
    );
};

export default PushSummary;

import { constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material/styles';

export const StyledDiv = styled('div')`
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
`;

export const StyledLabel = styled('span')`
    font-family: ${constants.PROXIMANOVA_W08_SEMIBOLD};
    font-size: 1.4rem;
    padding-left: 10px;
`;

export const StyledValue = styled('span')`
    font-family: ${constants.PROXIMANOVA_W08_REGULAR};
    font-size: 1.4rem;
    padding-left: 10px;
`;

export const PushHistoryDiv = styled('div')`
    color: ${props =>
        props.theme.palette.mode === 'dark' ? '#FCFCFC' : '#121212'};
`;

import { constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const StyledField = styled('div')`
    &:not(:last-child) {
        padding-bottom: 10pt;
    }
    .MuiFormLabel-root {
        font-family: ${constants.PROXIMANOVA_W08_BOLD};
        margin-bottom: 4px;
        font-size: 14px;
        display: block;
        color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_WHITE
                : constants.COLOR_GENERAL_29};
    }
    .MuiInputBase-root {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_DARK_3
                : constants.COLOR_WHITE};
    }
    .modal-text-field {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_29
                : constants.COLOR_WHITE};
    }
`;

import React, { useEffect, useRef, useState } from 'react';

import {
    CHECKBOX_STATUS,
    constants,
    InfoIcon,
    MultiSelect,
    StyledMenuPaper,
    Table,
    TableNoData,
    TableSkeleton,
    Tooltip
} from '@armis/armis-ui-library';
import { Menu, PaperProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TOAST_ID } from 'src/constants/APIConstants';
import { SYNC_STATUS } from 'src/constants/APIResponse';
import { ASQ, DASHBOARD, TENANT, TENANTS } from 'src/constants/LabelText';
import {
    commonDashboardAPIMapping,
    createRelatedObject,
    NO_DATA_TO_SHOW
} from 'src/constants/TableConstants';
import { commonDashboardColumnDef } from 'src/helpers/ColumnsConfig';
import {
    displayErrorMessage,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { useTable } from 'src/hooks/useTable';
import { Header } from 'src/pages/components/Header';
import { TableHeader } from 'src/pages/components/TableHeader';
import { CommonTable } from 'src/pages/containers/DashBoard/CommonTable/CommonTable';
import {
    DashBoardProps,
    TableRowData
} from 'src/pages/containers/DashBoard/DashBoard.types';
import {
    getDashboardDataStatus,
    getDashboards,
    postDashboards
} from 'src/services/api.service';
import { axiosController } from 'src/services/axiosInstance';
import {
    selectDashBoardQuery,
    selectDashBoardTenants,
    setIsDataFetchInProgress
} from 'src/store/slices/dashBoardSlice';
import { selectUser } from 'src/store/slices/userSlice';
import {
    DashBoardsResponse,
    ErrorResponse,
    GetDashboardDataStatusResponse,
    PostDashBoardsResponse
} from 'src/types/APIResponseTypes';
import { FilterItems } from 'src/types/CommonTypes';

import {
    StyledCard,
    StyledDashBoardContainer,
    StyledDashBoardItem,
    StyledDiv,
    StyledEmptyContainer,
    StyledHeader
} from './DashBoard.style';

const columnsFilterItems: FilterItems[] = [];
createRelatedObject(
    commonDashboardAPIMapping,
    undefined,
    undefined,
    columnsFilterItems
);

const DashBoardComp = ({ setIsLoading }: DashBoardProps) => {
    const {
        gridRef,
        tableLoading,
        setTableLoading,
        filterItems,
        anchorEl,
        handleMenuClick,
        handleMenuClose,
        onSelectionChanged
    } = useTable({
        columnsFilterItems
    });

    const theme = useTheme();

    const dashBoardQuery = useSelector(selectDashBoardQuery);
    const dashBoardTenants = useSelector(selectDashBoardTenants);
    const [dashboards, setDashboards] = useState<DashBoardsResponse[] | []>([]);
    const [rowData, setRowData] = useState<TableRowData[] | null>(null);
    const pollIntervalId = useRef<any>(0);

    const dispatch = useDispatch();
    const location = useLocation();
    const currentUser = useSelector(selectUser);
    const isArmisUser = currentUser.username.includes('@armis.com');

    const resetAfterFetchedDashboard = () => {
        setTableLoading(false);
        dispatch(setIsDataFetchInProgress(false));
        clearTimeout(pollIntervalId.current);
        pollIntervalId.current = 0;
    };

    const pollQueryResults = (jobId: string) => {
        getDashboardDataStatus(jobId)
            .then(({ data }: AxiosResponse<GetDashboardDataStatusResponse>) => {
                const { message, status, dashboardData } = data;
                if (status === SYNC_STATUS.SUCCESS) {
                    const tableData = dashboardData.map(element => ({
                        results: element.count,
                        message: element.message,
                        name: element.tenant.name,
                        tenantUrl: element.tenant.tenantUrl,
                        asq: dashBoardQuery
                    }));
                    setRowData(tableData.length === 0 ? null : tableData);
                    resetAfterFetchedDashboard();
                } else if (status === SYNC_STATUS.FAILED) {
                    showToast(message, TOAST_TYPE.ERROR, TOAST_ID);
                    setRowData(null);
                    resetAfterFetchedDashboard();
                } else if (status === SYNC_STATUS.IN_PROGRESS) {
                    pollIntervalId.current = setTimeout(() => {
                        pollQueryResults(jobId);
                    }, 1500);
                }
            })
            .catch(err => {
                displayErrorMessage(err);
                setRowData(null);
                resetAfterFetchedDashboard();
            });
    };

    useEffect(() => {
        if (!dashBoardQuery && dashboards.length === 0) {
            setIsLoading(true, true);
            getDashboards()
                .then(({ data }: AxiosResponse<DashBoardsResponse[]>) => {
                    const data1 = data.map(element => ({
                        title: element.title,
                        asq: element.asq,
                        dashletDataDto: element.dashletDataDto.map(
                            element1 => ({
                                results: element1.results,
                                tenantUrl: element1.tenantDto!.tenantUrl,
                                name: element1.tenantDto!.name,
                                asq: element.asq
                            })
                        )
                    }));
                    setDashboards(data1);
                })
                .catch((err: AxiosError<ErrorResponse>) => {
                    setDashboards([]);
                    displayErrorMessage(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else if (dashBoardQuery) {
            setTableLoading(true);
            dispatch(setIsDataFetchInProgress(true));
            const postDataObject = {
                asq: dashBoardQuery,
                tenantIds: dashBoardTenants
            };
            postDashboards(postDataObject)
                .then(
                    ({
                        data: { status, id }
                    }: AxiosResponse<PostDashBoardsResponse>) => {
                        if (status === SYNC_STATUS.IN_PROGRESS) {
                            pollQueryResults(id);
                        }
                    }
                )
                .catch((err: AxiosError<ErrorResponse>) => {
                    setRowData(null);
                    displayErrorMessage(err);
                    resetAfterFetchedDashboard();
                });
        }
    }, [dashBoardQuery, dashBoardTenants]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (rowData === null && !tableLoading) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [rowData, tableLoading, gridRef]);

    const onGridReadyFun = () => {
        filterItems.forEach((element: FilterItems) => {
            gridRef.current?.columnApi?.setColumnVisible(
                element.id,
                element.checkStatus === CHECKBOX_STATUS.CHECKED
            );
        });
    };

    useEffect(() => {
        if (location.state?.resetTopBarState) {
            resetAfterFetchedDashboard();
            axiosController.controller.abort();
        }
    }, [location.state]);

    useEffect(
        () => () => {
            axiosController.controller.abort();
            resetAfterFetchedDashboard();
        },
        []
    );

    return (
        <>
            <Header title={DASHBOARD} />
            {dashBoardQuery ? (
                <>
                    <TableHeader
                        loading={tableLoading}
                        onColumnMenuClick={handleMenuClick}
                        title={`${rowData?.length ?? 0} ${
                            rowData?.length === 1 ? `${TENANT}` : `${TENANTS}`
                        }`}
                    />
                    <Menu
                        anchorEl={anchorEl}
                        onClose={handleMenuClose}
                        open={Boolean(anchorEl)}
                        PaperProps={
                            {
                                component: StyledMenuPaper
                            } as Partial<PaperProps<'div', {}>> | undefined
                        }
                    >
                        <MultiSelect
                            items={filterItems}
                            onSelectionChanged={onSelectionChanged}
                            showSelectAllOption
                        />
                    </Menu>
                    <Table
                        ref={gridRef}
                        columnDefs={commonDashboardColumnDef}
                        context={{ isArmisUser }}
                        loadingOverlayComponent={TableSkeleton}
                        noRowsOverlayComponent={TableNoData}
                        noRowsOverlayComponentParams={{
                            content: NO_DATA_TO_SHOW
                        }}
                        onGridReady={onGridReadyFun}
                        rowData={rowData}
                        suppressNoRowsOverlay={tableLoading}
                    />
                </>
            ) : dashboards.length === 0 ? (
                <StyledEmptyContainer>
                    <TableNoData content={NO_DATA_TO_SHOW} />
                </StyledEmptyContainer>
            ) : (
                <StyledDiv>
                    {dashboards.map(
                        (element: DashBoardsResponse, index: number) => (
                            <StyledDashBoardItem
                                key={index}
                                data-testid="table-dashlet"
                            >
                                <StyledDashBoardContainer>
                                    <StyledCard className="control card title-outside external-dark-border external-padding stretched">
                                        <StyledHeader className="card-title chart-card-title no-margin no-padding">
                                            <div
                                                className="title"
                                                style={{
                                                    color:
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? 'white'
                                                            : 'black'
                                                }}
                                            >
                                                {element.title}
                                            </div>
                                            <Tooltip
                                                arrow
                                                placement="right"
                                                title={
                                                    <div className="tooltip-arrow-text">
                                                        {ASQ}
                                                        {element.asq}
                                                    </div>
                                                }
                                            >
                                                <span>
                                                    <InfoIcon
                                                        className="info-icon"
                                                        style={{
                                                            fill: constants.COLOR_GENERAL_4,
                                                            marginLeft: '5px'
                                                        }}
                                                    />
                                                </span>
                                            </Tooltip>
                                        </StyledHeader>
                                        <div className="stretched card-content">
                                            <div
                                                style={{
                                                    borderBottom: ` 1px solid ${
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '#352D45'
                                                            : '#E3E3E8'
                                                    } `
                                                }}
                                            />
                                            <CommonTable
                                                rowData={element.dashletDataDto}
                                            />
                                        </div>
                                    </StyledCard>
                                </StyledDashBoardContainer>
                            </StyledDashBoardItem>
                        )
                    )}
                </StyledDiv>
            )}
        </>
    );
};

export const Dashboard = IsLoadingHOC(DashBoardComp);

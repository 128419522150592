import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getUser } from 'src/services/api.service';
import { setUser } from 'src/store/slices/userSlice';
import { User } from 'src/types/APIResponseTypes';

export const useAuth = () => {
    const [isAuth, setIsAuth] = useState<null | boolean>(null);
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const res = await getUser(searchParams.get('partner')!);
                const user: User = res.data;

                if (res.status === 200 && !!user) {
                    dispatch(setUser(user));
                    setIsAuth(true);
                } else {
                    setIsAuth(false);
                }
            } catch (err) {
                setIsAuth(false);
            }
        };

        checkAuth();
    }, [dispatch, searchParams]);

    return isAuth;
};

import React, { useEffect, useState } from 'react';

import { Button, Loader } from '@armis/armis-ui-library';
import { AxiosError } from 'axios';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ArmisLogo from 'src/assets/icons/ArmisLogo';
import CentrixLogo from 'src/assets/icons/CentrixLogo';
import LoginPageLogo from 'src/assets/icons/LoginPageLogo';
import {
    ARMIS,
    DO_NOT_MATCH,
    LOGIN_TITLE,
    NEW_PASSWORD_AGAIN_PLACEHOLDER,
    NEW_PASSWORD_PLACEHOLDER,
    PASSWORD_AGAIN_INPUT_BOX,
    PASSWORD_INPUT_BOX,
    PASSWORD_REGEX,
    PASSWORD_VALIDATE,
    PW_FORM_TITLE,
    REQUIRED,
    RESET_PASSWORD,
    USER_AGREEMENT
} from 'src/constants/LabelText';
import { HOME, LOGIN } from 'src/constants/RouteConstants';
import { Validators } from 'src/helpers/Validators';
import { useAuth } from 'src/hooks/useAuth';
import {
    ButtonContainer,
    FormContainer,
    InputContainer,
    MsgContainer,
    StyledContainer,
    StyledDiv,
    StyledTextBox
} from 'src/pages/containers/Login/Login.style';
import { postPassword } from 'src/services/api.service';
import { ErrorResponse } from 'src/types/APIResponseTypes';

import LoginImage from '../../../../assets/images/loginImage.png';
import LoginVideo from '../../../../assets/video/loginMovie.mp4';

export const SetPassword = () => {
    const isAuth = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const [formFields, setFormFields] = useState({
        [PASSWORD_INPUT_BOX]: '',
        [PASSWORD_AGAIN_INPUT_BOX]: ''
    });
    const [isUserAgree, setIsUserAgree] = useState<boolean>(false);
    const [innerWidth, setInnerWidth] = useState<number>(1536);
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    useEffect(() => {
        setInnerWidth(window.innerWidth);
    }, []);

    useEffect(() => {
        if (isAuth) {
            navigate(HOME, { replace: true });
        }
    }, [isAuth]);

    const onChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setError(false);
        setFormFields(preValue => ({
            ...preValue,
            [event.target.id]: event.target.value
        }));
        setMessage('');
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!isUserAgree) {
            setError(true);
            setMessage(USER_AGREEMENT);
        } else if (
            !Object.keys(formFields).every(key =>
                Validators.validateNotEmpty(
                    formFields[key as keyof typeof formFields]
                )
            )
        ) {
            setError(true);
            setMessage(REQUIRED);
        } else if (
            formFields[PASSWORD_AGAIN_INPUT_BOX] !==
            formFields[PASSWORD_INPUT_BOX]
        ) {
            setError(true);
            setMessage(DO_NOT_MATCH);
        } else if (
            !Validators.validateAgainstFormat(
                formFields[PASSWORD_INPUT_BOX],
                PASSWORD_REGEX
            )
        ) {
            setError(true);
            setMessage(PASSWORD_VALIDATE);
        } else {
            const cupObject = { cup: formFields[PASSWORD_INPUT_BOX] };
            postPassword(token!, cupObject)
                .then(() => {
                    navigate(LOGIN, { replace: true });
                })
                .catch((err: AxiosError<ErrorResponse>) => {
                    setError(true);
                    setMessage(err.response?.data?.message ?? err.message);
                });
        }
    };

    const handleAgreement = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsUserAgree(e.target.checked);
    };

    if (isAuth === false) {
        return (
            <StyledContainer>
                <Helmet>
                    <title>{`${ARMIS} | ${LOGIN_TITLE}`}</title>
                </Helmet>
                <div
                    style={{
                        display: 'flex',
                        width: '100%'
                    }}
                >
                    <div style={{ marginTop: '30px' }}>
                        <ArmisLogo />
                    </div>
                    <StyledDiv>
                        <img
                            alt="Login"
                            className="login-video"
                            src={LoginImage}
                        />
                        <video
                            autoPlay
                            className="login-video"
                            id="background-video"
                            loop
                            muted
                            poster={LoginImage}
                        >
                            <source src={LoginVideo} type="video/mp4" />
                        </video>
                        <div
                            style={{
                                marginTop: innerWidth <= 1530 ? '-30px' : '0px'
                            }}
                        >
                            <CentrixLogo />
                        </div>
                        <div className="login-header">
                            See, protect and manage your entire attack surface
                        </div>
                        <div className="login-sub-header">
                            Powered by the Armis AI-driven Asset Intelligence
                            Engine
                        </div>
                        <div className="content">
                            <header className="header">{PW_FORM_TITLE}</header>
                            <FormContainer onSubmit={handleSubmit}>
                                <div className="input-field">
                                    <span className="label">
                                        {formFields[PASSWORD_INPUT_BOX].length >
                                            0 && 'New Password'}
                                    </span>
                                    <InputContainer>
                                        <StyledTextBox
                                            error={error}
                                            fullWidth
                                            id={PASSWORD_INPUT_BOX}
                                            onChange={onChangeHandler}
                                            placeholder={
                                                NEW_PASSWORD_PLACEHOLDER
                                            }
                                            size="small"
                                            type="password"
                                            value={
                                                formFields[PASSWORD_INPUT_BOX]
                                            }
                                            variant="filled"
                                        />
                                    </InputContainer>
                                    <div style={{ marginTop: '25px' }}>
                                        <span className="label">
                                            {formFields[PASSWORD_INPUT_BOX]
                                                .length > 0 &&
                                                'New Password (again)'}
                                        </span>
                                        <InputContainer>
                                            <StyledTextBox
                                                error={error}
                                                fullWidth
                                                id={PASSWORD_AGAIN_INPUT_BOX}
                                                onChange={onChangeHandler}
                                                placeholder={
                                                    NEW_PASSWORD_AGAIN_PLACEHOLDER
                                                }
                                                size="small"
                                                type="password"
                                                value={
                                                    formFields[
                                                        PASSWORD_AGAIN_INPUT_BOX
                                                    ]
                                                }
                                                variant="filled"
                                            />
                                        </InputContainer>
                                    </div>

                                    <div className="eula-agreement">
                                        <input
                                            className="eula-agreement-checkbox"
                                            onChange={e => handleAgreement(e)}
                                            type="checkbox"
                                        />
                                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                        <label
                                            className="eula-agreement-text"
                                            htmlFor="agreement"
                                        >
                                            I acknowledge and accept Armis’
                                            &nbsp;
                                            <a
                                                href="https://www.armis.com/privacy-policy"
                                                style={{ color: '#fff' }}
                                            >
                                                Privacy Policy
                                            </a>
                                            &nbsp; and agree to Armis’ &nbsp;
                                            <a
                                                href="https://www.armis.com/legal/general-terms-and-conditions/"
                                                style={{ color: '#fff' }}
                                            >
                                                General Terms and Conditions.
                                            </a>
                                        </label>
                                    </div>

                                    <ButtonContainer>
                                        <Button
                                            fullWidth
                                            style={{
                                                backgroundColor: '#2ABAFF',
                                                height: '40px',
                                                borderRadius: '4px'
                                            }}
                                            type="submit"
                                            variant="contained"
                                        >
                                            {RESET_PASSWORD}
                                        </Button>
                                    </ButtonContainer>
                                    {error && (
                                        <MsgContainer>{message}</MsgContainer>
                                    )}
                                </div>
                            </FormContainer>
                        </div>
                        <div
                            className="logo-resize"
                            style={{
                                position: 'absolute',
                                bottom: '0px',
                                right: '0px',
                                display: 'block',
                                marginRight: '24px',
                                marginBottom: '3px'
                            }}
                        >
                            <LoginPageLogo />
                        </div>
                    </StyledDiv>
                </div>
            </StyledContainer>
        );
    }
    return (
        <Loader fade={false} loaderType={{ name: 'dots' }} show>
            <div className="dots" />
        </Loader>
    );
};

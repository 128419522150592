import { TextBox } from '@armis/armis-ui-library';
import { Container, styled } from '@mui/material';

import {
    MsgContainerProps,
    StyledContainerProps,
    StyledTextBoxProps
} from './Login.types';

export const StyledContainer = styled(Container, {
    shouldForwardProp: prop => prop !== 'isLoginSuccess'
})<StyledContainerProps>(({ isLoginSuccess = false }) => ({
    display: 'flex',
    height: '100vh',
    marginLeft: '155px',
    pointerEvents: `${isLoginSuccess ? 'none' : 'auto'}`,
    '& .login-video ': {
        filter: `${isLoginSuccess ? 'blur(10px)' : 'none'}`
    }
}));

export const StyledDiv = styled('div')`
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin-top: 100px;

    & .login-video {
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
    }

    & .login-header {
        font-size: 40px;
        color: white;
        margin-bottom: 30px;
        margin-top: 19px;
        line-height: 40px;
        @media (max-width: 1440px) {
            font-size: 30px;
            line-height: 30px;
        }
    }

    & .login-sub-header {
        font-size: 20px;
        color: white;
        margin-bottom: 30px;
        line-height: 30px;
        margin-top: -3px;
        @media (max-width: 1440px) {
            font-size: 15px;
        }
    }
    & .content {
        width: 300px;
        padding-top: 20px;
        & .header {
            font-family: var(--chakra-fonts-bold);
            color: #fff;
            font-size: 16px;
            letter-spacing: 0.4px;
            margin-bottom: 10px;
            margin-top: 5px;
        }

        & .input-field {
            margin-bottom: 20px;
            & .label {
                font-family: 'Proxima Nova W08 Thn Reg';
                font-size: 12px;
                color: #48206e;
                letter-spacing: 0.4px;
                transition: opacity 0.1s linear;
                display: inline-block;
            }
        }

        & .eula-agreement {
            display: flex;
            margin-bottom: 20px;
            line-height: 1;
        }

        & .eula-agreement-checkbox {
            margin: 5px 10px 0px 0px;
        }

        & .eula-agreement-text {
            font-size: 14px;
            color: #fff;
            line-height: 1.5em;
        }
    }

    & .logo-resize {
        @media (min-width: 1530px) {
            width: 400px;
        }
        @media (min-width: 1340px) and (max-width: 1529px) {
            width: 300px;
        }
    }
`;

export const StyledImage = styled('img')`
    margin-bottom: 100px;
`;

export const FormContainer = styled('form')`
    background-color: transparent;
    border-radius: 4px;
`;

export const FormHeader = styled('div')`
    font-family: 'Proxima Nova W08 Smbd';
    color: #48206e;
    font-size: 18px;
    letter-spacing: 0.4px;
    margin-bottom: 20px;
`;

export const InputContainer = styled('div')`
    margin-bottom: 20px;
`;

export const ButtonContainer = styled('div')`
    display: flex;
    align-items: baseline;
    margin-top: 20px;
`;

export const MsgContainer = styled('div')<MsgContainerProps>(() => ({
    fontFamily: 'Proxima Nova W08 Thn Reg',
    flex: 2,
    fontSize: 12,
    paddingLeft: 20,
    alignSelf: 'center',
    textAlign: 'center',
    color: '#fff',
    marginTop: '3px'
}));

export const TwoFactorInputContainer = styled('div')`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    box-sizing: border-box;
`;

export const TwoFactorInput = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    margin-top: 20px;
    gap: 10px;
    background-color: #f3f3f3;
    border-radius: 4px;
    height: 60px;
`;

export const TwoFactorTextContainer = styled('div')`
    display: flex;
    column-gap: 5px;

    p {
        padding: 0;
        margin: 0;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export const StyledParagraph = styled('div')<MsgContainerProps>(
    ({ variant = 'info' }) => ({
        fontSize: 14,
        marginTop: 10,
        textAlign: 'right',
        height: 20,
        color: variant === 'info' ? '#64acfa' : '#019f5c'
    })
);

export const StyledTextBox = styled(TextBox)<StyledTextBoxProps>(
    ({ error = false }) => ({
        fontSize: '16px',
        letterSpacing: '0.4px',
        padding: 0,
        boxSizing: 'border-box',
        width: '100%',
        outline: 'none',
        background: 'transparent',
        border: `1px solid ${error ? '#E31C3D' : '#fff'}`,
        '& .MuiInputBase-root': {
            color: '#fff'
        },
        '& .MuiFilledInput-input': {
            padding: '10px',
            fontFamily: 'Proxima Nova W08 Thn Reg'
        },
        '& .MuiFilledInput-root:after': {
            border: 'none'
        },
        '& .MuiFilledInput-root:before': {
            border: 'none'
        },
        '& .MuiFilledInput-root:hover': {
            border: 'none',
            backgroundColor: 'transparent'
        },
        '& .MuiFilledInput-root:not(:hover)': {
            border: 'none',
            backgroundColor: 'transparent'
        },
        '& .MuiFilledInput-root': {
            borderRadius: '0px',
            backgroundColor: 'transparent',
            ':hover:not(.Mui-disabled, .Mui-error):before': {
                borderBottom: 'none'
            }
        },
        '& .MuiFilledInput-input:-webkit-autofill': {
            '-webkit-box-shadow': 'none',
            '-webkit-text-fill-color': 'black',
            'caret-color': 'black'
        }
    })
);

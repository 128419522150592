import React from 'react';

import {
    ErrorTri,
    Loader,
    TableNoData,
    WarningTri
} from '@armis/armis-ui-library';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
    ACTIVE,
    ALERTS,
    COLLECTORS_LABEL,
    DEVICES,
    ERROR,
    EXPAND_TILE_VIEW,
    INTEGRATIONS_LABEL,
    OFFLINE,
    OTHERS,
    PINNED,
    SITES
} from 'src/constants/LabelText';
import { NO_DATA_TO_SHOW } from 'src/constants/TableConstants';
import { AggregatedData } from 'src/pages/containers/AggregatedView/AggregatedView.types';
import { StyledEmptyContainer } from 'src/pages/containers/DashBoard/DashBoard.style';
import { Tile } from 'src/pages/containers/TileView/Tile/Tile';
import {
    StyledTilesContainer,
    StyledTileView,
    StyledTypography
} from 'src/pages/containers/TileView/TileView.style';
import { TileViewTypes } from 'src/pages/containers/TileView/TileView.types';

import { PinnedTiles } from './PinnedTiles/PinnedTiles';

export const TileViewComp = ({
    rowData,
    loading,
    thresoldData,
    isArmisUser,
    pinActionTile
}: TileViewTypes) => {
    const getTileComp = (
        {
            criticality,
            tenantName,
            tenantUrl,
            device,
            site,
            alert,
            ...rest
        }: AggregatedData,
        index: number
    ) => {
        const totalIntegrations =
            rest.activeIntegration + rest.errorIntegration;
        const totalCollectors = rest.activeCollector + rest.offlineCollector;
        return (
            <Tile
                key={`${rest.tenant.id}-${index}`}
                chartResources={[
                    {
                        labelName: INTEGRATIONS_LABEL,
                        totalCount: totalIntegrations,
                        chartData: [
                            {
                                name: ACTIVE,
                                y: rest.activeIntegration,
                                color: '#019F5C'
                            },
                            {
                                name: ERROR,
                                y: rest.errorIntegration,
                                color: '#E22B2C'
                            }
                        ]
                    },
                    {
                        labelName: COLLECTORS_LABEL,
                        totalCount: totalCollectors,
                        chartData: [
                            {
                                name: ACTIVE,
                                y: rest.activeCollector,
                                color: '#019F5C'
                            },
                            {
                                name: OFFLINE,
                                y: rest.offlineCollector,
                                color: '#E22B2C'
                            }
                        ]
                    }
                ]}
                criticality={criticality}
                index={index}
                isArmisUser={isArmisUser}
                pinActionTile={pinActionTile}
                pinLatest={rowData?.pinned?.slice(-1)[0]?.tenant.pinOrder ?? 0}
                pinned={rest.tenant?.pinOrder > 0}
                tenant={rest.tenant}
                tenantResources={[
                    {
                        labelName: DEVICES,
                        count: device
                    },
                    {
                        labelName: (
                            <>
                                {alert >= thresoldData?.ALERT_THRESHOLD! ? (
                                    <ErrorTri
                                        height="8"
                                        style={{ marginRight: '4px' }}
                                        width="8"
                                    />
                                ) : (
                                    !!(
                                        alert <
                                            thresoldData?.ALERT_THRESHOLD! &&
                                        alert !== 0
                                    ) && (
                                        <WarningTri
                                            height="8"
                                            style={{ marginRight: '4px' }}
                                            width="8"
                                        />
                                    )
                                )}
                                {ALERTS}
                            </>
                        ),
                        count: alert
                    },
                    {
                        labelName: SITES,
                        count: site
                    }
                ]}
                tileTitle={tenantName}
                tileURL={tenantUrl}
            />
        );
    };

    const theme = useTheme();

    const pinnedTiles = rowData?.pinned?.length! > 0;
    return rowData?.pinned?.length === 0 &&
        rowData?.unPinned?.length === 0 &&
        !loading ? (
        <StyledEmptyContainer>
            <TableNoData content={NO_DATA_TO_SHOW} />
        </StyledEmptyContainer>
    ) : (
        <StyledTilesContainer id={EXPAND_TILE_VIEW}>
            {loading && (
                <Box
                    sx={{
                        display: 'flex',
                        padding: '20px',
                        justifyContent: 'center',
                        boxSizing: 'content-box'
                    }}
                >
                    <Loader
                        fade={false}
                        loaderType={{ name: 'spinner', size: 12 }}
                        show={loading}
                    >
                        <div className="circle" data-testid="loader-dots" />
                    </Loader>
                </Box>
            )}
            <div style={{ display: loading ? 'none' : 'unset' }}>
                {pinnedTiles && (
                    <StyledTypography
                        sx={{
                            color: theme.palette.mode === 'dark' ? 'white' : ''
                        }}
                        variant="h4"
                    >
                        {PINNED}
                    </StyledTypography>
                )}
                {pinnedTiles && (
                    <PinnedTiles getTileComp={getTileComp} rowData={rowData} />
                )}
                {pinnedTiles && (
                    <StyledTypography variant="h4">{OTHERS}</StyledTypography>
                )}
                <StyledTileView>
                    {rowData?.unPinned?.map(getTileComp)}
                </StyledTileView>
            </div>
        </StyledTilesContainer>
    );
};

import { createSlice } from '@reduxjs/toolkit';
import { CustomPropertiesResponse } from 'src/types/APIResponseTypes';

import type { RootState } from '../store';

interface InitialStateType {
    customPropertiesStructure: CustomPropertiesResponse | null;
}

const initialState: InitialStateType = {
    customPropertiesStructure: null
};

export const customPropertiesSlice = createSlice({
    name: 'customProperties',
    initialState,
    reducers: {
        setCustomPropertiesStructure: (state, action) => {
            state.customPropertiesStructure = action.payload;
        }
    }
});

export const selectCustomPropertiesStructure = (state: RootState) => state.customProperties.customPropertiesStructure as CustomPropertiesResponse | null;

export const { setCustomPropertiesStructure } = customPropertiesSlice.actions;

export default customPropertiesSlice.reducer;
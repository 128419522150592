import { constants } from '@armis/armis-ui-library';
import { Typography, styled } from '@mui/material';
import { Modal } from 'src/pages/components/Modal';

export const EllipsisTypography = styled(Typography)`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${props => props.theme.typography.body2.fontSize};
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_25
            : constants.COLOR_GENERAL_22};
`;

export const DragableDiv = styled('div')`
    // top: auto !important;
    // left: auto !important;
`;

export const RightSideContainer = styled('div')`
    display: flex;
    width: 100%;
`;

export const RightSideFieldsContainer = styled('div')`
    height: calc(100% - 45px);
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_28
                : constants.COLOR_GENERAL_52};
    }
    &::-webkit-scrollbar-track {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_27
                : constants.COLOR_GENERAL_21};
        border-bottom-right-radius: 8px;
    }
`;

export const MiddleContainer = styled('div')`
    display: flex;

    & .MuiCardContent-root {
        padding: 0 10px 8px;
        background: ${props =>
            props.theme.palette.mode === 'dark' ? 'transparent' : '#F2F2F4'};
    }
`;

export const PageContainer = styled('div')`
    display: flex;
    height: calc(100% - 90px);
`;

export const StyledDiv = styled('div')`
    flexdirection: column;
    gap: 5px;
    display: flex;

    & .MuiGrid-item {
        padding-right: 8px;
    }

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_28
                : constants.COLOR_GENERAL_52};
    }
    &::-webkit-scrollbar-track {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_27
                : constants.COLOR_GENERAL_21};
        border-bottom-right-radius: 8px;
    }
`;

export const StyledModal = styled(Modal)``;

export const StyledTypography = styled(Typography)`
    font-family: Proxima Nova W08 Reg;
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_25
            : constants.COLOR_GENERAL_22};
`;

export const StyledField = styled('div')`
    &:not(:last-child, :first-child) {
        padding-bottom: 10pt;
    }
    .MuiFormLabel-root {
        font-family: Proxima Nova Bld;
        margin-bottom: 4px;
        font-size: 14px;
        display: block;
        color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_WHITE
                : constants.COLOR_GENERAL_29};
    }
    .MuiInputBase-root {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_29
                : constants.COLOR_WHITE};
    }
    .text-area {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_29
                : constants.COLOR_WHITE};
        color: ${props =>
            props.theme.palette.mode === 'dark' ? '#FCFCFC' : '#121212'};

        &:focus {
            border-color: #8338ec !important;
        }
        border: 1px solid
            ${props =>
                props.theme.palette.mode === 'dark' ? '#5C5C5C' : '#CBCBCB'};
    }
    & .rdw-editor-toolbar {
        border: none;
    }

    & .rdw-option-wrapper {
        border: ${props => (props.theme.palette.mode === 'dark' ? 'none' : '')};
        background: ${props =>
            props.theme.palette.mode === 'dark' ? 'none' : 'white'};
    }

    & .rdw-dropdown-wrapper {
        border: ${props =>
            props.theme.palette.mode === 'dark' ? '1px solid #5C5C5C' : ''};
        background: ${props =>
            props.theme.palette.mode === 'dark' ? 'none' : ''};
        color: ${props =>
            props.theme.palette.mode === 'dark' ? 'white' : 'black'};
    }

    & .rdw-dropdown-optionwrapper {
        background: ${props =>
            props.theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.05)'
                : ''};

        &::-webkit-scrollbar {
            width: 8px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: ${props =>
                props.theme.palette.mode === 'dark'
                    ? constants.COLOR_GENERAL_28
                    : constants.COLOR_GENERAL_52};
        }
        &::-webkit-scrollbar-track {
            background-color: ${props =>
                props.theme.palette.mode === 'dark'
                    ? constants.COLOR_GENERAL_27
                    : constants.COLOR_GENERAL_21};
            border-bottom-right-radius: 8px;
        }
    }
    & .ql-toolbar.ql-snow {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        border: ${props =>
            props.theme.palette.mode === 'dark'
                ? `1px solid ${constants.COLOR_GENERAL_28}`
                : `1px solid ${constants.COLOR_9}`};
    }

    & .ql-container.ql-snow {
        border: ${props =>
            props.theme.palette.mode === 'dark'
                ? `1px solid ${constants.COLOR_GENERAL_28}`
                : `1px solid ${constants.COLOR_9}`};

        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        background-color: ${props =>
            props.theme.palette.mode === 'dark' ? '' : '#ffffff'};

        caret-color: ${props =>
            props.theme.palette.mode === 'dark' ? '#ffffff' : ''};

        color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_WHITE
                : constants.COLOR_GENERAL_3};
    }

    button .ql-stroke,
    .ql-picker-label .ql-stroke .ql-fill {
        fill: none;
        stroke: ${props =>
            props.theme.palette.mode === 'dark'
                ? 'white !important'
                : 'black !important'};
        display: inline-flex;
        transition-duration: 200ms;
        height: 32px;
        min-width: 2.5rem;
    }

    button:hover .ql-fill,
    ql-active:not {
        fill: ${props =>
            props.theme.palette.mode === 'dark'
                ? 'white !important'
                : 'black !important'};
    }

    button:hover,
    .ql-picker-label:hover,
    .ql-fill {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_28
                : constants.COLOR_GENERAL_52};
        stroke: none !important;
        fill: ${props =>
            props.theme.palette.mode === 'dark'
                ? 'white !important'
                : 'black !important'};
        border-radius: 4px;
    }

    .ql-formats .ql-active {
        background-color: #d3e3fd;
        border-radius: 4px;
    }

    .ql-formats .ql-active {
        background-color: ${props =>
            props.theme.palette.mode === 'dark' ? '#635e6d' : '#d3e3fd'};
        border-radius: 4px;
    }

    .ql-formats {
        button {
            margin: 3px;
        }
    }
`;

export const StyledHeader = styled('div')`
    display: flex;
    justify-content: space-between;

    & .arrow {
        height: 12px;
        justify-self: center;
        transition: all 300ms;
        fill: ${constants.COLOR_1};
        overflow: hidden;
        position: relative;

        &.open {
            transform: rotate(90deg);
        }
        &.close {
            transform: rotate(270deg);
        }
    }
`;

export const StyledQuillDiv = styled('div')`
    & .ql-toolbar .ql-stroke {
        fill: none;
        stroke: ${props =>
            props.theme.palette.mode === 'dark' ? 'white' : 'black'};
    }

    & .ql-toolbar .ql-fill {
        fill: ${props =>
            props.theme.palette.mode === 'dark' ? 'white' : 'black'};
        stroke: none;
    }

    & .ql-toolbar .ql-picker {
        color: ${props =>
            props.theme.palette.mode === 'dark' ? 'white' : 'black'};
    }

    & .ql-error {
        & .ql-toolbar.ql-snow {
            border: 1px solid #e31c3d;
        }

        & .ql-container.ql-snow {
            border: 1px solid #e31c3d;
            border-top: 0px;
        }
    }
`;

const List = styled('div')`
    background: #fff;
    padding: 0.5rem 0.5rem 0;
    border-radius: 3px;
    flex: 0 0 150px;
    font-family: sans-serif;
`;

export const Content = styled('div')`
    padding: 10px;
    overflow-y: auto;
    height: calc(100% - 45px);
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_28
                : constants.COLOR_GENERAL_52};
    }
    &::-webkit-scrollbar-track {
        background-color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_27
                : constants.COLOR_GENERAL_21};
        border-bottom-right-radius: 8px;
    }
`;

export const MiddlePaneContainer = styled(List)`
    background-color: transparent;
    min-height: 55vh;
`;

export const RightPaneContainer = styled(List)`
    background-color: ${props =>
        props.theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : '#ffffff'};
    min-height: 55vh;
`;

export const RightPaneContainerTwo = styled(List)`
    background-color: ${props =>
        props.theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)'};
    min-height: 55vh;
`;

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
});

import { constants } from '@armis/armis-ui-library';
import { Box, styled } from '@mui/material';
import { StyledTileProps } from 'src/pages/containers/TileView/Tile/Tile.types';

export const StyledTile = styled('div')`
    border: ${props =>
        props.theme.palette.mode === 'dark'
            ? '1px solid #5C5C5C'
            : '1px solid #E3E3E8'};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: ${props =>
        props.theme.palette.mode === 'dark' ? '#251E33' : ''};
`;

export const TileTitle = styled('div', {
    shouldForwardProp: prop => !['criticality'].includes(prop as string)
})<StyledTileProps>(({ criticality }) => ({
    borderBottom: `2px solid ${
        criticality === 2
            ? constants.COLOR_10
            : criticality === 1
            ? constants.COLOR_3
            : constants.COLOR_2
    }`,
    padding: '8px 24px',
    display: 'flex',
    alignItems: 'center',
    height: '40px',

    '& svg': {
        fill: 'none !important'
    }
}));

export const TileTitleDiv = styled(Box)`
    flex: 1;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const TenantResources = styled('div')`
    padding: 8px 20px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid ${constants.COLOR_9};
    color: ${props => (props.theme.palette.mode === 'dark' ? 'white' : '')};
`;

export const TenantResource = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    &:not(:last-child) {
        border-right: 1px solid ${constants.COLOR_GENERAL_8};
    }
    row-gap: 3px;
`;

export const ChartsBox = styled(Box)`
    display: flex;
    > div:not(:last-child) {
        border-right: 1px solid ${constants.COLOR_9};
        border-bottom-left-radius: 4px;
    }
    > div {
        flex: 1;
        border-bottom-right-radius: 4px;
    }
`;
